import React, { useState, useEffect } from 'react';
import DivisionWiseImsRolling from './DivisionWiseImsRolling';
import RegionWiseImsRolling from './RegionWiseImsRolling';
import { BottomTenRM, BottomThreeDSMView, BottomTwentSalesOfficerAcVw, BottomTwentyTSM, CategoryWiseDivisonAchDataVw, DivisionWiseImsSummeryVW, ImsSummeryAllView, RegionWiseImsSummeryVW, TopFiveDSMView, TopTenRMView, TopTwentyTSM, bottomTwentySalesOfficersvw, currentMonthWorkingDays, dailyImsSummeryreport, focusPeroductTargetVsAchievmentView, focusSkuTargetVsAchievmentView, heighestAchieversTwentySalesOfficers, topSellingSkuView, topTwentSalesOfficerVw } from '../../Redux/MapAction';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../../../modules/Billing/Components/LoadingSpinner';
import TopFiveDsmRolling from './TopFiveDsmRolling';
import BottomThreeDsmRolling from './BottomThreeDsmRolling';
import TopTenRmRolling from './TopTenRmRolling';
import BottomTenRmRolling from './BottomTenRmRolling';
import TopsellingSkuRolling from './TopsellingSkuRolling';
import TopTwentyTsmRolling from './TopTwentyTsmRolling';
import BottomtwentyTsmRolling from './BottomTwentyTsmRolling';
import HeighestAchieversTwentysalesOfficerRolling from './HeighestAchieversTwentysalesOfficerRolling';
import BottomTwentSalesValueAchOfficerRolling from './BottomTwentySalesOfRolling';
import BottomTwentySalesOfficerRolling from './BottomTwentySalesOfficerRolling';
import TopTwentSalesValueAchOfficerRolling from './TopTwentSalesValueAchOfficerRolling';
import CategoryWiseDivisonRolling from './CategoryWiseDivisionRolling';
import FocusProductTarvsAch from './FocusProductVsTargetProductRolling';
import FocusSkutarvsAch from './FocusSkuTargetvsAchRolling';
import { Accordion } from "react-bootstrap";
import { FaAngleDoubleDown } from 'react-icons/fa';
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useHistory } from "react-router-dom";
import DasilyImsReportRolling from './DasilyImsReportRolling';




const MainRolling = () => {
    const dispatch = useDispatch();
    const { register, setValue } = useForm();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };




    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };
    const handleSummeryReport = async () => {
        await dispatch(ImsSummeryAllView(selectedMonth))
        await dispatch(TopTenRMView(selectedMonth))
        await dispatch(topSellingSkuView(selectedMonth))
        await dispatch(BottomTenRM(selectedMonth))

    }



    const currentDate = new Date();
    const regionData = useSelector(state => state?.mapData?.regionDataView?.allData);
    const divisionData = useSelector(state => state?.mapData?.divisionDataView?.allData);
    const topFiveDsmData = useSelector(state => state?.mapData?.topFiveDSmData?.allData);
    const bottom3DsmData = useSelector(state => state?.mapData?.bottomThreeDsm?.allData);
    const topTenRmDatas = useSelector(state => state?.mapData?.topTenRmData?.allData);//1111111111111
    const bottomTenRmDatas = useSelector(state => state?.mapData?.bottomTenRm?.allData);//llllllllllll
    const top20Tsm = useSelector(state => state?.mapData?.topTwentTSM?.allData);
    const bottom20Tsm = useSelector(state => state?.mapData?.bottomTwentTSM?.allData);
    const heigest20SoAch = useSelector(state => state?.mapData?.heighestAchiversTwenty?.allData);
    const bottom20So = useSelector(state => state?.mapData?.bottomTwentySalesOfficers?.allData);
    const top20SoValue = useSelector(state => state?.mapData?.topTwentySalesOfficer?.allData);
    const bottom20SoValue = useSelector(state => state?.mapData?.BottomTwentySalesOfficerData?.allData);
    const categoryWise = useSelector(state => state?.mapData?.categoryWiseDivisonAch?.allData);
    const focusProductTarget = useSelector(state => state?.mapData?.focusProductData?.allData);
    const focusSKUTarget = useSelector(state => state?.mapData?.focussKUData?.allData);
    const topSellingDatas = useSelector(state => state?.mapData?.topSellingData?.allData);// 
    const workingDays = useSelector(state => state?.mapData?.currentMonhWorking?.allData);
    const dailyImsData = useSelector(state => state?.mapData?.dailyImsData?.allData);
    const headerData = useSelector(state => state?.mapData?.dailyImsData?.skippedData);

    /*     const regionData = useSelector(state => state?.mapData?.regionDataView?.allData);
        const divisionData = useSelector(state => state?.mapData?.imsSummeryAllData?.division);
        const topFiveDsmData = useSelector(state => state?.mapData?.imsSummeryAllData?.top5Dsm);
        const bottom3DsmData = useSelector(state => state?.mapData?.imsSummeryAllData?.bottom3Dsm);
        const topTenRmDatas = useSelector(state => state?.mapData?.topTenRmData?.allData);//1111111111111
        const bottomTenRmDatas = useSelector(state => state?.mapData?.bottomTenRm?.allData);//llllllllllll
        const top20Tsm = useSelector(state => state?.mapData?.imsSummeryAllData?.top20Tsm);
        const bottom20Tsm = useSelector(state => state?.mapData?.imsSummeryAllData?.bottom20Tsm);
        const heigest20SoAch = useSelector(state => state?.mapData?.imsSummeryAllData?.top20So);
        const bottom20So = useSelector(state => state?.mapData?.imsSummeryAllData?.bottom20So);
        const top20SoValue = useSelector(state => state?.mapData?.imsSummeryAllData?.top20SoValue);
        const bottom20SoValue = useSelector(state => state?.mapData?.imsSummeryAllData?.bottom20SoValue);
        const categoryWise = useSelector(state => state?.mapData?.imsSummeryAllData?.categoryWise);
        const focusProductTarget = useSelector(state => state?.mapData?.imsSummeryAllData?.focusProductTarget);
        const focusSKUTarget = useSelector(state => state?.mapData?.imsSummeryAllData?.focusSKUTarget);
        const topSellingDatas = useSelector(state => state?.mapData?.topSellingData?.allData);// */



    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);
    const [currentPage, setCurrentPage] = useState(0);
    const [isSliding, setIsSliding] = useState(true);
    const pageDuration = 20000;

    const DivisionRolling = () => <div><DivisionWiseImsRolling divisionData={divisionData} workingDays={workingDays} /></div>;
    const RegionImsRolling = () => <div><RegionWiseImsRolling regionData={regionData} workingDays={workingDays} /></div>;
    const topFiveDsm = () => <div><TopFiveDsmRolling topFiveDsmData={topFiveDsmData} workingDays={workingDays} /></div>;
    const Bottom3Dsm = () => <div><BottomThreeDsmRolling bottom3DsmData={bottom3DsmData} workingDays={workingDays} /></div>;
    const topTenRmData = () => <div><TopTenRmRolling topTenRmDatas={topTenRmDatas} workingDays={workingDays} /></div>;
    const bottomTenRmData = () => <div><BottomTenRmRolling bottomTenRmDatas={bottomTenRmDatas} workingDays={workingDays} /></div>;
    const TopTwentyTsm = () => <div><TopTwentyTsmRolling top20Tsm={top20Tsm} workingDays={workingDays} /></div>;
    const bottomTwentyTsm = () => <div><BottomtwentyTsmRolling bottom20Tsm={bottom20Tsm} workingDays={workingDays} /></div>;
    const HeighestAchieversData = () => <div><HeighestAchieversTwentysalesOfficerRolling heigest20SoAch={heigest20SoAch} workingDays={workingDays} /></div>;
    const bottonTwentySalesData = () => <div><BottomTwentySalesOfficerRolling bottom20So={bottom20So} workingDays={workingDays} /> </div>;
    const TopTwentySalesOfficerValue = () => <div><TopTwentSalesValueAchOfficerRolling top20SoValue={top20SoValue} workingDays={workingDays} /> </div>;
    const BottomTwentSalesValueData = () => <div><BottomTwentSalesValueAchOfficerRolling bottom20SoValue={bottom20SoValue} workingDays={workingDays} /></div>;
    const cateGoryWiseDivision = () => <div><CategoryWiseDivisonRolling categoryWise={categoryWise} workingDays={workingDays} /></div>;
    const focusProductTar = () => <div><FocusProductTarvsAch focusProductTarget={focusProductTarget} workingDays={workingDays} /></div>;
    const focusSkuTar = () => <div><FocusSkutarvsAch focusSKUTarget={focusSKUTarget} workingDays={workingDays} /></div>;
    const topSellingData = () => <div><TopsellingSkuRolling topSellingDatas={topSellingDatas} workingDays={workingDays} /></div>;
    const DailyImsReport = () => <div><DasilyImsReportRolling dailyImsData={dailyImsData} headerData={headerData} workingDays={workingDays} /></div>;


    const pages = [DailyImsReport, DivisionRolling, RegionImsRolling, topFiveDsm, Bottom3Dsm, topTenRmData, bottomTenRmData, TopTwentyTsm, bottomTwentyTsm, HeighestAchieversData, bottonTwentySalesData, TopTwentySalesOfficerValue, BottomTwentSalesValueData, cateGoryWiseDivision, focusProductTar, focusSkuTar,
        topSellingData];

    const data = useSelector(state => state?.mapData?.divisionSummary?.allData);
    const imsSummeryAllDataIsLoading = useSelector(state => state?.mapData?.imsSummeryAllDataIsLoading);
    const divisionDataViewscc = useSelector(state => state?.mapData?.imsSummeryAllData);

    console.log('workingDays', workingDays, divisionData)


    useEffect(() => {
        initialData();
    }, [dispatch])


    const initialData = async () => {
        await dispatch(BottomTwentSalesOfficerAcVw(selectedMonth))
        await dispatch(currentMonthWorkingDays())
        await dispatch(dailyImsSummeryreport(selectedMonth))

        await dispatch(TopTenRMView(selectedMonth))
        await dispatch(topSellingSkuView(selectedMonth))
        await dispatch(BottomTenRM(selectedMonth))
        await dispatch(RegionWiseImsSummeryVW(selectedMonth))
        await dispatch(TopFiveDSMView(selectedMonth))
        await dispatch(BottomThreeDSMView(selectedMonth))
        await dispatch(DivisionWiseImsSummeryVW(selectedMonth))
        await dispatch(TopTwentyTSM(selectedMonth))
        await dispatch(BottomTwentyTSM(selectedMonth))
        await dispatch(heighestAchieversTwentySalesOfficers(selectedMonth))
        await dispatch(bottomTwentySalesOfficersvw(selectedMonth))
        await dispatch(topTwentSalesOfficerVw(selectedMonth))
        await dispatch(CategoryWiseDivisonAchDataVw(selectedMonth))
        await dispatch(focusPeroductTargetVsAchievmentView(selectedMonth))
        await dispatch(focusSkuTargetVsAchievmentView(selectedMonth))


    }

    const bottom20SoValuess = useSelector(state => state?.mapData?.BottomTwentySalesOfficerData?.allData);

    useEffect(() => {
        let intervalId;

        if (isSliding) {
            intervalId = setInterval(() => {
                setCurrentPage((prevPage) => (prevPage + 1) % pages.length);
            }, pageDuration);
        }

        return () => clearInterval(intervalId);
    }, [isSliding, currentPage, pages.length, pageDuration]);

    const goToPage = (index) => {
        setCurrentPage(index);
    };

    const handleToggleSlide = () => {
        setIsSliding((prevIsSliding) => !prevIsSliding);
    };

    const navigate = useHistory();

    const previousMonthData = () => {
        navigate.push("/preMonthRolling")

    }
    console.log('heigest20SoAch', heigest20SoAch)

    return (

        <div className='container' >
            <div className="row">
                <div className="col-lg-10 col-md-12 col-sm-12 paddingremove col-xl-12 sticky-top zindexmodified">
                    <div className="top_card3">
                        <Accordion defaultActiveKey="0">
                            {!imsSummeryAllDataIsLoading &&

                                <button
                                    className="btn btn-primary btn-sm mt-5 rolling-stop-continue"
                                    onClick={handleToggleSlide}
                                >
                                    {isSliding ? 'Stop' : 'Continue'}
                                </button>
                            }

                            &nbsp; &nbsp; &nbsp; &nbsp;

                            <button
                                className="btn btn-primary btn-sm mt-5 rolling-stop-continue"
                                onClick={previousMonthData}
                            >
                                Previous
                            </button>


                            <Accordion.Toggle as={Button} variant="link" eventKey="1" className="arrayButton" onClick={handleAccordionToggle}>
                                <div>
                                    <div className="dailysales text-center dailysalesmargin dailysalesfont row">

                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        {/*    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; */}
                                        Daily Basis Sales Report current report&nbsp;
                                        {/*              <div>
                                            <FaAngleDoubleDown className="float-right toparrow blink-text dropdownArrow" />
                                            <span className="clickHere float-right">
                                                {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                                            </span>
                                        </div> */}
                                    </div>

                                </div>
                            </Accordion.Toggle>
                            {/*              <Accordion.Collapse eventKey="1">
                                <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
                                    <div>
                                        <div className="row d-flex justify-content-center bg-blue ">
                                            <div className="col-md-1.5 d-flex align-items-center justify-content-md-start justify-content-center">
                                                <Form.Group as={Col} controlId="formGridState">
                                                    <label className="form-label text-light mb-0 text-right mr-3">Select Month </label>
                                                    <div>
                                                        <input
                                                            type="month"
                                                            id="monthPicker"
                                                            value={selectedMonth}
                                                            onChange={handleMonthChange}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="col-md-1.5 d-flex justify-content-center mt-3 ml-5">
                                                <button className="btn btn-primary btn-sm " onClick={() => handleSummeryReport()}>Filter</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Accordion.Collapse> */}
                        </Accordion>
                    </div>

                </div>
            </div>

            {
                (!divisionData || !bottom20SoValue) && <LoadingSpinner />
            }
            {(divisionData && regionData && bottom20SoValue) &&

                <div className='main-carosal-container'>
                    <div id="carouselExampleControls" className="carousel slide carosel-div-main" data-ride="carousel">
                        <div className="carousel-inner">
                            {pages.map((PageComponent, index) => (
                                <div key={index} className={`carousel-item ${index === currentPage ? 'active' : ''}`}>
                                    <PageComponent />
                                </div>
                            ))}
                        </div>
                        <a className="carousel-control-prev" role="button" data-slide="prev" onClick={() => goToPage((currentPage - 1 + pages.length) % pages.length)}>
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="sr-only">Previous</span>
                            <p className='button-1002-continur ' >{"<"}</p>
                        </a>
                        <a className="carousel-control-next" role="button" data-slide="next" onClick={() => goToPage((currentPage + 1) % pages.length)}>
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="sr-only">Next</span>
                            <p className='button-1002-continur '  >{'>'}</p>
                        </a>
                    </div>
                </div>

            }


        </div>
    );
};

export default MainRolling;
