import React, { useState } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { FaAngleDoubleDown } from "react-icons/fa";
import CMinPercentageColoredTable from "./CMinPercentageColoredTable";
import DemoLineRecharts from "./DemoLineRecharts";
import CMAnalysisInput from "./CMAnalysisInput";
import CMyeartoyearPaginationChart from "./CMyeartoyearPaginationChart";
import MonthToMonthLineColumnAreaChart from "./MonthToMonthLineColumnAreaChart";
import ApexPiramidChart from "./ApexPiramidChart";

const AblCManalysisContainer = () => {

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-12 col-md-12 col-sm-12 paddingremove sticky-top zindexmodified ">
          <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
            <div className="top_card_CM  justify-content-center align-items-center text-center">
              <Accordion defaultActiveKey="0">
                <Accordion.Toggle
                  as={Button}
                  variant="link"
                  eventKey="1"
                  className="arrayButton"
                  onClick={handleAccordionToggle}
                >
                  <div>
                    <div className="row justify-content-center">
                      <div className="col">
                        <div className="dailysales text-center dailysalesfont">
                          ABL CM Analysis
                        </div>
                      </div>
                    </div>
                    <div>
                      <FaAngleDoubleDown className="float-right toparrow blink-text" />
                      <span className="clickHere float-right">
                        {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                      </span>
                    </div>
                  </div>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="1">
                  <div className="col-lg-12 col-md-12 col-sm-12 paddingremove text-left">
                    <div className="row text-left">
                      <div className="col text-left">
                        <CMAnalysisInput />
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          </div>

        </div>

        {/* <div className="col-12 col-md-12 col-lg-12 col-xl-6 paddingremove ">
          <StackedBarchart />
        </div> */}

        {/* <div className="col-12 col-md-12 col-lg-8 col-xl-8 paddingremove">
          <ApexPiramidChart />
        </div> */}
        <div className="col-12 col-md-12 col-lg-8 col-xl-8 paddingremove">
          <MonthToMonthLineColumnAreaChart />
        </div>
        {/* <div className="col-12 col-md-12 col-lg-6 col-xl-6 paddingremove">
          <TotalFirstBarChart />
        </div> */}
        {/* <div className="col-12 col-md-12 col-lg-6 col-xl-6 paddingremove">
          <CMAnalysisYTDC2 />
        </div> */}
        <div className="col-12 col-md-12 col-lg-4 col-xl-4 paddingremove bgcolorwhite">
          <CMyeartoyearPaginationChart />
        </div>
        {/* <div className="col-12 col-md-12 col-lg-6 col-xl-6 paddingremove">
          <StackBarCharFaridBhai />
        </div> */}
        <div className="col-12 col-md-12 col-lg-12 col-xl-12 paddingremove marginremoveCM">
          <CMinPercentageColoredTable />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 paddingremove ">
          <DemoLineRecharts />
        </div>

      </div>
    </div>
  );
};
export default AblCManalysisContainer;
