import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { MdOutlineDateRange } from "react-icons/md";
import "react-datepicker/dist/react-datepicker.css";
import { getAreaListByDivisionEntryNew, getButtomperformanceForPoorperformance, getDivisionListByZoneEntryNew, getOverAllPerformanceForBiscutChanel, getOverAllPerformanceForBiscutChanelButtom, getOverAllPerformanceForBiscutChanelButtomTop, getOverallAreaTerritory, getOverallPerformancForTopFastButtomChanel, getOverallZoneDivisionList, getTerritoryEntryNew, getTerritoryEntryNewZone, getZoneListEntryNew, locationWisBottomperformerFastChanelDataView, locationWisTopperformerFastChanelDataView, locationWiseBottomPerformerForBiscutChanel, locationWiseBottomPerformerForFastChanel, locationWiseBottomperformerBiskutChanelDataView, locationWiseTopPerformerForBiscutChanel, locationWiseTopPerformerForFastChanel, locationWiseTopperformerBiskutChanelDataView } from "../Redux/MapAction";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { Accordion } from "react-bootstrap";
import { FaAngleDoubleDown } from 'react-icons/fa';
import LoadingSpinner from "../../modules/Billing/Components/LoadingSpinner";
import { useHistory } from "react-router-dom";
import { sub } from "date-fns";
import TopBiscutChanelModal from "./Modals/TopBiscutChanelModal";
import { getAreaListByDivisionEntry, getDivisionListByZoneEntry, getTerritoryEntry, getZoneListEntry } from "../../modules/Cfo/DistributorReportAction";
import { GetDSMType, GetDistributorByTerritoryID, GetNSMType, GetRMType } from "../../modules/Cfo/DashboardCartAction";


export const PerformanceFilters = ({ showColumn2, toggleColumns2 }) => {

  const saleType = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterId, setFilterID] = useState([]);
  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(0);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(0);
  const [selectArea, setSelectArea] = useState(0);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(0);
  const [salesType, setSalesType] = useState(1);



  const [zonePoor, SetzonePoor] = useState([]);
  const [divisionPoor, SetDivisionPoor] = useState([]);
  const [selectZonePoor, setSelectZonePoor] = useState(0);
  const [areaPoor, setAreaPoor] = useState([]);
  const [selectDivisionPoor, setSelectDivisionPoor] = useState(0);
  const [selectAreaPoor, setSelectAreaPoor] = useState(0);
  const [selectGeoTypePoor, setSelectGeoTypePoor] = useState(2);
  const [terrioryPoor, setTerritoryPoor] = useState([]);
  const [selectTerritoryPoor, setSelectTerritoryPoor] = useState(0);

  const closeModal = () => {
    setIsModalOpen(false);
  };




  const currentDate = new Date();

  //const initialMonth = `${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${(currentDate.getFullYear() % 100).toString().padStart(2, '0')}`;
  const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
  const currentYearString = currentDate.getFullYear().toString();
   const [selectedMonth, setSelectedMonth] = useState(initialMonth);
 

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
   };


  const dispatch = useDispatch();
  const { register, setValue } = useForm();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };
  const [id, setId] = useState(50);
  const [subId, setSubId] = useState(600);
  const [bId, setBid] = useState(50);
  const [subBId, setSubBId] = useState(600);
  const [top, setTop] = useState(10);
  const [btom, setBtom] = useState(10)
  var d = new Date();
  var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
  var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";


  useEffect(() => {
    initialData();
  }, [dispatch])

  let saleTypeValue = saleType?.current?.checked ? 2 : 1;

  const initialData = async () => {
    let zoneData = await getZoneListEntryNew();
    let saleTypeValue = saleType?.current?.checked ? 2 : 1;
    let zoneDataPoor = await getZoneListEntryNew();


    Setzone(zoneData.data);
    SetzonePoor(zoneDataPoor.data);
    dispatch(locationWiseTopPerformerForBiscutChanel(selectZone, selectDivision, selectArea, selectTerritory, selectedMonth, top, salesType))
    dispatch(locationWiseTopPerformerForFastChanel(selectZone, selectDivision, selectArea, selectTerritory, selectedMonth, top, salesType))
    dispatch(locationWiseTopperformerBiskutChanelDataView(selectZone, selectDivision, selectArea, selectTerritory, selectedMonth,   salesType))
    dispatch(locationWisTopperformerFastChanelDataView(selectZone, selectDivision, selectArea,  selectTerritory, selectedMonth,   salesType))

    dispatch(locationWiseBottomPerformerForBiscutChanel(selectZonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth, btom, salesType))
    dispatch(locationWiseBottomPerformerForFastChanel(selectZonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth, btom, salesType))
    dispatch(locationWiseBottomperformerBiskutChanelDataView(selectZonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth,  salesType))
    dispatch(locationWisBottomperformerFastChanelDataView( zonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth,  salesType))

    await dispatch(getOverallZoneDivisionList())
    await dispatch(getOverallAreaTerritory())


  }



  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const filterData = () => {
    setIsAccordionOpen(false);


    dispatch(locationWiseTopPerformerForBiscutChanel(selectZone, selectDivision, selectArea, selectTerritory, selectedMonth, top, salesType))
    dispatch(locationWiseTopPerformerForFastChanel(selectZone, selectDivision, selectArea, selectTerritory, selectedMonth, top, salesType))
    dispatch(locationWisTopperformerFastChanelDataView(selectZone, selectDivision, selectArea,  selectTerritory, selectedMonth,   salesType))
    dispatch(locationWisBottomperformerFastChanelDataView( zonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth,  salesType))

    dispatch(locationWiseTopperformerBiskutChanelDataView(selectZone, selectDivision, selectArea, selectTerritory, selectedMonth,  salesType))
    dispatch(locationWiseBottomPerformerForBiscutChanel(selectZonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth, btom, salesType))
    dispatch(locationWiseBottomPerformerForFastChanel(selectZonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth, btom, salesType))
    dispatch(locationWiseBottomperformerBiskutChanelDataView(selectZonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth,  salesType))

  }





  const topDataList = useSelector(state => state?.mapData?.topperformanceBisckutChanel?.data?.allData);
  const topDataListIsLOading = useSelector(state => state?.mapData?.topNewBisckutIsLoading);
  const topFastChanel = useSelector(state => state?.mapData?.topNewFastChanel?.allData)
  const biscutChanelButtomIsLoading = useSelector(state => state?.mapData?.topNewFastChanelIsLoading)
  const poorBiskutChanel = useSelector(state => state?.mapData?.bottomNewBiskutChanel?.allData);
  const poorBiskutChanelcc = useSelector(state => state?.mapData?.bottomNewBiskutChanel);
  const bisckutChanelTopButtomDataisLoading = useSelector(state => state?.mapData?.bottomNewBisckutIsLoading)
  const poorButtomdataList = useSelector(state => state?.mapData?.BottomNewFastChanel?.allData)
  const poorButtomdataListisLoading = useSelector(state => state?.mapData?.BottomNewFastChanelIsLoading);
  const areaList = useSelector(state => state?.mapData?.zoneDivisionList)
  const SubLocationList = useSelector(state => state?.mapData?.areaTerritory)
  const topNewBiskutChanel = useSelector(state => state?.mapData?.topNewBiskutChanel?.allData)
  const topBiskutChanelData = useSelector(state => state?.mapData?.topBiskutChanelDataView?.allData)
  const topBiskutChanelDataViewIsLoading = useSelector(state => state?.mapData?.topBiskutChanelDataViewIsLoading)
  const bottomBiskutChanelData = useSelector(state => state?.mapData?.BOTTOMBiskutChanelDataView?.allData)
  const BOTTOMBiskutChanelDataViewIsLoading = useSelector(state => state?.mapData?.BOTTOMBiskutChanelDataViewIsLoading)
  const topFastChanelData = useSelector(state => state?.mapData?.topFastChanelDataView?.allData)
  const bottomFastChanelData = useSelector(state => state?.mapData?.bottomFastChanelDataView?.allData)


  const handleTopBiscutClick = (enroll) => {
    setIsModalOpen(true)
    setFilterID(topBiskutChanelData?.filter(data => data?.id == enroll))

  }

  console.log('topBiskutChanelData', topBiskutChanelData)

  const handletopFast = (enroll) => {
    console.log('enroll', enroll)
    setIsModalOpen(true)
    setFilterID(topFastChanelData?.filter(data => data?.id == enroll))

  }
  const handleBottomBiscutClick = (enroll) => {
    console.log('ennn', enroll)
    setIsModalOpen(true)
    setFilterID(bottomBiskutChanelData?.filter(data => data?.id == enroll))

  }

  console.log('topFastChanelData', topFastChanelData)

  const handleBottomFastClick = (enroll) => {
    console.log('ennn', enroll)
    setIsModalOpen(true)
    setFilterID(bottomFastChanelData?.filter(data => data?.id == enroll))

  }

  const TopLine = [{ value: 10, label: 'Top 10' },
  { value: 20, label: 'Top 20' },
  { value: 30, label: 'Top 30' },
  { value: 40, label: 'Top 40' },
  { value: 50, label: 'Top 50' },
  { value: 60, label: 'Top 60' },
  { value: 70, label: 'Top 70' },
  { value: 80, label: 'Top 80' },
  { value: 90, label: 'Top 90' },
  { value: 100, label: 'Top 100' }]

  const BottomLine = [{ value: 10, label: 'Bottom 10' },
  { value: 20, label: 'Bottom 20' },
  { value: 30, label: 'Bottom 30' },
  { value: 40, label: 'Bottom 40' },
  { value: 50, label: 'Bottom 50' },
  { value: 60, label: 'Bottom 60' },
  { value: 70, label: 'Bottom 70' },
  { value: 80, label: 'Bottom 80' },
  { value: 90, label: 'Bottom 90' },
  { value: 100, label: 'Bottom 100' }]


  const navigate = useHistory();


  const handleClicktoNewTab = (id) => {
    navigate.push({
      pathname: `/sales-dashboard/performer/${id}`,
      state: { id: id }
    });

  }

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntryNew(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntryNew(id, selectGeoType);
    setArea(areaData.data);
  };
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntryNew(id, selectGeoType);
    setTerritory(territoryData.data);
  };

  const getTerritoryDataZone = async (id) => {
    let territoryData = await getTerritoryEntryNewZone(id, selectGeoType);
    setTerritory(territoryData.data);
  };


  const divisionListPoor = async (id) => {
    let divisionData = await getDivisionListByZoneEntryNew(id, selectGeoType);
    SetDivisionPoor(divisionData.data);
  };
  const getAreaDataPoor = async (id) => {
    let areaData = await getAreaListByDivisionEntryNew(id, selectGeoType);
    setAreaPoor(areaData.data);
  };
  const getTerritoryDataPoor = async (id) => {
    let territoryData = await getTerritoryEntryNew(id, selectGeoType);
    setTerritoryPoor(territoryData.data);
  };
  const getTerritoryDataPoorZone = async (id) => {
    let territoryData = await getTerritoryEntryNewZone(id, selectGeoType);
    setTerritoryPoor(territoryData.data);
  };



  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };

  const zoneDropdownDataPoor = () => {
    let options = [];
    if (zonePoor?.length > 0) {
      zonePoor.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };
  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const divisionDropdownDataPoor = () => {
    let options = [];
    if (divisionPoor?.length > 0) {
      divisionPoor.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const areaDropdownDataPoor = () => {
    let options = [];
    if (areaPoor?.length > 0) {
      areaPoor.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const territoryDropdownDataPoor = () => {
    let options = [];
    if (terrioryPoor?.length > 0) {
      terrioryPoor.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };


  const topBiskut = topNewBiskutChanel && topNewBiskutChanel.length > 0 ? topNewBiskutChanel[0]?.position : null;
  const topFast = topFastChanel && topFastChanel.length > 0 ? topFastChanel[0]?.position : null;
  const ButtomBiskut = poorBiskutChanel && poorBiskutChanel.length > 0 ? poorBiskutChanel[0]?.position : null;
  const bottomFast = poorButtomdataList && poorButtomdataList.length > 0 ? poorButtomdataList[0]?.position : null;





  console.log('topBiskutChanelData', topBiskutChanelData)


  return (

    <div>


      {isModalOpen && (
        <TopBiscutChanelModal
          onClose={closeModal}
          filterId={filterId}
          topBiskutChanelData={topBiskutChanelData}
        />
      )}

      <div className="container-fluid top_card3-map rounded mb-5">

        <Accordion activeKey={isAccordionOpen ? "1" : "0"}>
          <Button variant="warning" onClick={toggleColumns2}>{showColumn2 ? '<<' : '>>'}</Button>

          <Accordion.Toggle as={Button} variant="link" eventKey="1" className="arrayButton" onClick={handleAccordionToggle}>
            <div>

              <div className="dailysales text-center dailysalesmargin dailysalesfont row  ">
                <span className="sales-title-map" style={{ width: '260px', margin: 'auto' }} > Sales Performers
                  <div>
                    <FaAngleDoubleDown className="float-right toparrow blink-text dropdownArrow" />
                    <span className="clickHere float-right">
                      {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1" onEnter={() => setIsAccordionOpen(true)} onExit={() => setIsAccordionOpen(false)}>
            <div>
              <div className="row d-flex justify-content-center bg-blue ">
                <div className="col-md-1.5">
                  <div className="switch-container mt-5 mb-3">
                    <div>
                      <p className="mb-0 text-light">Primary </p>
                    </div>
                    <div>
                      <label className="switchMap">
                        <input type="checkbox" onChange={(e) => setSalesType(e.target.checked ? 2 : 1)}
 /* ref={saleType} */ />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div>
                      <p className="mb-0 text-light">Secondary </p>
                    </div>
                  </div>
                </div>



                <div className="col-md-1.5 d-flex align-items-center justify-content-md-start justify-content-center">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label text-light mb-0 text-right mr-3">Select Month </label>
                    <div>
                      <input
                        type="month"
                        id="monthPicker"
                        value={selectedMonth}
                        onChange={handleMonthChange}
                      />
                    </div>
                  </Form.Group>
                </div>


                <div className="col-md-1.5 d-flex justify-content-center mt-3 ml-5">
                  <button className="btn btn-primary btn-sm " onClick={() => filterData()}>Filter</button>
                </div>
              </div>
              <div className="row mt-5">

                <div className="col-md-6"  >
                  <div className="bg-primary p-2 rounded mb-2">
                    <h5 className="text-center text-light">Top Performers</h5>

                    <div >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Select Zones</label>
                        <RHFInput
                          className="formSelect pt-0"
                          as={<Select options={zoneDropdownData()} />}
                          rules={{ required: false }}
                          name="zoneData"
                          placeholder="All"
                          register={register}
                          value=""
                          isClearable
                          onChange={(option) => {
                            divisionList(option?.value);
                            getAreaData(option?.value);
                            getTerritoryDataZone(option?.value);
                            setSelectZone(option?.value);
                            dispatch(GetNSMType(option?.value));
                            dispatch(GetDistributorByTerritoryID(option?.value));
                            dispatch(locationWiseTopPerformerForBiscutChanel(option?.value, selectDivision, selectArea, selectTerritory, selectedMonth, top, salesType))
                            dispatch(locationWiseTopPerformerForFastChanel(option?.value, selectDivision, selectArea, selectTerritory, selectedMonth, top, salesType))
                            dispatch(locationWiseTopperformerBiskutChanelDataView(option?.value, selectDivision, selectArea, selectTerritory, selectedMonth,   salesType))
                            dispatch(locationWisTopperformerFastChanelDataView(option?.value, selectDivision, selectArea, selectTerritory, selectedMonth,   salesType))
                            /*             setValue("divisionData", "");
                                        setValue("areaData", "");
                                        setValue("territory", "");
                                        setValue("distributor", ""); */

                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>

                    <div >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Select Division</label>
                        <RHFInput
                          className="formSelect pt-0"
                          as={<Select options={divisionDropdownData()} />}
                          rules={{ required: false }}
                          name="divisionData"
                          register={register}
                          value=""
                          isClearable
                          onChange={(option) => {

                            getAreaData(option?.value);
                            setSelectDivision(option?.value);
                            dispatch(locationWiseTopPerformerForBiscutChanel(selectZone, option?.value, selectArea, selectTerritory, selectedMonth, top, salesType))
                            dispatch(locationWiseTopPerformerForFastChanel(selectZone, option?.value, selectArea, selectTerritory, selectedMonth, top, salesType))
                            dispatch(locationWiseTopperformerBiskutChanelDataView(selectZone, option?.value, selectArea, selectTerritory, selectedMonth,  salesType))
                            dispatch(locationWisTopperformerFastChanelDataView(selectZone, option?.value, selectArea, selectTerritory, selectedMonth,  salesType))
                            dispatch(GetDSMType(option?.value));
                            dispatch(
                              GetDistributorByTerritoryID(selectZone, option?.value)
                            );
                            /*           setValue("areaData", "");
                                      setValue("territory", "");
                                      setValue("distributor", ""); */
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>

                    <div  >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Select Region</label>
                        <RHFInput
                          className="formSelect pt-0"
                          as={<Select options={areaDropdownData()} />}
                          rules={{ required: false }}
                          name="areaData"
                          register={register}
                          value=""
                          isClearable
                          onChange={(option) => {
                            // setType(option.value)
                            getTerritoryData(option?.value);
                            setSelectArea(option?.value);
                            dispatch(locationWiseTopPerformerForBiscutChanel(selectZone, selectDivision, option?.value, selectTerritory, selectedMonth, top, salesType))
                            dispatch(locationWiseTopPerformerForFastChanel(selectZone, selectDivision, option?.value, selectTerritory, selectedMonth, top, salesType))
                            dispatch(locationWiseTopperformerBiskutChanelDataView(selectZone, selectDivision, option?.value, selectTerritory, selectedMonth,  salesType))
                            dispatch(locationWisTopperformerFastChanelDataView(selectZone, selectDivision, option?.value, selectTerritory, selectedMonth,  salesType))
                            dispatch(GetRMType(option?.value));
                            dispatch(
                              GetDistributorByTerritoryID(
                                selectZone,
                                selectDivision,
                                option?.value
                              )
                            );
                            /*                             setValue("territory", "");
                                                        setValue("distributor", ""); */
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>

                    <div  >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Select Territory</label>
                        <RHFInput
                          className="formSelect pt-0"
                          as={<Select options={territoryDropdownData()} />}
                          rules={{ required: false }}
                          name="territory"
                          register={register}
                          value=""
                          isClearable
                          onChange={(option) => {

                            setSelectTerritory(option?.value);
                            dispatch(locationWiseTopPerformerForBiscutChanel(selectZone, selectDivision, selectArea, option?.value, selectedMonth, top, salesType))
                            dispatch(locationWiseTopPerformerForFastChanel(selectZone, selectDivision, selectArea, option?.value, selectedMonth, top, salesType))
                            dispatch(locationWiseTopperformerBiskutChanelDataView(selectZone, selectDivision, selectArea, option?.value, selectedMonth,   salesType))
                            dispatch(locationWisTopperformerFastChanelDataView(selectZone, selectDivision, selectArea, option?.value, selectedMonth,   salesType))

                            dispatch(
                              GetDistributorByTerritoryID(
                                selectZone,
                                selectDivision,
                                selectArea,
                                option?.value
                              )
                            );
                            /*                            setValue("soData", "");
                                                       setValue("distributor", ""); */
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>


                    <div  >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Top</label>
                        <RHFInput
                          className="formSelect pt-0 "
                          as={<Select options={TopLine} />}
                          rules={{ required: false }}
                          name="topPerformnace"
                          register={register}
                          placeholder="Top 10"
                          isClearable
                          onChange={(option) => {
                            setTop(option?.value);
                            dispatch(getOverAllPerformanceForBiscutChanel(id, subId, option?.value || top, selectedMonth));
                            dispatch(getOverAllPerformanceForBiscutChanelButtomTop(id, subId, option?.value || top, selectedMonth));
                            dispatch(locationWiseTopPerformerForBiscutChanel(selectZone, selectDivision, selectArea, selectTerritory, selectedMonth, option?.value, salesType))
                            dispatch(locationWiseTopPerformerForFastChanel(selectZone, selectDivision, selectArea, selectTerritory, selectedMonth, option?.value, salesType))
 
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>



                  </div>
                </div>



                <div className="col-md-6">
                  <div className="bg-primary p-2 rounded mb-2">
                    <h5 className="text-center text-light">Lowest Performer</h5>
                    <div >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Select Zones</label>
                        <RHFInput
                          className="formSelect pt-0"
                          as={<Select options={zoneDropdownDataPoor()} />}
                          rules={{ required: false }}
                          name="zoneDatapoor"
                          register={register}
                          value=""
                          placeholder="All"
                          isClearable
                          onChange={(option) => {
                            divisionListPoor(option?.value);
                            getAreaDataPoor(option?.value);
                            getTerritoryDataPoorZone(option?.value);
                            setSelectZonePoor(option?.value);
                            dispatch(GetNSMType(option?.value));
                            dispatch(GetDistributorByTerritoryID(option?.value));
                            dispatch(locationWiseBottomPerformerForBiscutChanel(option?.value, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth, btom, salesType))
                            dispatch(locationWiseBottomPerformerForFastChanel(option?.value, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth, btom, salesType))
                            dispatch(locationWiseBottomperformerBiskutChanelDataView(option?.value, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth,  salesType))
                            dispatch(locationWisBottomperformerFastChanelDataView(option?.value, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth,  salesType))

                            /*          setValue("divisionDataPoor", "");
                                     setValue("areaDataPoor", "");
                                     setValue("territoryPoor", "");
                                     setValue("distributorPoor", ""); */
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>

                    <div >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Select Division</label>
                        <RHFInput
                          className="formSelect pt-0"
                          as={<Select options={divisionDropdownDataPoor()} />}
                          rules={{ required: false }}
                          name="divisionDataPoor"
                          register={register}
                          value=""
                          isClearable
                          onChange={(option) => {
                            getAreaDataPoor(option?.value);
                            setSelectDivisionPoor(option?.value);
                            dispatch(GetDSMType(option?.value));
                            dispatch(locationWiseBottomPerformerForBiscutChanel(selectZonePoor, option?.value, selectAreaPoor, selectTerritoryPoor, selectedMonth, btom, salesType))
                            dispatch(locationWiseBottomPerformerForFastChanel(selectZonePoor, option?.value, selectAreaPoor, selectTerritoryPoor, selectedMonth, btom, salesType))
                            dispatch(locationWiseBottomperformerBiskutChanelDataView(selectZonePoor, option?.value, selectAreaPoor, selectTerritoryPoor, selectedMonth,   salesType))
                            dispatch(locationWisBottomperformerFastChanelDataView(selectZonePoor, option?.value, selectAreaPoor, selectTerritoryPoor, selectedMonth,   salesType))

                            dispatch(
                              GetDistributorByTerritoryID(selectZone, option?.value)
                            );
                            /*             setValue("areaDataPoor", "");
                                        setValue("territoryPoor", "");
                                        setValue("distributorPoor", ""); */
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>

                    <div  >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Select Region</label>
                        <RHFInput
                          className="formSelect pt-0"
                          as={<Select options={areaDropdownDataPoor()} />}
                          rules={{ required: false }}
                          name="areaDataPoor"
                          register={register}
                          value=""
                          isClearable
                          onChange={(option) => {
                            // setType(option.value)
                            dispatch(locationWiseBottomPerformerForBiscutChanel(selectZonePoor, selectDivisionPoor, option?.value, selectTerritoryPoor, selectedMonth, btom, salesType))
                            dispatch(locationWiseBottomPerformerForFastChanel(selectZonePoor, selectDivisionPoor, option?.value, selectTerritoryPoor, selectedMonth, btom, salesType))
                            dispatch(locationWiseBottomperformerBiskutChanelDataView(selectZonePoor, selectDivisionPoor, option?.value, selectTerritoryPoor, selectedMonth,  salesType))
                            dispatch(locationWisBottomperformerFastChanelDataView(selectZonePoor, selectDivisionPoor, option?.value, selectTerritoryPoor, selectedMonth,  salesType))

                            getTerritoryDataPoor(option?.value);
                            setSelectAreaPoor(option?.value);
                            dispatch(GetRMType(option?.value));
                            dispatch(
                              GetDistributorByTerritoryID(
                                selectZone,
                                selectDivision,
                                option?.value
                              )
                            );
                            /*                             setValue("territoryPoor", "");
                                                        setValue("distributorPoor", ""); */
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>

                    <div  >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Select Territory</label>
                        <RHFInput
                          className="formSelect pt-0"
                          as={<Select options={territoryDropdownDataPoor()} />}
                          rules={{ required: false }}
                          name="territoryPoor"
                          register={register}
                          value=""
                          isClearable
                          onChange={(option) => {
                            setSelectTerritoryPoor(option?.value);
                            dispatch(locationWiseBottomPerformerForBiscutChanel(selectZonePoor, selectDivisionPoor, selectAreaPoor, option?.value, selectedMonth, btom, salesType))
                            dispatch(locationWiseBottomPerformerForFastChanel(selectZonePoor, selectDivisionPoor, selectAreaPoor, option?.value, selectedMonth, btom, salesType))
                            dispatch(locationWiseBottomperformerBiskutChanelDataView(selectZonePoor, selectDivisionPoor, selectAreaPoor, option?.value, selectedMonth,  salesType))
                            dispatch(locationWisBottomperformerFastChanelDataView(selectZonePoor, selectDivisionPoor, selectAreaPoor, option?.value, selectedMonth,  salesType))

                            dispatch(
                              GetDistributorByTerritoryID(
                                selectZone,
                                selectDivision,
                                selectArea,
                                option?.value
                              )
                            );
                            //setValue("distributor", "");
                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>

                    <div  >
                      <Form.Group as={Col} controlId="formGridState">
                        <label className="form-label text-light mb-0 text-right mr-3">Bottom</label>
                        <RHFInput
                          className="formSelect pt-0 "
                          as={<Select options={BottomLine} />}
                          rules={{ required: false }}
                          name="top"
                          register={register}
                          placeholder="Bottom 10"
                          isClearable
                          onChange={(option) => {
                            setBtom(option?.value);
                            dispatch(getOverallPerformancForTopFastButtomChanel(bId, subBId, option?.value || btom, selectedMonth));
                            dispatch(getButtomperformanceForPoorperformance(bId, subBId, option?.value || btom, selectedMonth))
                            dispatch(locationWiseBottomPerformerForBiscutChanel(selectZonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth, option?.value || btom, salesType))
                            dispatch(locationWiseBottomPerformerForFastChanel(selectZonePoor, selectDivisionPoor, selectAreaPoor, selectTerritoryPoor, selectedMonth, option?.value || btom, salesType))

                          }}
                          setValue={setValue}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </Accordion.Collapse>
        </Accordion>


      </div>


      <div className="row main-row-performance">
        <div className="col-md-6">


          <div className="rounded">
            <div className="text-center bg-primary rounded p-1 mb-2">
              <h5 className="text-light m-0">Biscuit Channel {topBiskut && "("} {topBiskut} {topBiskut && ")"} </h5>
              {(topDataListIsLOading || topBiskutChanelDataViewIsLoading) && (
                <LoadingSpinner text="" />
              )}
            </div>

            <div>
              {topNewBiskutChanel?.length <= 0 && <p>
                No Available data here

              </p>}
            </div>

            {(!topDataListIsLOading && !topBiskutChanelDataViewIsLoading ) &&(
              <>
                <div className="container mb-3 list-container">

                  <div className="row">
                    <div className="col-5 px-0">
                      <ul className="text-left pl-1 list-name ">
                        {topNewBiskutChanel?.map((item, i) => (
                          <li key={i}>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-7 px-0">
                      <ul className="list-group text-center">
                        {topNewBiskutChanel?.map((item, i) => (
                          <li /* onClick={() => handleClicktoNewTab(item.performance)} */ onClick={() => handleTopBiscutClick(item.enroll)} key={i}>{item.zone_name} - <span /* style={{ color: item.performance >= 100 ? 'green' : 'white' }} */  >
                            {item.performance}%</span></li>


                        ))}
                      </ul>
                      <div className="last-li">&nbsp;</div>
                    </div>
                  </div>

                </div>
              </>)}

          </div>

          <div className="rounded main-row-performance">
            <div className="text-center bg-primary rounded p-1 mb-2">
              <h5 className="text-light m-0">Fast Channel {topFast && "("} {topFast} {topFast && ")"}</h5>
              {(biscutChanelButtomIsLoading) && (
                <LoadingSpinner text="" />
              )}
            </div>

            <div>
              {topFastChanel?.length <= 0 && <p>
                No Available data here

              </p>}
            </div>


            {!biscutChanelButtomIsLoading && (
              <>
                <div className="container mb-3 list-container">


                  <div className="row">
                    <div className="col-5 px-0">
                      <ul className="text-left pl-1 list-name">
                        {topFastChanel?.map((item, i) => (
                          <li key={i}>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-7 px-0">
                      <ul className="list-group text-center">
                        {topFastChanel?.map((item, i) => (
                          <li  onClick={() => handletopFast(item.enroll)} key={i}>{item.zone_name} - <span /* style={{ color: selectColor(item.performance) }} */ >{item.performance}%</span></li>
                        ))}
                      </ul>
                      <div className="last-li">&nbsp;</div>
                    </div>
                  </div>




                </div>
              </>)}
          </div>




        </div>

        <div className="col-md-6">




          <div className="rounded">
            <div className="text-center bg-primary rounded p-1 mb-2">
              <h5 className="text-light m-0">Biscuit Channel {ButtomBiskut && "("} {ButtomBiskut} {ButtomBiskut && ")"}</h5>
              {(bisckutChanelTopButtomDataisLoading) && (
                <LoadingSpinner text="" />
              )}
            </div>

            <div>
              {poorBiskutChanel?.length <= 0 && <p>
                No Available data here

              </p>}
            </div>

            {!bisckutChanelTopButtomDataisLoading && (
              <>
                <div className="container mb-3 list-container">



                  <div className="row">
                    <div className="col-5 px-0">
                      <ul className="text-left pl-1 list-name">
                        {poorBiskutChanel?.map((item, i) => (
                          <li key={i}>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-7 px-0">
                      <ul className="list-group text-center">
                        {poorBiskutChanel?.map((item, i) => (
                          <li  onClick={() => handleBottomBiscutClick(item.enroll)} key={i}>{item.zone_name} - <span /* style={{ color: selectColor(item.performance) }} */  >{item.performance}%</span></li>
                        ))}
                      </ul>
                      <div className="last-li">&nbsp;</div>
                    </div>
                  </div>



                </div>
              </>)}
          </div>

          <div className="rounded main-row-performance">
            <div className="text-center bg-primary rounded p-1 mb-2">
              <h5 className="text-light m-0">Fast Channel {bottomFast && "("} {bottomFast}{bottomFast && ")"}</h5>
              {(poorButtomdataListisLoading || BOTTOMBiskutChanelDataViewIsLoading) && (
                <LoadingSpinner text="" />
              )}
            </div>

            <div>
              {poorButtomdataList?.length <= 0 && <p>
                No Available data here

              </p>}
            </div>

            {(!poorButtomdataListisLoading && !BOTTOMBiskutChanelDataViewIsLoading) && (
              <>
                <div className="container mb-3 list-container">


                  <div className="row">
                    <div className="col-5 px-0">
                      <ul className="text-left pl-1 list-name">
                        {poorButtomdataList?.map((item, i) => (
                          <li key={i}>{item.name}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="col-7 px-0">
                      <ul className="list-group text-center">
                        {poorButtomdataList?.map((item, i) => (
                          <li  onClick={() => handleBottomFastClick(item.enroll)} key={i}>{item.zone_name} - <span /* style={{ color: selectColor(item.performance) }} */ >{item.performance}%</span></li>
                        ))}
                      </ul>
                      <div className="last-li">&nbsp;</div>
                    </div>
                  </div>

                </div>
              </>)}
          </div>

        </div>

      </div >
    </div>
  );
};

export default PerformanceFilters;
