import { GetDdl } from "../../../../Global/GlobalDdl";
import * as Types from "../Types";
const initialState = {
    userInfo: {},
    employeeSearch: [],
    ddlUnit:[],
    dashboardData:null,
    targetQty:[],
    itemDataInput: {
        fromDate:null,
        customerGroup:null,
        unit: "",
        category: "",
        targetQty: null,
        targetValue:null,
        measurement:null

      },

    reportData:{
        fromDate:null,
        toDate:null,
        unit:null,
        categoryId:null,
        targetyear:null,
        targetMonth:null,
        customerGroupID:null,
        categoryId:null,
        measurement:1


    }
};
const AccountsReducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        // case Types.GET_UNIT_NAME:
        //     return {
        //         ...state,
        //         ddlUnit: GetDdl(action.payload.data, 'intUnitID', 'unitName')
        //     };
        //     case Types.CHANGE_PRODUCT_INPUT:
        //         console.log('action.payload', action.payload);
        //         const itemDataInput = { ...state.itemDataInput };
        //         itemDataInput[action.payload.name] = action.payload.value;
        //         return {
        //             ...state,
        //             itemDataInput
                   
        //         };
        //     case Types.CHANGE_PRODUCT_INPUT_REPORT:
        //         const reportData = { ...state.reportData };
        //         reportData[action.payload.name] = action.payload.value;
        //         return {
        //             ...state,
        //             reportData
        //         };

        //     case Types.GET_CATEGORY_NAME:
        //     return {
        //         ...state,
        //         ddlCategory: GetDdl(action.payload.data, 'productCategoryId', 'prodCatName')
        //     };
        //     case Types.GET_CUSTOMER_GROUP:
        //     return {
        //         ...state,
        //         ddlCusGroup: GetDdl(action.payload.data.result, 'customerGroupId', 'customerGroupName')
        //     };
        //     case Types.CHANGE_HANDLE_DATE_MANAGE:
               
        //         var getYear = action.payload.name.substring(0, 4);
        //         var getMonth = action.payload.name.substring(5, 7);
        //         var lstday = new Date(getYear, getMonth, 0).getDate();

        //         var fromDate = `${action.payload.name}`+"-"+"01";
        //         var toDate = `${action.payload.name}`+"-"+lstday;
        //         const getDate = { ...state.reportData };
        //         getDate.fromDate=fromDate;
        //         getDate.toDate=toDate;
        //         getDate.targetMonth=parseInt(getMonth);
        //         getDate.targetyear=getYear;
        //     return {
        //         ...state,
        //         reportData:getDate
               
        //     };
        //     case Types.GET_DASHBOARD_DATA:
        //         var conArray=[];
        //        const dateWiseSales = action.payload.data.detailsReport;
        //        console.log('action.payload.data.detailsReport', action.payload.data);
        //        console.log('dateWiseSales', dateWiseSales);

              
        //         for (let i = 0; i < dateWiseSales.length; i++) {
        //             conArray.push(state.reportData.measurement ==1?dateWiseSales[i].price:dateWiseSales[i].qty);
                    
        //         }

                
        //     //    const convertArray = Object.values(dateWiseSales);
        //     //    console.log('convertArray', convertArray);

        //     return {
        //         ...state,
        //         dashboardData:action.payload.data,
        //         targetQty:conArray
               
        //     };
        //     case Types.GET_AFML_DASHBOARD_DATA:
        //         var conArray=[];
        //        const afmldateWiseSales = action.payload.data.salesDetailsReport;

        
        //         for (let i = 0; i < afmldateWiseSales.length; i++) {
        //             conArray.push(state.reportData.measurement ==1?afmldateWiseSales[i].price:afmldateWiseSales[i].qty);
                    
        //         }
        //     //    const convertArray = Object.values(dateWiseSales);
        //     //    console.log('convertArray', convertArray);

        //     return {
        //         ...state,
        //         dashboardData:action.payload.data,
        //         salesQty:conArray
               
        //     };
        default:
            break;
    }
    return newState;
};

export default AccountsReducer;

