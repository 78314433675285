import React from "react";
import ReactToPrint from "react-to-print-advanced";
import { currentdate } from "../PrintDwopdown/DateHelper";

const Print = (props) => {

  const current = currentdate();
  const componentRef = props.refvalue;
 

  return (
    <>
      {
        <ReactToPrint  
          // trigger={() => <button className="cutom-print-btn" >Print</button>}
          trigger={() =>  <i class="fas fa-file-alt">Print</i>}
          content={() => componentRef.current}
          pageStyle="background:blue"
        />
      }
    </>
  );
};
export default Print;
