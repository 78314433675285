import * as Types from "./MapType";

const initialState = {
    toDivisionDataIsLoading: false,
    toZoneDataIsLoading: false,
    toRegionIsLoading: false,
    toTerritoryIsLoading: false,
    zoneDataModalIsLoading: false,
    divisionDataModalIsLoading: false,
    regionDatamodalIsLoading: false,
    territoryDataIsLodingForModal: false,
    bisckutChanelIsLoading: false,
    biscutChanelButtomIsLoading: false,
    bisckutChanelTopButtomDataisLoading: false,
    poorButtomdataListisLoading: false,
    topNewBisckutIsLoading: false,
    bottomNewBisckutIsLoading: false,
    topNewFastChanelIsLoading: false,
    BottomNewFastChanelIsLoading: false,
    topBiskutChanelDataViewIsLoading: false,
    BOTTOMBiskutChanelDataViewIsLoading: false,
    divisionData: [],
    zoneData: [],
    regionData: [],
    territoryData: [],
    zoneDataForModal: [],
    divisionDataForModal: [],
    regionDataForModal: [],
    territoryDataForModal: [],
    topperformanceBisckutChanel: [],
    topFastChanel: [],
    poorBiskutChanel: [],
    poorButtomdataList: [],
    zoneDivisionList: [],
    areaTerritory: [],
    topNewBiskutChanel: [],
    bottomNewBiskutChanel: [],
    topNewFastChanel: [],
    BottomNewFastChanel: [],
    topBiskutChanelDataView: [],
    BOTTOMBiskutChanelDataView: [],
    topFastChanelDataView: [],
    bottomFastChanelDataView: [],
    divisionDataView: [],
    divisionDataViewIsLoading: false,
    regionDataView: [],
    regionDataViewIsLoading: false,
    bottomThreeDsm: [],
    bottomThreeDsmIsLOading: false,
    topTenRmData: [],
    TopTenRmIsLoading: false,
    bottomTenRm: [],
    bottomTenRmIsLoading: false,
    topTwentTSMIsLoading: false,
    topTwentTSM: [],
    bottomTwentTSMIsLoading: false,
    bottomTwentTSM: [],
    topFiveDsmIsloading: false,
    topFiveDSmData: [],
    heighestAchiversTwentyIsloading: false,
    heighestAchiversTwenty: [],
    bottomTwentySalesOfficers: [],
    bottomTwentySalesOfficersIsloading: false,
    imsSummeryAllDataIsLoading: false,
    imsSummeryAllData: [],
    topTwentySalesOfficerIsLoading: false,
    topTwentySalesOfficer: [],
    bottomTwentySalesOfficerIsLoading: false,
    BottomTwentySalesOfficerData: [],
    categoryWiseDivisonAchIsLoading: false,
    categoryWiseDivisonAch: [],
    focusProductDataIsLoading: false,
    focusProductData: [],
    focussKUData: [],
    focussKUDataIsLoading: false,
    topSellingData: [],
    topSellingDataIsLoading: false,
    preViousMonthImsSummeryDAtaIsLoading: false,
    preViousMonthImsSummeryData: [],
    regionDataPreIsLoading: false,
    regionDataPre: [],
    preTopFiveDSm: [],
    preBottomThreeDsmData: [],
    preTopTenRmData: [],
    preBottomTenRmData: [],
    preTopTwentyTsm: [],
    prebOTTOMTwentyTsm: [],
    preAchoValueAch: [],
    preAchoValueAchBottom: [],
    currentMonhWorking:{},
    previousMonthWorking:{},
    dailyImsData:[],
    dailyImsDataisLoading:false

}




const MapReducerData = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {
        case Types.GET_OVER_ALL_DIVISION:
            const divisionData = action.payload

            return {
                ...state,
                toDivisionDataIsLoading: action.payload.isLoading,
                divisionData: divisionData,
            };

        case Types.GET_OVER_ALL_ZONE:
            const zoneData = action.payload;
            console.log('zoneData', zoneData)
            return {
                ...state,
                zoneData: zoneData,
                toZoneDataIsLoading: action.payload.isLoading

            }
        case Types.GET_OVER_ALL_REGION:
            const regionData = action.payload;

            return {
                ...state,
                regionData: regionData,
                toRegionIsLoading: action.payload.isLoading

            }
        case Types.GET_OVER_ALL_TERRITORY:
            const territoryDatas = action.payload;

            return {
                ...state,
                territoryData: territoryDatas,
                toTerritoryIsLoading: action.payload.isLoading,
            }

        case Types.GET_OVER_ALL_ZONE_DATA_FOR_MODAL:
            const zoneDataModal = action.payload;

            return {
                ...state,
                zoneDataForModal: zoneDataModal,
                zoneDataModalIsLoading: action.payload.isLoading,
            }


        case Types.GET_OVER_ALL_DIVISION_DATA_FOR_MODAL:
            const divisionDataModal = action.payload;

            return {
                ...state,
                divisionDataForModal: divisionDataModal,
                divisionDataModalIsLoading: action.payload.isLoading,
            }


        case Types.GET_OVER_ALL_REGION_FOR_MODAL:
            const regionDataModal = action.payload;

            return {
                ...state,
                regionDataForModal: regionDataModal,
                regionDatamodalIsLoading: action.payload.isLoading,
            }


        case Types.GET_OVER_ALL_TERRITORY_FOR_MODAL:
            const territoryModal = action.payload;

            return {
                ...state,
                territoryDataForModal: territoryModal,
                territoryDataIsLodingForModal: action.payload.isLoading,
            }

        case Types.GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL:
            const biscutChanelData = action.payload;
            console.log('aaa', action, state)
            return {
                ...state,
                topperformanceBisckutChanel: biscutChanelData,
                bisckutChanelIsLoading: action.payload.isLoading,
            }
        case Types.GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL_BUTTOM:
            const biscutChanelDataForButtom = action.payload;
            console.log('aaa', action, state)
            return {
                ...state,
                topFastChanel: biscutChanelDataForButtom,
                biscutChanelButtomIsLoading: action.payload.isLoading,
            }


        case Types.GET_OVER_ALL_PERFORMANCE_BUTTOM_FOR_TOP:
            const bisckutTopButtomData = action.payload;
            console.log('aaa', action, state)
            return {
                ...state,
                poorBiskutChanel: bisckutTopButtomData,
                bisckutChanelTopButtomDataisLoading: action.payload.isLoading,
            }

        case Types.GET_OVER_ALL_PERFORMANCE_BUTTOM_For_POOR_FAST:
            const poorButtomdata = action.payload;
            console.log('aaa', action, state)
            return {
                ...state,
                poorButtomdataList: poorButtomdata,
                poorButtomdataListisLoading: action.payload.isLoading,
            }




        case Types.GET_ZONE_DIVISION_LIST:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                zoneDivisionList: getOverAllDSM(action.payload.data),
            }

        case Types.GET_AREA_TERRITORY_LIST:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                areaTerritory: getOverallAreaTerritory(action.payload.data),
            }
        case Types.GET_LOCATION_WISE_TOP_PERFORMERbISCKUT_CHANEL:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                topNewBiskutChanel: action.payload.data,
                topNewBisckutIsLoading: action.payload.isLoading,

            }
        case Types.GET_LOCATION_WISE_BOTTOM_PERFORMERbISCKUT_CHANEL:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                bottomNewBiskutChanel: action.payload.data,
                bottomNewBisckutIsLoading: action.payload.isLoading,

            }


        case Types.GET_LOCATION_WISE_TOP_PERFORMER_FAST_CHANEL:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                topNewFastChanel: action.payload.data,
                topNewFastChanelIsLoading: action.payload.isLoading,

            }

        case Types.GET_LOCATION_WISE_BOTTOM_PERFORMER_FAST_CHANEL:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                BottomNewFastChanel: action.payload.data,
                BottomNewFastChanelIsLoading: action.payload.isLoading,

            }


        case Types.GET_LOCATION_WISE_BISKUT_CHANEL_DATA_VIEW:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                topBiskutChanelDataView: action.payload.data,
                topBiskutChanelDataViewIsLoading: action.payload.isLoading

            }


        case Types.GET_LOCATION_WISE_BOTTOM_BISKUT_CHANEL_DATA_VIEW:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                BOTTOMBiskutChanelDataView: action.payload.data,
                BOTTOMBiskutChanelDataViewIsLoading: action.payload.isLoading

            }

        case Types.GET_LOCATION_WISE_TOP_FAST_CHANEL_DATA_VIEW:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                topFastChanelDataView: action.payload.data,

            }

        case Types.GET_LOCATION_WISE_BOTTOM_FAST_CHANEL_DATA_VIEW:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                bottomFastChanelDataView: action.payload.data,

            }

        case Types.GEt_DIVISION_WISE_IMS_SUMMERY_VIEW:
            console.log('action.payload.data --7776', action.payload.data)
            return {
                ...state,
                divisionDataView: action.payload.data,
                divisionDataViewIsLoading: action.payload.isLoading

            }

        case Types.GEt_REGION_WISE_IMS_SUMMERY_VIEW:
            return {
                ...state,
                regionDataView: action.payload.data,
                regionDataViewIsLoading: action.payload.isLoading

            }

        case Types.BOTTOM_THREE_DSM_VIEW:
            return {
                ...state,
                bottomThreeDsm: action.payload.data,
                bottomThreeDsmIsLOading: action.payload.isLoading,

            }

        case Types.TOP_TEN_RM_VIEW:
            return {
                ...state,
                topTenRmData: action.payload.data,
                TopTenRmIsLoading: action.payload.isLoading,

            }

        case Types.BOTTOM_TEN_RM_VIEW:
            return {
                ...state,
                bottomTenRm: action.payload.data,
                bottomTenRmIsLoading: action.payload.isLoading,

            }

        case Types.TOP_TWENTY_TSM_VIEW:
            return {
                ...state,
                topTwentTSM: action.payload.data,
                topTwentTSMIsLoading: action.payload.isLoading,

            }

        case Types.BOTTOM_TWENTY_TSM_VIEW:
            return {
                ...state,
                bottomTwentTSM: action.payload.data,
                bottomTwentTSMIsLoading: action.payload.isLoading,

            }

        case Types.TOP_FIVE_DSM_VIEW:
            return {
                ...state,
                topFiveDSmData: action.payload.data,
                topFiveDsmIsloading: action.payload.isLoading,

            }

        case Types.HEIGEST_ACHIEVERS_TWENTY_SALES_OFFICERS:
            return {
                ...state,
                heighestAchiversTwenty: action.payload.data,
                heighestAchiversTwentyIsloading: action.payload.isLoading,

            }

        case Types.BOTTOM_TWENTY_SALES_OFFICERS:
            return {
                ...state,
                bottomTwentySalesOfficers: action.payload.data,
                bottomTwentySalesOfficersIsloading: action.payload.isLoading,

            }

        case Types.IMS_SUMMERY_ALL_VIEW:
            console.log('--->', action.payload.data)
            return {
                ...state,
                imsSummeryAllData: action.payload.data,
                imsSummeryAllDataIsLoading: action.payload.isLoading,

            }

        case Types.TOP_TWENTY_SALES_OFFICERS:
            console.log('--->', action.payload.data)
            return {
                ...state,
                topTwentySalesOfficer: action.payload.data,
                topTwentySalesOfficerIsLoading: action.payload.isLoading,

            }

        case Types.BOTTOM_TWENTY_SALES_ACH_OFFICERS:
            console.log('--->', action.payload.data)
            return {
                ...state,
                BottomTwentySalesOfficerData: action.payload.data,
                bottomTwentySalesOfficerIsLoading: action.payload.isLoading,

            }

        case Types.CATEGORY_WISE_DIVISON_ACHIVEMENT:
            return {
                ...state,
                categoryWiseDivisonAch: action.payload.data,
                categoryWiseDivisonAchIsLoading: action.payload.isLoading,

            }

        case Types.FOCUS_PRODUCT_TARGET_VS_ACHIEMENT:
            return {
                ...state,
                focusProductData: action.payload.data,
                focusProductDataIsLoading: action.payload.isLoading,

            }

        case Types.FOCUS_SKU_gTARGET_VS_ACHIEMENT:
            return {
                ...state,
                focussKUData: action.payload.data,
                focussKUDataIsLoading: action.payload.isLoading,

            }

        case Types.TOP_SELLING_SKU:
            return {
                ...state,
                topSellingData: action.payload.data,
                topSellingDataIsLoading: action.payload.isLoading,

            }


        case Types.GET_PREVIOUS_MONTH_DIVISION_WISE_IMS_SUMMERY:
            return {
                ...state,
                preViousMonthImsSummeryDAta: action.payload.data,
                preViousMonthImsSummeryDAtaIsLoading: action.payload.isLoading,

            }
        case Types.GET_PREVIOUS_MONTH_REGION_WISE_IMS_SUMMERY:
            return {
                ...state,
                regionDataPre: action.payload.data,
                regionDataPreIsLoading: action.payload.isLoading,

            }

        case Types.GET_PREVIOUS_MONTH_TOP_FIVE_DSM:
            return {
                ...state,
                preTopFiveDSm: action.payload.data,

            }
        case Types.GET_PREVIOUS_MONTH_BOTTOM_THREE_DSM:
            return {
                ...state,
                preBottomThreeDsmData: action.payload.data,

            }
        case Types.GET_PREVIOUS_MONTH_TOP_TEN_RM_DATA:
            return {
                ...state,
                preTopTenRmData: action.payload.data,

            }
        case Types.GET_PREVIOUS_MONTH_BOTTOM_TEN_RM_DATA:
            return {
                ...state,
                preBottomTenRmData: action.payload.data,

            }
        case Types.GET_PREVIOUS_MONTH_TOP_TWENTY_TSM_DATA:
            return {
                ...state,
                preTopTwentyTsm: action.payload.data,

            }
        case Types.GET_PREVIOUS_MONTH_BOTTOM_TWENTY_TSM_DATA:
            return {
                ...state,
                prebOTTOMTwentyTsm: action.payload.data,

            }
        case Types.GET_PREVIOUS_MONTH_TOP_TWENTY_SO_DATA:
            return {
                ...state,
                PrtopTwentySo: action.payload.data,

            }
        case Types.GET_PREVIOUS_MONTH_SO_VALUE_ACH_DATA:
            return {
                ...state,
                preAchoValueAch: action.payload.data,

            }
        case Types.GET_PREVIOUS_MONTH_BOTTOM_SO_VALUE_ACH_DATA:
            return {
                ...state,
                preAchoValueAchBottom: action.payload.data,

            }
        case Types.CURRENT_MONTH_WORKING_DAYS:
            return {
                ...state,
                currentMonhWorking: action.payload.data,

            }
        case Types.PREVIOUS_MONTH_WORKING_DAYS:
            return {
                ...state,
                previousMonthWorking: action.payload.data,

            }
         case Types.DAILY_IMS_LIFTING_SUMMERY_DETAILS:
                return {
                    ...state,
                    dailyImsData: action.payload.data,
                    dailyImsDataisLoading:action.payload.isLoading
    
                }
        default:
            return newState;


    }
}


export default MapReducerData;




const getOverAllDSM = (data) => {
    console.log('dataaaaaa combinedLi ', data.combinedList
    )
    let options = [];
    if (data) {
        data.combinedList
            .forEach((item) => {
                let itemData = {
                    value: item.id,
                    label: item.name,
                };
                options.push(itemData);
            });
    }
    return options;
};

const getOverallAreaTerritory = (data) => {
    console.log('dataaaaaa combinedLi ', data.combinedList
    )
    let options = [];
    if (data) {
        data.combinedList
            .forEach((item) => {
                let itemData = {
                    value: item.id,
                    label: item.name,
                };
                options.push(itemData);
            });
    }
    return options;
};