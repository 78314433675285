import * as Types from "./DashboardType";
const initialState = {
    fullName: [],
    actualSalesQtyData: [],
    actualSalesValData: [],
    cmData: [],
    cmAnalysisLoading: false,
    fullNameYear: [],
    CMtrandData: [],
    CMTrandDataLoading: false,
    actualSalesValDataTotal: [],
    actualSalesQtyDataYear: [],
    actualSalesValDataYear: [],
    cmDataYear: [],
    TradeOfferPriceData: [],
    cmAnalysisYearLoading: false,
    lwStandCMData: [],
    lwStandCMDataLoading: false,
    sumSalesLineWise: 0,
    ActualCMSumSalesLineWise: 0,
    standardSalesLineWise: 0,
    standardSumValue: 0,
    ActualSumValue: 0,
    ReduCount: 0,
    GreeenCount: 0,
    YellowCount: 0,
    totalValue: {},
    total: {
        actualSalesQty: 0,
        actualSalesVal: 0,
        cm: 0
    },
    totalYearvalue: {
        actualSalesQty: 0,
        actualSalesVal: 0,
        cm: 0
    },
    groupData: [],
    labelsData: [],
    detailsArrayYellow: [],
    detailsArrayRed: [],
    detailsArrayGreen: [],
    recipeData:[],
    recipeDataLoading: false,
    sumSalesCal:0,

};

const CMAnalysisReducer = (state = initialState, action) => {
    switch (action.type) {

        case Types.GET_CM_ANALYSIS_MONTOMON_DATA:
            const actualSalesQTY = action.payload.data;

            const actualSalesVAL = action.payload.data;
            const cmData = action.payload.data;


            const sumSalesData = 0;

            const actualSalesDataQty = [];
            for (let i = 0; i < actualSalesQTY?.length; i++) {
                const label = actualSalesQTY[i].lineName > 10 ? `${actualSalesQTY[i].lineName.substring(0, 15)}..` : actualSalesQTY[i].lineName;

                actualSalesDataQty.push({
                    label,
                    value: parseFloat(actualSalesQTY[i].actualSalesVal).toFixed(2)
                });
            }

            const actualSalesDataVal = [];
            for (let i = 0; i < actualSalesVAL?.length; i++) {
                const label = actualSalesVAL[i].lineName.length > 10 ? `${actualSalesVAL[i].lineName.substring(0, 15)}..` : actualSalesVAL[i].lineName;
                actualSalesDataVal.push({
                    label,
                    value: parseFloat(actualSalesVAL[i].cmValue).toFixed(3)
                });
            }

            const cm = [];
            for (let i = 0; i < cmData?.length; i++) {
                const label = cmData[i].lineName.length > 10 ? `${cmData[i].lineName.substring(0, 15)}..` : cmData[i].lineName;
                cm.push({
                    label,
                    value: parseInt(cmData[i].cm)
                });
            }

            const fulllabels = [];
            for (let i = 0; i < actualSalesQTY?.length; i++) {
                const label = actualSalesQTY[i].lineName
                fulllabels.push({
                    label
                });
            }

            const { data, isLoading } = action.payload;
            const groupingData = Array.isArray(data) ? data.map(item => ({
                ...item,
                actualSalesQty: parseFloat(item.actualSalesQty).toFixed(2), // Convert actualSalesQty to float
                actualSalesVal: parseFloat(item.actualSalesVal).toFixed(2), // Convert actualSalesVal to float
                cmValue: parseFloat(item.cmValue).toFixed(2), // Convert cmValue to float
            })) : [];

            return {
                ...state,
                actualSalesQtyData: actualSalesDataQty,
                actualSalesValData: actualSalesDataVal,
                cmData: cm,
                cmAnalysisLoading: action.payload.isLoading,
                fullName: fulllabels,
                total: action.payload.data?.totalData,
                groupData: groupingData,

            };

        case Types.GET_CM_ANALYSIS_YEAR_DATA:
            const actualSalesYearQTY = action.payload.data;

            const actualSalesDataYearQty = [];
            const actualSalesDataYearQtyTtl = [];
            const actualSalesDataYearVal = [];
            const fulllabelsYear = [];
            const YearCm = [];

            for (let i = 0; i < actualSalesYearQTY?.length; i++) {
                const label = actualSalesYearQTY[i].lineName > 10 ? `${actualSalesYearQTY[i].lineName.substring(0, 15)}..` : actualSalesYearQTY[i].lineName;
                actualSalesDataYearQty.push({
                    label,
                    value: parseFloat(actualSalesYearQTY[i].actualSalesVal).toFixed(2)
                });
                actualSalesDataYearVal.push({
                    label,
                    value: parseFloat(actualSalesYearQTY[i].cmValue).toFixed(3)
                });
                YearCm.push({
                    label,
                    value: parseInt(actualSalesYearQTY[i].cm)
                });
                fulllabelsYear.push({
                    label,
                    value: parseInt(actualSalesYearQTY[i].lineName)
                });
            }

            return {
                ...state,
                actualSalesQtyDataYear: actualSalesDataYearQty,
                actualSalesValDataYear: actualSalesDataYearVal,
                actualSalesValDataTotal: actualSalesDataYearQtyTtl,
                cmDataYear: YearCm,
                cmAnalysisYearLoading: action.payload.isLoading,
                fullNameYear: fulllabelsYear,
                totalYearvalue: action.payload.data?.totalData,
            };

        case Types.GET_LINE_WISE_STANDARD_CM:
            console.log('actualSalesQTY', action.payload.data?.allLineSum)
            console.log('actualSalesQTY', action.payload.data)
            
            //parseFloat(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.itemWiseMixing)) / 100).toFixed(2)
            let sumSales = 0;
            let standardSum = 0;
            let ActualCMSum = 0;
            let standardSumValue = 0;
            let ActualSumValue = 0;
            let standaDardValue = action.payload.data?.report;
            let ReduCount = 0;
            let GreeenCount = 0;
            let YellowCount = 0;
            let detailsArrayYellow = [];
            let detailsArrayRed = [];
            let detailsArrayGreen = [];
            let sumSalesCal=0;

            let allLineSum = action.payload.data?.allLineSum;
            let LineWiseSum = action.payload.data?.totalValue;
            console.log('LineWiseSum', LineWiseSum);
            console.log('allLineSum', allLineSum);

           // detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal
            for (let i = 0; i < standaDardValue?.length; i++) {
                const element = standaDardValue[i].details;
                var totalSales = standaDardValue[i].lineWiseDeliveryVal;
                var lineSum = standaDardValue[i].lineWiseActualCM;
                var standardCMPer = standaDardValue[i].lineWIsestandardMixingSum;
                sumSales += (LineWiseSum/allLineSum) * 100;
                // ActualSumValue+=(totalSales*lineSum)/100;
                // standardSumValue+=(totalSales*standardCMPer)/100;
                for (let j = 0; j < element.length; j++) {
                    var totalSales = standaDardValue[i].lineWiseDeliveryVal;

                    console.log('element[j]', element[j].itemWiseDeliveryVal/standaDardValue[i].lineWiseDeliveryVal);
                    var lineSum = standaDardValue[i].lineWiseActualCM;

                    sumSalesCal+=(element[j].itemWiseDeliveryVal/standaDardValue[i].lineWiseDeliveryVal)*100
                    //sumSales += (standaDardValue[i].lineWiseDeliveryVal/allLineSum) * 100;
                    standardSum += element[j].itemWiseMixing;
                    ActualCMSum += element[j].actualCM;
                    standardSumValue += ((standaDardValue[i].lineWiseDeliveryVal * ((element[j].itemWiseDeliveryVal / standaDardValue[i].lineWiseDeliveryVal) * 100)) / 100) * ((element[j].itemWiseMixing)) / 100
                    ActualSumValue += ((standaDardValue[i].lineWiseDeliveryVal * ((element[j].itemWiseDeliveryVal / standaDardValue[i].lineWiseDeliveryVal) * 100)) / 100) * ((element[j].actualCM)) / 100
                    //ActualSumValue += ((standaDardValue[i].lineWiseDeliveryVal * ((element[j].itemWiseDeliveryVal / standaDardValue[i].lineWiseDeliveryVal) * 100)) / 100) * ((element[j].actualCM)) / 100


                }
                console.log('sumSalesCal', sumSalesCal);

            }

            // How many cm is yellow,red calculation
            for (let i = 0; i < standaDardValue?.length; i++) {
                const elementD = standaDardValue[i].details;
                for (let j = 0; j < elementD.length; j++) {
                    const currentItem = elementD[j];
                    if (currentItem.actualCM <= 5) {
                        if (currentItem instanceof Object) {
                            ReduCount++;
                            detailsArrayRed.push(currentItem);
                        }
                    } else if (currentItem.actualCM > 10) {
                        GreeenCount++;
                        detailsArrayGreen.push(currentItem);
                    } else if (currentItem.actualCM <= 10) {
                        YellowCount++;
                        detailsArrayYellow.push(currentItem);
                    }
                }
            }

            return {
                ...state,
                lwStandCMData: action.payload.data?.report,
                totalValue: action.payload.data,
                lwStandCMDataLoading: action.payload.isLoading,
                sumSalesLineWise: sumSales,
                standardSalesLineWise: standardSum,
                ActualCMSumSalesLineWise: ActualCMSum,
                standardSumValue: standardSumValue,
                ActualSumValue: ActualSumValue,
                YellowCount,
                ReduCount,
                GreeenCount,
                detailsArrayYellow: detailsArrayYellow,
                detailsArrayRed: detailsArrayRed,
                detailsArrayGreen: detailsArrayGreen,
                sumSalesCal:sumSalesCal


            };
        case Types.GET_CM_TRAND_SINCE_BEGINING:

            return {
                ...state,
                CMtrandData: action.payload?.data,
                CMTrandDataLoading: action.payload.isLoading,
            };
        case Types.GET_RECIPE_LIST:

            return {
                ...state,
                recipeData: action.payload?.data,
                recipeDataLoading: action.payload.isLoading,
            };
        default:
            return {
                ...state
            };
    }
};
export default CMAnalysisReducer;
