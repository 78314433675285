import React from "react";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns, data } from "../TestTable/data";
import "../TestTable/test.css";
import * as jsa from "../TestTable/response_sale_order.json";

const TableIndex = ({ data }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>Sku Name</th>
          <th>Qty</th>
        </tr>
      </thead>
      <tbody>
        {data.order_line.map((item, index) => (
          <tr key={index}>
            <td>{item.name}</td>
            <td>{item.product_uom_qty}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

function TestTable() {
  const tableData = {
    columns,
    data
  };

  return (
    <div className="main">
      <DataTableExtensions filterHidden={false} exportHeaders {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          noHeader
          defaultSortField="id"
          defaultSortAsc={false}
          pagination
          highlightOnHover
        />
      </DataTableExtensions>
    </div>
  );
}


export default TableIndex;


