import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const PerformerDetails = () => {
    const { state } = useLocation();
    
    const history = useHistory();

    const id = state && state.id;
    console.log('id', id);
    const handleGoBack = () => {
        history.goBack();
    };
    return (
        <div>

            <button onClick={handleGoBack}>Back</button>

            This page is for performer details with ID: {id}
        </div>
    );
};

export default PerformerDetails;
