import axios from "axios";
import * as Types from "../aside-menu/authTypes";

export const GetMenuListsByPermission = () => async (dispatch) => {
  let data = {
    menuList: [],
    isMenuLoading: true,
  }
  // dispatch({ type: Types.GET_MENU_LIST, payload: data });

  axios.get(`${process.env.REACT_APP_API_PUBLIC_URL}api/OAuth/ApplicationURLWEB`)
  .then(res => {
    data = {
      menuList: res.data.data,
      isMenuLoading: false,
    }
    dispatch({ type: Types.GET_MENU_LIST, payload: data });
  });
};

/** Dummy Axios call for Example */
export const DummyAxiosCall = () => async (dispatch) => {
  await axios.get(`https://testapi.com/?AccountId=1`).then((res) => {
    // dispatch({ type: Types.GET_EMPLOYEE_INFO, payload: res.data });
  });
  // dispatch({ type: Types.GET_BLOOD_GROUP_LIST, payload: "data" });
};
