import * as Types from "../aside-menu/authTypes";

const initialState = {
  menuList: [],
  permissionMenu:[],
  isMenuLoading: false,
};

const authMenuPermissionReducer = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case Types.GET_MENU_LIST:
      const menuArr=[];
      for (let i = 0; i < action.payload?.menuList.length; i++) {
        const menuObj = {
          path: action.payload?.menuList[i].path,
          name: action.payload?.menuList[i].name,
          component: action.payload?.menuList[i].component,
          exact: true,
        }
        menuArr.push(menuObj);
      }
      return {
        ...state,
        menuList: action.payload?.menuList,
        permissionMenu:menuArr,
        isMenuLoading: action.payload.isMenuLoading,
      };

    default:
      break;
  }
  return newState;
};
export default authMenuPermissionReducer;
