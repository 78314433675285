import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { useSelector } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect } from "react";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export default function SalesMixingMonitoring() {
  const actSalesData = useSelector(
    (state) => state.productionLine.actSalesData
  );
  const salesMixingMonitoringDataLabels = useSelector(
    (state) => state.productionLine.salesMixingMonitoringDataLabels
  );
  const isLoading = useSelector(
    (state) => state.productionLine.toSalesMonitoringLoading
  );

  const salesMixingStockData = useSelector(
    (state) => state.productionLine.salesMixingStockData
  );
  const salesMixingMonitoringData = useSelector(
    (state) => state.productionLine.salesMixingMonitoringData
  );
  const fullName = useSelector(
    (state) => state.productionLine.fullName
  );

  let nf = new Intl.NumberFormat("en-BD");
  const chartRef = useRef(null);
  const zoomOptions = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      mode: "x",
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: true,
        labels: {
          usePointStyle: true,
        },
        datalabels: {
          display: true,
          color: "white",
        },
      },
      zoom: zoomOptions,
      tooltip: {
        callbacks: {
          title: (context) => {
            const filterToolTip =
              salesMixingMonitoringDataLabels[context[0].dataIndex];
            return filterToolTip;
          },
        },
      },
    },
  };

  const labels = salesMixingMonitoringDataLabels;

  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        label: "SALES MIX %",
        backgroundColor: "#ffc000",
        data: salesMixingMonitoringData,
        datalabels: {
          display: true,
          color: "white",
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: function(value, context) {
            return nf.format(value) + "%";
          },          
        },
        options: {
          plugins: {
            tooltip: {
              callbacks: {
                title: (context) => {
                  console.log(context);
                },
              },
            },
          },
        },
      },
      {
        type: "line",
        label: "ACT SALES %",
        color: "white",
        backgroundColor: "#203864",
        borderColor: "#203864",
        borderWidth: 30, // Set the line width to a higher value for bold effect
        // pointStyle: "rect",
        pointRadius: 20,
        pointHoverRadius: 20,
        fill: false,
        data: actSalesData,
        datalabels: {
          display: true,
          color: "white",
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: function(value, context) {
            return nf.format(value) + "%";
          },
          backgroundColor: "#203864",
          // backgroundColor: function(dataColor) {
          //     return dataColor.dataset.data[dataColor.dataIndex] < 0 ? '#203864' : 'rgba(106, 90, 205, 0.7)';
          //   },
        },
      }
      
    ],
  };

  const onZoomPluse = () => {
    chartRef.current.zoom(11);
  };
  useEffect(() => {
    onZoomPluse();
  }, []);
  return (
    <div className="App card">
      <div className="text-center bg-danger pdfBGC2 pt-2 text-white rounded-top">
        <h5 className="pdfFont2C">Sales Mixing Monitoring Old</h5>
      </div>
      {isLoading && (
        <span className="spineercolor mt-5 mb-5">
          <LoadingSpinner />
        </span>
      )}

      <>
        
          <Chart
            ref={chartRef}
            options={options}
            data={data}
            plugins={[ChartDataLabels]}
          />
      </>

    </div>
  );
}
