import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCumulativeReport } from "./ProductionLineAction";
import { useReactToPrint } from "react-to-print";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { numberFormat } from "./Money";
import Print from "../master/PrintDwopdown/Print";
import { getLineWiseSalesStandard } from "./DashboardCartAction";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
const StandardMixing = () => {
  const conponentPDF = useRef();
  const componentRef = useRef();
  const dispatch = useDispatch();
  //   useEffect(() => {
  //     initialData();
  //   }, []);
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
  });

  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const cumulativeReportData = useSelector(
    (state) => state.productionLine.cumulativeReportData
  );
  const totalInformation = useSelector(
    (state) => state.productionLine.totalInformation
  );
  const brandInformation = useSelector(
    (state) => state.productionLine.brandInformation
  );
  const standardWiseReport = useSelector(
    (state) => state.dashboardcart.standardWiseReport
  );
  const standardWiseReporttotalValue = useSelector(
    (state) => state.dashboardcart.standardWiseReporttotalValue
  );
  const standardWiseReporttotalqty = useSelector(
    (state) => state.dashboardcart.standardWiseReporttotalqty
  );
  const salesMixing = useSelector(
    (state) => state.dashboardcart.salesMixing
  );
  console.log("standardWiseReport", standardWiseReport);
  const isLoading = useSelector(
    (state) => state.productionLine.cumulativeReportdataLoading
  );
  const daysLeft =
    cumulativeReportData.totalDays - cumulativeReportData.currentDays;
  const workingPassed = parseInt(
    ((cumulativeReportData?.totalDays - daysLeft) /
      cumulativeReportData?.totalDays) *
    100
  );

  let d = new Date();
  let firstD =
    d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
  let currentD =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2) +
    "";
  //   const initialData = async () => {

  //   };
  const biscuitChannelGap = totalInformation
    ? Math.abs(
      Math.floor(
        totalInformation[0]?.primaryAchivement -
        totalInformation[0]?.secondaryAcv
      )
    )
    : 0;
  const breadBanGap = totalInformation
    ? Math.abs(
      Math.floor(
        totalInformation[1]?.primaryAchivement -
        totalInformation[1]?.secondaryAcv
      )
    )
    : 0;
  const totalGap = biscuitChannelGap + breadBanGap;

  const handleChangeStartDate = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);

  };
  const handleShowReport = () => {
    if (startDate && toDate) {
      dispatch(
        getLineWiseSalesStandard(
          startDate,
          toDate,
        )
      );
    } else {
      alert("Enter From and To Date.");
    }
  };

  return (
    <div className="card reportDataTable container-fluid">
      <div className="p-5 dashboardCartReport mt-4 ">
        <div className="form-group row p-3">
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date:</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="form-control cusheight"
                onChange={handleChangeStartDate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date:</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="form-control cusheight"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3">
            <button
              className="button-621 mt-5"
              onClick={() => handleShowReport()}
            >
              Show
            </button>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <div className="card position-relative">
          <div className="m-3 d-flex flex-row-reverse">
            {/* <button className="button-621" onClick={generatePDF}>
              Print
            </button> */}
            <button type="button" class="btn  ">
             <Print refvalue={componentRef} />
            </button>
          </div>
          {salesMixing && (
            <span className="spineercolor mt-5 mb-5">
              <LoadingSpinner />
            </span>
          )}
          <>
            {!salesMixing && (
              <div ref={componentRef} style={{ width: "100%" }}>
                <div className="text-center pt-2  rounded-top">
                  <button className="button-010">Standard Mixing Report</button>
                  {/* <h4 className=""></h4> */}
                </div>
                {/* <div className="d-flex justify-content-between">
                  <div className="">
                    <h6 className="p-1 m-2 d-inline-block rounded p-2 bg-light">
                      Report As on: {currentD}
                    </h6>
                  </div>
                  <div className="">
                    <h6 className=" p-1 m-2 d-inline-block rounded p-2 bg-light">
                      Day Passed: {daysLeft ? daysLeft : 0} Days
                    </h6>
                  </div>
                </div> */}
                <div className="p-3">
                  <div className="data-table">
                    <table className="table table-head-custom table-vertical-center item-add-table borderTableims mb-5">
                      <thead>
                        <tr>
                          <th>Line Name</th>
                          <th>Product ID</th>
                          <th>Product Name</th>
                          <th>Sales Quantity</th>
                          <th>Sales Value</th>
                          <th>Standred Sales Mixing</th>
                          <th>Act. Sales Mixing</th>
                        </tr>
                      </thead>
                      <tbody>
                        {standardWiseReport.map((line) => (
                          <React.Fragment key={line.lineID}>
                            <tr className="bg-light">
                              <td>{line.lineName}</td>
                              <td></td>
                              <td></td>
                              <td>{numberFormat(Math.floor(line.achivementQty))}</td>
                              <td>{numberFormat(Math.floor(line.achivmentPrice))}</td>
                              <td>{(line.standardMixingSum).toFixed(2)} %</td>
                              <td>100%</td>
                            </tr>
                            {line.details.map((detail) => (
                              <tr key={detail.productID}>
                                <td></td>
                                <td>{detail.productID}</td>
                                <td>{detail.productName}</td>
                                <td>
                                  {numberFormat(Math.floor(detail.achivement))}
                                </td>
                                <td>
                                  {numberFormat(Math.floor(detail.achivementValue))}
                                </td>
                                <td>
                                  {numberFormat(Math.floor(detail.mixing))}%
                                </td>
                                <td>
                                  {((detail.achivement / line.achivementQty) * 100).toFixed(2)}%
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        ))}
                        <tr className="bg-light">
                          <td>Total</td>
                          <td></td>
                          <td></td>
                          <td>{numberFormat(standardWiseReporttotalqty)}</td>
                          <td>{numberFormat(Math.floor(standardWiseReporttotalValue))}</td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default StandardMixing;
