import React, { useState } from "react";
import ProductionLineTable from "./ProductionLineTable";
import SalesMixingMonitoringTable from "./SalesMixingMonitoringTable";
import { Accordion, Button, Card } from "react-bootstrap";
import ProductionLineInput from "./ProductionLineInput";
import { FaAngleDoubleDown } from "react-icons/fa";
import ProductionLineUpdated from "./ProductionLineUpdated";
import PracticeSalesMixingMonitoring from "./PracticeSalesMixingMonitoring";
import SalesMixingMonitoring from "./SalesMixingMonitoring";
import SalesMixingMonitoring2 from "./SalesMixingMonitoring2";

const ProductionLineContainer = () => {

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 paddingremove sticky-top col-xl-12 zindexmodified  ">
          <div className="top_card3">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="1"
                className="arrayButton"
                onClick={handleAccordionToggle}
              >
                <div>
                  <div className="dailysales text-center dailysalesmargin dailysalesfont">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    &nbsp; &nbsp; &nbsp; &nbsp; Daily Sales Monitoring&nbsp;
                    <FaAngleDoubleDown className="dropdownArrow" />{" "}
                    <span className="clickHere float-right">
                      {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                    </span>
                  </div>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="1">
                <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
                  <ProductionLineInput />
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>
          <div className="top_card4">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle
                as={Button}
                variant="link"
                eventKey="1"
                className="arrayButton"
              >
                <div>
                  <div className="dailysales text-center dailysalesmargin dailysalesfont">
                  Daily Sales Monitoring
                  </div>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="1">
                <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
                  <ProductionLineInput />
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>
        </div>
        {/* <div className="col-12 col-md-12 col-lg-6 paddingremove">
          <ProductionLine />
        </div> */}
        <div className="col-12 col-md-12 col-lg-6 paddingremove">
          <ProductionLineUpdated />
        </div>
        <div className="col-12 col-md-12 col-lg-6 paddingremove">
          <ProductionLineTable />
        </div>
        <div className="col-12 col-md-12 col-lg-6 paddingremove">
          <PracticeSalesMixingMonitoring />
        </div>
        {/* <div className="col-12 col-md-12 col-lg-6 paddingremove">
          <SalesMixingMonitoring />
        </div> */}
        {/* <div className="col-12 col-md-12 col-lg-6 paddingremove">
          <SalesMixingMonitoring2/>
        </div> */}
        <div className="col-12 col-md-12 col-lg-6 paddingremove">
          <SalesMixingMonitoringTable />
        </div>
      </div>
    </div>
  );
};
export default ProductionLineContainer;
