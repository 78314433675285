import * as Types from "./DashboardType";
const initialState = {
  toProductionTableLoading: false,
  toSalesMonitoringTableLoading: false,
  toSalesMonitoringLoading: false,
  productionStockLoading: false,
  cumulativeReportdataLoading: false,
  productLineData: [],
  productLineTableData: [],
  salesMixingMonitoringData: [],
  salesMixingMonitoringData2: [],
  salesMixingMonitoringTableData: [],
  salesMixingMonitoringDataLabels: [],
  salesMixingMonitoringDataLabels2: [],
  fullName: [],
  productionStockMonitoringData: [],
  actualProduction: [],
  mtdAcctualProd: [],
  cumulativeReportData: [],
  totalInformation: [],
  brandInformation: [],
  totalCapacityWeight:0,
  totalCapacityValue:0,
  MtdAcctualProdVal:0,

  totalDay: 0,
  reportData: {
    ZoneID: " ",
    DivisionID: " ",
    AreaID: " ",
    Territory: " ",
    targetyear: " ",
    targetMonth: " ",
    Fdate: " ",
    Tdate: " ",
    measurement: 2,
    categoryName: "",
    lineName: "",
  },
};

const ProductionLineReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.CHANGE_PRODUCT_INPUT_REPORT:
      const reportData = { ...state.reportData };
      reportData[action.payload.name] = action.payload.value;
      return {
        ...state,
        reportData,
      };
    case Types.GET_PRODUCTION_LINE_DATA:
      return {
        ...state,
        productLineData: action.payload.data,
      };
    case Types.GET_PRODUCTION_LINE_TABLE_DATA:
      return {
        ...state,
        productLineTableData: action.payload.data,
        toProductionTableLoading: action.payload.isLoading,
      };
    // case Types.GET_SALES_MIXING_MONITORING_DATA:
    //   const salesMixingActPrd = action.payload.data?.salesMixing;
    //   const salesMixingStock = action.payload.data?.actualSales;

    //   const productName = [];
    //   const productName2 = [];
    //   const salesMixingData = [];
    //   for (let i = 0; i < salesMixingActPrd?.length; i++) {
    //     salesMixingData.push(
    //       state.reportData.measurement == 1
    //         ? salesMixingActPrd[i].price.toFixed(2)
    //         : salesMixingActPrd[i].qty.toFixed(2)
    //     );
    //     let firstHyphenIndex = salesMixingActPrd[i].productName?.indexOf("-");
    //     let secondHyphenIndex = salesMixingActPrd[i].productName?.indexOf("-", firstHyphenIndex + 1);
    //     let gmIndex = salesMixingActPrd[i].productName?.indexOf("gm");
    //     let slicedString = salesMixingActPrd[i].productName?.slice(secondHyphenIndex + 1, gmIndex + 2);
    //     productName.push(salesMixingActPrd[i].productName);
    //     // productName2.push(salesMixingActPrd[i].productName);
    //     productName2.push(slicedString);

    //   }
    //   const stockData = [];
    //   for (let i = 0; i < salesMixingStock?.length; i++) {
    //     stockData.push(
    //       state.reportData.measurement == 1
    //         ? salesMixingStock[i].price.toFixed(2)
    //         : salesMixingStock[i].qty.toFixed(2)
    //     );
    //   }
    //   return {
    //     ...state,
    //     salesMixingMonitoringData: salesMixingData,
    //     actSalesData: stockData,
    //     salesMixingMonitoringDataLabels: productName,
    //     salesMixingMonitoringDataLabels2: productName2,
    //     toSalesMonitoringLoading: action.payload.isLoading,
    //   };

    //3rd graph sales mixing
    case Types.GET_SALES_MIXING_MONITORING_DATA:
  const actualSales = action.payload.data?.actualSales;
  const salesMixing = action.payload.data?.salesMixing;

  const actualSalesData = [];
  for (let i = 0; i < actualSales?.length; i++) {
    const label = actualSales[i].productName.length > 10 ? `${actualSales[i].productName.substring(0, 15)}..` : actualSales[i].productName;
    actualSalesData.push({
      label,
      value:  parseFloat(actualSales[i].qty).toFixed(2)
    });
  }

  const salesMixingData = [];
  for (let i = 0; i < salesMixing?.length; i++) {
    const label = salesMixing[i].productName.length > 10 ? `${salesMixing[i].productName.substring(0, 15)}..` : salesMixing[i].productName;
    salesMixingData.push({
      label,
      value: parseFloat(salesMixing[i].qty).toFixed(2)
    });
  }

  const fulllabels = [];
  for (let i = 0; i < actualSales?.length; i++) {
    const label = actualSales[i].productName
    fulllabels.push({
      label
    });
  }

  console.log('salesMixingData :>> ', salesMixingData);

  return {
    ...state,
    salesMixingMonitoringData: actualSalesData,
    salesMixingMonitoringData2: salesMixingData,
    toSalesMonitoringLoading: action.payload.isLoading,
    fullName:fulllabels,
  };



    case Types.GET_SALES_MIXING_MONITORING_TABLE_DATA:
      const salesMixingDataT = action.payload.data;
      const arr = [salesMixingDataT];
      let sorted = arr.sort(
        (a, b) => parseInt(b.mtdAccualSales) - parseInt(a.mtdAccualSales)
      );

      return {
        ...state,
        salesMixingMonitoringTableData: salesMixingDataT,
        toSalesMonitoringTableLoading: action.payload.isLoading,
      };
    case Types.GET_PRODUCTION_AND_STOCK_MONITORING_GRAPH:
      const actualProductionData = action.payload.data.pakingInformation;
      const actualProduction = [];
      for (let i = 0; i < actualProductionData?.length; i++) {
        actualProduction.push(
          state.reportData.measurement == 1
            ? actualProductionData[i].productQty
            : actualProductionData[i].productQty
        );
      }
      console.log('totalCapacityQty',action.payload);
      return {
        ...state,
        actualProduction: actualProduction,
        productionStockLoading: action.payload.isLoading,
        mtdAcctualProd: action.payload.data.mtdAcctualProd,
        totalDay: action.payload.data.totalDay,
        totalCapacityQty: action.payload.data.totalCapacityQty,
        totalCapacityValue: action.payload.data.totalCapacityValue,
        totalCapacityWeight: action.payload.data.totalCapacityWeight,
        totalCapacityValue: action.payload.data.totalCapacityValue,
        MtdAcctualProdVal: action.payload.data.mtdAcctualProdVal,
      };
    case Types.GET_CUMULATIVE_REPORT:
      const cumulativeReportData = action.payload.data;
      const totalInformation = cumulativeReportData.totalInformation;
      const brandInformation = cumulativeReportData.brandInformation;
      return {
        ...state,
        totalInformation: totalInformation,
        brandInformation: brandInformation,
        cumulativeReportData: action.payload.data,
        cumulativeReportdataLoading: action.payload.isLoading
      };
    default:
      return {
        ...state
      };
  }
};
export default ProductionLineReducer;
