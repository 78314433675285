import Axios from "axios";
import * as Types from "./DashboardType";

export const getUpdatedProductionLineTableData = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine ) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    dispatch({
      type: Types.GET_PRODUCTION_LINE_DATA_UPDATED,
      payload: responseList,
    });
    let url = "";
    url = `${process.env.REACT_APP_API_PUBLIC_URL}ProductionandStockMonitoringGraph?`;
  
    if (ZoneID !== null) {
      url += `&ZoneID=${ZoneID}`;
    }
    if (DivisionID !== null) {
      url += `&DivisionID=${DivisionID}`;
    }
    if (AreaID !== null) {
      url += `&AreaID=${AreaID}`;
    }
    if (Territory !== null) {
      url += `&Territory=${Territory}`;
    }
    if (Fdate !== null) {
      url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
      url += `&Tdate=${Tdate}`;
    }
    if (category !== null) {
      url += `&category=${category}`;
    }
    if (ProductId !== null) {
      url += `&ProductId=${ProductId}`;
    }
    if (ProductGroup !== null) {
      url += `&salesGroupId=${ProductGroup}`;
    }
    if (productionLine !== null) {
      url += `&productionLine=${productionLine}`;
    }
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data == null ? [] : res.data.data;
      responseList.isLoading = false;
    });
    dispatch({
      type: Types.GET_PRODUCTION_LINE_DATA_UPDATED,
      payload: responseList,
    });
  };

  export const GetTSRMTrackingSystem = (menu_name,action_name,macAddress,action_id) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: null,
      status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TSRMTrackingSystem?menu_name=${menu_name}&action_name=${action_name}&macAddress=${macAddress}&action_id=${action_id}`;
    await Axios.post(url).then((res) => {
      responseList.data = res.data.data == null ? [] : res.data.data;
      responseList.status = false;
    });
    
  };
