import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { BeatLoader } from "react-spinners";
  
const Division = lazy(() => import("../pages/Division"));
const SingleDivision = lazy(() => import("../pages/SingleDivision"));
const SingleTerritory = lazy(() => import("../pages/SingleTerritory"));
const SingleZone = lazy(() => import("../pages/SingleZone"));
const Zone = lazy(() => import("../pages/Zone"));
const Region = lazy(() => import("../pages/Region"));
const SalesDashboard = lazy(() => import("../pages/SalesDashboard"));

const LoadingFallback = () => (
  <div className="text-center">
    <BeatLoader color="#0D6EFD" />
  </div>
);

const Routers = ({showColumn1}) => {
  return (
    <Switch>
   {/*    <Route path="/CFO/Map" exact>
        <Suspense fallback={<LoadingFallback />}>
          <Region />
        </Suspense>
      </Route> */}
{/*       <Route path="/map" exact>
        <Suspense fallback={<LoadingFallback />}>
          <SalesDashboard />
        </Suspense>
      </Route> */}
      <Route path="/sales-dashboard" exact>
        <Suspense fallback={<LoadingFallback />}>
          <Region showColumn1={showColumn1} />
        </Suspense>
      </Route>
      <Route path="/sales-dashboard/zone" exact>
        <Suspense fallback={<LoadingFallback />}>
          <Zone showColumn1={showColumn1} />
        </Suspense>
      </Route>
      <Route path="/sales-dashboard/zone/:name" exact>
        <Suspense fallback={<LoadingFallback />}>
          <SingleZone showColumn1={showColumn1} />
        </Suspense>
      </Route>
      <Route path="/sales-dashboard/division" exact>
        <Suspense fallback={<LoadingFallback />}>
          <Division showColumn1={showColumn1} />
        </Suspense>
      </Route>
      <Route path="/sales-dashboard/division/:name" exact>
        <Suspense fallback={<LoadingFallback />}>
          <SingleDivision showColumn1={showColumn1}/>
        </Suspense>
      </Route>
      <Route path="/sales-dashboard/territory/:id" exact>
        <Suspense fallback={<LoadingFallback />}>
          <SingleTerritory showColumn1={showColumn1}/>
        </Suspense>
      </Route>
    </Switch>
  );
};

export default Routers;
