import Axios from "axios";
import * as Types from "./DashboardType";

export const InputHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT, payload: formData });
};
export const InputReportHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT_REPORT, payload: formData });
};
export const InputHandleChangeDate = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_START_END_DATE, payload: formData });
};


export const handleDateManageForDashboardCart = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_HANDLE_DATE_MANAGE_FOR_DASHBOARD, payload: formData });
};


export const GetDashboardTargetDate = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo,selectDistributorId,selectChannel) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_DASHBOARD_DATA, payload: responseList });
  let url = "";
  //url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDashboardSecondaryChart?ZoneID=${ZoneID}&DivisionID=${DivisionID}&AreaID=${AreaID}&AreaID=${AreaID}&Territory=${Territory}&Fdate=2022-12-01&Tdate=2022-12-27`;
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDashboardSecondaryChart?`;
  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  if (selectSo !== null) {
    url += `&soPoint=${selectSo}`
  }
  if (selectDistributorId !== null) {
    url += `&distribID=${selectDistributorId}`
  }
  if (selectChannel !== null) {
    url += `&SalesChannelId=${selectChannel}`
  }

  console.log("GetDashboardTargetDate",url);
  await Axios.get(url).then((res) => {
  
    responseList.data = res.data.data;
    responseList.isLoading = false;
  });
  
  dispatch({ type: Types.GET_DASHBOARD_DATA, payload: responseList });
};
export const GetABLMOnthWiseData = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo,selectDistributorId,salesChannelId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetMonthToMonthSecondarySalesTrend?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  if (selectSo !== null) {
    url += `&soPoint=${selectSo}`
  }
  if (selectDistributorId !== null) {
    url += `&distribID=${selectDistributorId}`
  }
  if (salesChannelId !== null) {
    url += `&SalesChannelId=${salesChannelId}`
  }
  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
    
  });
  dispatch({ type: Types.ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD, payload: responseList });
};
export const GetDamageData = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo,selectDistributorId,saleschannelid) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_DAMAGE_DATA, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDamageProductLine?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  if (selectSo !== null) {
    url += `&soPoint=${selectSo}`
  }
  if (selectDistributorId !== null) {
    url += `&distribID=${selectDistributorId}`
  }
  if (saleschannelid !== null) {
    url += `&SalesChannelId=${saleschannelid}`
  }
  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
    
  });
  dispatch({ type: Types.GET_DAMAGE_DATA, payload: responseList });
};
export const GetCategoryType = () => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductCategoryList`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
    
  });
  dispatch({ type: Types.GET_CATEGORY_TYPE, payload: responseList });
};
export const GetProductType = (CategoryId,startDate,toDate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductListByCategory?CategoryId=${CategoryId}&Fdate=${startDate}&Tdate=${toDate}`;

  await Axios.get(url).then((res) => {
    console.log("SKU",res.data.data)
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCTION_LINE_BY_PRODUCTION, payload: responseList });
};
export const GetNSMType = (zoneId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetNsMListByZoneId?zoneId=${zoneId}`;
  await Axios.get(url).then((res) => {
    console.log('division',res)
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_NSM_TYPE, payload: responseList });
};
export const GetDSMType = (divisionId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDsmListByDivisionId?divisionId=${divisionId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_DSM_TYPE, payload: responseList });
};

export const GetRMType = (areaId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetRmListByAreaId?areaId=${areaId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_RM_TYPE, payload: responseList });
};
export const GetTSMType = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetTsmListByTerritoryId?territoryId=${territoryId}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_TSM_TYPE, payload: responseList });
};
export const GetLineType = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductLine?`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_LINE_TYPE, payload: responseList });
};
export const GetProductGroup = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductGroup?`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCT_GROUP_TYPE, payload: responseList });
};

export const GetLocationWiseSales = (ZoneID=0, DivisionID=0, AreaID=0, Territory=0,Fdate,Tdate,category=0,ProductId=0,ProductGroup=0,productionLine=0) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  // dispatch({ type: Types.GET_LOCATION_WISE_DATA, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetLocationWiseSales?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  await Axios.get(url).then((res) => {
   
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
    
  });
  dispatch({ type: Types.GET_LOCATION_WISE_DATA, payload: responseList });
};
export const GetLocationWiseSalesForDonut = (ZoneID=0, DivisionID=0, AreaID=0, Territory=0,Fdate,Tdate,category=0,ProductId=0,ProductGroup=0,productionLine=0) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  dispatch({ type: Types.GET_LOCATION_WISE_DATA_FOR_DONUT, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetLocationWiseSales?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    
  });
  dispatch({ type: Types.GET_LOCATION_WISE_DATA_FOR_DONUT, payload: responseList });
};
export const clearList = (name, value) => (dispatch) => {
  let data = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CLEAR_LIST, payload: data });
};
export const GetProductionLine = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductionLine?`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCTION_LINE, payload: responseList });
};
export const GetProductionLineByProduction = (CId,Fdate,Tdate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductListBYProduction?CategoryId=${CId}&Fdate=${Fdate}&Tdate=${Tdate}`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_PRODUCTION_LINE_BY_PRODUCTION, payload: responseList });
};
export const GetDistributorByTerritoryID = (ZoneID=0, DivisionID=0, AreaID=0, Territory=0) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  dispatch({ type: Types.GET_DISTRIBUTOR_BY_TERRITORY_ID, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDistributorListbyTerritoryID?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_BY_TERRITORY_ID, payload: responseList });
};
export const GetSoPointByDistributorId = (distributorId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  dispatch({ type: Types.GET_SO_POINT_BY_DISTRIBUTOR_ID, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSOPointListbyDistributorId?DistributorId=${distributorId}`;

  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    
  });
  dispatch({ type: Types.GET_SO_POINT_BY_DISTRIBUTOR_ID, payload: responseList });
};
export const getSoList = (terrioryId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  dispatch({ type: Types.GET_SO_POINT_BY_TERRITORY_ID, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSOPointListbyAreaForDashboard?TerritoryId=${terrioryId}`;

  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.status = false;
    
  });
  dispatch({ type: Types.GET_SO_POINT_BY_TERRITORY_ID, payload: responseList });
};
export const getAblCumulativeSalesReportWithPackType = (Fdate,Tdate) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.GET_REPORT_DATA, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}AblCumulativeSalesReportWithPackType?`;
 
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  console.log("report",url);
  await Axios.get(url).then((res) => {
  console.log('res :>> ', res);
    responseList.data = res.data.data;
    responseList.status = false;
  });
  
  dispatch({ type: Types.GET_REPORT_DATA, payload: responseList });
};
export const GetSalesChannelType = (territoryId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetSalesChannelList?`;
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data;
    responseList.status = false;
  });
  dispatch({ type: Types.GET_SALES_CHANNEL_TYPE, payload: responseList });
};

// primary sales trand
export const GetablPrimarySalesTrand = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo,selectDistributorId,salesChannelId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({ type: Types.ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetMonthToMonthPrimarySalesTrend?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  if (selectSo !== null) {
    url += `&soPoint=${selectSo}`
  }
  if (selectDistributorId !== null) {
    url += `&distribID=${selectDistributorId}`
  }
  if (salesChannelId !== null) {
    url += `&SalesChannelId=${salesChannelId}`
  }
  await Axios.get(url).then((res) => {
    
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
    
  });
  dispatch({ type: Types.ABL_PRIMARY_SALES_TRAND, payload: responseList });
};
// primary sales trand

// dis bank recv
export const GetDisBankRecvData = (SalesChannelId,ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo,selectDistributorId,salesChannelId) => async (dispatch) => {

  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  // dispatch({ type: Types.GET_DISTIB_BANK_REC_DATA, payload: responseList });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetDisBankCollection?`;

  if (SalesChannelId !== null) {
    url += `&SalesChannelId=${SalesChannelId}`
  }
  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`
  }
  if (category !== null) {
    url += `&category=${category}`
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`
  }
  if (selectSo !== null) {
    url += `&soPoint=${selectSo}`
  }
  if (selectDistributorId !== null) {
    url += `&distribID=${selectDistributorId}`
  }
  if (salesChannelId !== null) {
    url += `&SalesChannelId=${salesChannelId}`
  }
  await Axios.get(url).then((res) => {
    console.log('bank res :>> ', res);
    
    responseList.data = res.data == null ? [] : res.data;
    responseList.isLoading = false;
    
  });
  dispatch({ type: Types.GET_DISTIB_BANK_REC_DATA, payload: responseList });
};

export const getLineWiseSalesStandard = (Fdate,Tdate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  dispatch({
    type: Types.GET_LINE_WISE_SALES_STANDRED,
    payload: responseList,
  });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetLineWiseSalesStandard?`;

  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`;
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`;
  }

  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
  });
  dispatch({
    type: Types.GET_LINE_WISE_SALES_STANDRED,
    payload: responseList,
  });
};

// dis bank recv