import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";

const LocationWIseSalesPieCHart2 = () => {
  const isLoading = useSelector((state) => state.dashboardcart.locationLoading);
  const LocationWisePieData = useSelector(
    (state) => state.dashboardcart.LocationWisePieData
  );
  const zoneData = useSelector((state) => state.dashboardcart.zoneData);
  const total = useSelector((state) => state.dashboardcart.total);
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const Measurement = reportData.measurement;
  const chartData = useSelector((state) => state.dashboardcart.chartData);
  const chartData2 = useSelector((state) => state.dashboardcart.chartData2);
  const locationTotalQty = useSelector(
    (state) => state.dashboardcart.locationTotalQty
  );

  let nf = new Intl.NumberFormat("en-BD");

  const data = {
    labels: zoneData,
    datasets: [
      {
        data: LocationWisePieData,
        backgroundColor: [
          "#0b8793",
          "#00C9FF",
          "#fcb045",
          "#ff9068",
          "#4e6af5",
          "#e86193",
          "#c973ff",
          "#927694",
        ],
        borderColor: [
          "#0b8793",
          "#00C9FF",
          "#fcb045",
          "#ff9068",
          "#4e6af5",
          "#e86193",
          "#c973ff",
          "#927694",
        ],
        borderWidth: 1,
        labels: zoneData,
      },
    ],
  };
  const options = {
    responsive: true,
    chart: {
      width:50,
    },
    plugins: {
      legend: {
        position: "bottom",
        display: true,
      },
      datalabels: {
        display: true,
        color: "white",
        formatter: (value, ctx) => {
          let dataset = ctx.chart.data.datasets[ctx.datasetIndex];
          let total = dataset.data.reduce((a, b) => a + b, 0);
          let percentage = ((value / total) * 100).toFixed(2) + "%";
          return `${dataset.labels[ctx.dataIndex]}\n${value}m \n(${percentage})`;
        },
      },
    },

    // scales: {
    //   y: {
    //     title: {
    //       display: true,
    //       text: "Millions",
    //       font: {
    //         size: 12,
    //       },
    //     },
    //   },
    // },
    responsive: [
      {
        breakpoint: 780,
        options: {
          chart: {
            width: 100,
          },
        },
      },
      {
        breakpoint: 400,
        options: {
          chart: {
            width: 50,
          },
        },
      },
    ],
  };
  return (
    <div className="mb-0 card locationMother">
      <span className="spineercolor mt-5 mb-5">
        {isLoading && <LoadingSpinner />}
      </span>
      <>
        {!isLoading && (
          <>
            <div className="locatioChartHead">
              <div className="text-center">
                <h5>Location Wise Sales at million</h5>
              </div>
              <div className="text-center location">
                <h6>
                  Total:{Measurement == 1 ? total + " m" : locationTotalQty}{" "}
                </h6>
              </div>
            </div>
            <div className="cuswidth3">
              <Pie
                options={options}
                data={data}
                plugins={[ChartDataLabels]}
              />
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default LocationWIseSalesPieCHart2;

