export const GET_SEARCH_DATA_INPUT = "GET_SEARCH_DATA_INPUT";
export const CHANGE_VALUE_INPUT = "CHANGE_VALUE_INPUT";
export const CHANGE_PRODUCT_INPUT = "CHANGE_PRODUCT_INPUT";
export const GET_UNIT = "GET_UNIT";
export const GET_PARTY_TYPE = "GET_PARTY_TYPE";
export const GET_PARTY_NAME = "GET_PARTY_NAME";
export const GET_PARTY_LEDGER = "GET_PARTY_LEDGER";


export const GET_PO_DETAILS = "GET_PO_DETAILS";

