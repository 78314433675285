import React from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { numberFormat } from "./Money";

const Money2 = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  const dashboardInfo = useSelector(
    (state) => state.dashboardcart.dashboardData
  );
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const Measurement = reportData.measurement;
  const isLoading = useSelector((state) => state.dashboardcart.toCardLoading);
  console.log("dashboardInfo", dashboardInfo);
  console.log('dashboardInfo?.totalDays-remaining :>> ', remaining);
  console.log('dashboardInfo?.totalDays :>> ', dashboardInfo?.totalDays);

  const average =
    dashboardInfo?.totalSecondaryTargetValue -
    dashboardInfo?.totalSecondaryValue;
  const remaining = dashboardInfo?.totalDays - dashboardInfo?.currentDays;
  const workingPassed = parseInt((dashboardInfo?.totalDays - remaining) / dashboardInfo?.totalDays * 100);
  console.log('remaining', remaining)
  // const restavg = ((dashboardInfo?.totalSecondaryTargetValue) - (dashboardInfo?.totalSecondaryValue)) / remaining==0?1:0;
  // const restavgqty = ((dashboardInfo?.targetSecondaryQty) - (dashboardInfo?.totalSecondaryQty)) / remaining==0?1:0;
  const restavg = Math.floor(
    (dashboardInfo?.totalSecondaryTargetValue -
      dashboardInfo?.totalSecondaryValue) /
    remaining
  );
  const restavgqty = Math.floor(
    (dashboardInfo?.targetSecondaryQty - dashboardInfo?.totalSecondaryQty) /
    remaining
  );

  let nf = new Intl.NumberFormat("en-BD");
  const color = restavg < 0 ? " #006622" : "rgb(61 61 148)";
  const resttav = restavg < 0 ? 0 : restavg;
  return (
    <div className="money">
      <span className="spineercolor mt-5 mb-5">
        {isLoading && <LoadingSpinner />}
      </span>
      <>
        {!isLoading && (
          <>
            <div className="sub_card">
              <span className="mb-2 moneyheading changeC">SECONDARY</span>
            </div>

            <div className="sub_card">
              <span>
                <span className="totaltargettext changeC poppins spanheading">
                  Total Target<br></br>
                </span>
                <span className="text-1st changeC">
                  {numberFormat(
                    parseInt(
                      Measurement == 1
                        ? dashboardInfo?.totalSecondaryTargetValue
                        : dashboardInfo?.targetSecondaryQty
                    )
                  )}
                </span>
              </span>
            </div>
            <div className="sub_card">
           
              <span className="txtwidth poppins spanheading collColor"></span><br></br>
             
                <span className="text-2nd collColor p-5">
                
                </span>
            </div>
            <div className="sub_card">
              <span className="moneyacheived">
                <span className="txtwidth moneyacheivedC poppins spanheading">Achieved</span><br></br>
                <span className="text-4th moneyacheivedC">
                  {numberFormat(
                    parseInt(
                      Measurement == 1
                        ? dashboardInfo?.totalSecondaryValue
                        : dashboardInfo?.totalSecondaryQty
                    )
                  )}
                </span>
              </span>
            </div>
            <div className="sub_card">
              <span className="txtwidth rdtcolor updaterdtColor spanheading">RDT</span><br></br>
              <span className="text-2nd updaterdtColor">
                {year == dashboardInfo?.currentYear &&
                  month == dashboardInfo?.currentMonth ? (
                  <div className="">
                    <span className="text-2nd">
                      {Measurement == 1 ? (
                        <div>{numberFormat(resttav)}</div>
                      ) : (
                        <div>{numberFormat(restavgqty)}</div>
                      )}
                    </span>
                  </div>
                ) : (
                  <span className="text-2nd">0</span>
                )}
              </span>
            </div>
            {year == dashboardInfo?.currentYear &&
              month == dashboardInfo?.currentMonth ? (
              <div className="sub_card p-2">
                {/* <h2 className="text-3rd">{remaining} Days Left</h2> */}
                <h2 className="text-3rd">{remaining} W.Days Left</h2>
                {/* <span>Time Remaining in current month</span> */}
              </div>
            ) : (
              <h2 className="text-3rd p-2">0 W.Days Left</h2>
            )}
            {year == dashboardInfo?.currentYear &&
              month == dashboardInfo?.currentMonth ? (
              <div className="sub_card p-2">
                {/* <h2 className="text-3rd">{remaining} Days Left</h2> */}
                <h2 className="wpassed">{workingPassed}% T.Passed</h2>
                {/* <span>Time Remaining in current month</span> */}
              </div>
            ) : (
              <h2 className="wpassed p-2">100% T.Passed</h2>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default Money2;
