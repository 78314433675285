import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCumulativeReport } from "./ProductionLineAction";
import { useReactToPrint } from "react-to-print";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { numberFormat } from "./Money";
import Print from "../master/PrintDwopdown/Print";
const Report = () => {
  const conponentPDF = useRef();
  const componentRef = useRef();
  const dispatch = useDispatch();
  // useEffect(() => {
  //   initialData();
  // }, []);
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
  });

  const cumulativeReportData = useSelector(
    (state) => state.productionLine.cumulativeReportData
  );
  const totalInformation = useSelector(
    (state) => state.productionLine.totalInformation
  );
  const brandInformation = useSelector(
    (state) => state.productionLine.brandInformation
  );
  const isLoading = useSelector(
    (state) => state.productionLine.cumulativeReportdataLoading
  );
  const daysLeft = cumulativeReportData.totalDays-cumulativeReportData.currentDays;
  const workingPassed = parseInt(
    ((cumulativeReportData?.totalDays - daysLeft) /
      cumulativeReportData?.totalDays) *
      100
  );

  let d = new Date();
  let firstD =
    d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
  let currentD =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2) +
    "";
  // const initialData = async () => {
  //   dispatch(getCumulativeReport(firstD, currentD));
  // };
  const biscuitChannelGap = totalInformation
    ? Math.abs(
        Math.floor(
          totalInformation[0]?.primaryAchivement -
            totalInformation[0]?.secondaryAcv
        )
      )
    : 0;
  const breadBanGap = totalInformation
    ? Math.abs(
        Math.floor(
          totalInformation[1]?.primaryAchivement -
            totalInformation[1]?.secondaryAcv
        )
      )
    : 0;
  const totalGap = biscuitChannelGap + breadBanGap;
  return (
    <div className="reportDataTable container-fluid">
      <div className="">
        <div className="card position-relative">
          <div className="m-3 d-flex flex-row-reverse">
            {/* <button className="button-621" onClick={generatePDF}>
              Print
            </button> */}
            <button type="button" class="btn  ">
              <Print refvalue={componentRef} />
            </button>
          </div>
          {isLoading && (
            <span className="spineercolor mt-5 mb-5">
              <LoadingSpinner />
            </span>
          )}
          <>
            {!isLoading && (
              <div ref={componentRef} style={{ width: "100%" }}>
                <div className="text-center pt-2  rounded-top">
                  <button className="button-010">
                    ABL Cumulative Sales Report In Tk. (Cr)
                  </button>
                  {/* <h4 className=""></h4> */}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h6 className="p-1 m-2 d-inline-block rounded p-2 bg-light">
                      Report As on: {currentD}
                    </h6>
                  </div>
                  <div className="">
                    <h6 className=" p-1 m-2 d-inline-block rounded p-2 bg-light">
                      Day Passed: {daysLeft ? daysLeft : 0} Days
                    </h6>
                  </div>
                </div>
                <div className="p-3">
                  <div className="react-bootstrap-table table-responsive border-0">
                    <table class="table table-head-custom table-vertical-center item-add-table borderTableims mb-5">
                      <thead>
                        <tr className="bg-light">
                          <th>
                            <b>Channel Name</b>
                          </th>
                          <th>
                            <b>Biscuit Channel</b>
                          </th>
                          <th>
                            <b>Bread & Bun Channel</b>
                          </th>
                          <th>
                            <b>Total</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="">
                          <td className="bg-light">
                            <b>Target </b>({currentD})
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(totalInformation[0]?.primaryTarget)
                                )
                              : 0}
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(totalInformation[1]?.primaryTarget)
                                )
                              : 0}
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(
                                    totalInformation[0]?.primaryTarget
                                  ) +
                                    Math.floor(
                                      totalInformation[1]?.primaryTarget
                                    )
                                )
                              : 0}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="bg-light">
                            <b>Time Pass %</b>
                          </td>
                          <td>{workingPassed ? workingPassed : 0}%</td>
                          <td>{workingPassed ? workingPassed : 0}%</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td className="bg-light">
                            <b>IMS Ach %</b>
                          </td>
                          <td>
                            {totalInformation
                              ? Math.floor(
                                  (totalInformation[0]?.secondaryAcv /
                                    totalInformation[0]?.primaryTarget) *
                                    100
                                ) + "%"
                              : 0}
                          </td>
                          <td>
                            {totalInformation
                              ? Math.floor(
                                  (totalInformation[1]?.secondaryAcv /
                                    totalInformation[1]?.primaryTarget) *
                                    100
                                ) + "%"
                              : 0}
                          </td>
                          <td>
                            {totalInformation
                              ? Math.floor(
                                  ((totalInformation[0]?.secondaryAcv +
                                    totalInformation[1]?.secondaryAcv) /
                                    (totalInformation[0]?.primaryTarget +
                                      totalInformation[1]?.primaryTarget)) *
                                    100
                                ) + "%"
                              : 0}
                          </td>
                        </tr>
                        <tr>
                          <td className="bg-light">
                            <b>Primary Sales</b>
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(
                                    totalInformation[0]?.primaryAchivement
                                  )
                                )
                              : 0}
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(
                                    totalInformation[1]?.primaryAchivement
                                  )
                                )
                              : 0}
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(
                                    totalInformation[0]?.primaryAchivement +
                                      totalInformation[1]?.primaryAchivement
                                  )
                                )
                              : 0}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="bg-light">
                            <b>Secondary Sales</b>
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(totalInformation[0]?.secondaryAcv)
                                )
                              : 0}
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(totalInformation[1]?.secondaryAcv)
                                )
                              : 0}
                          </td>
                          <td>
                            {totalInformation
                              ? numberFormat(
                                  Math.floor(
                                    totalInformation[0]?.secondaryAcv +
                                      totalInformation[1]?.secondaryAcv
                                  )
                                )
                              : 0}
                          </td>
                        </tr>
                        <tr className="">
                          <td className="bg-light">
                            <b>Gap</b>
                          </td>
                          <td>{numberFormat(biscuitChannelGap)}</td>
                          <td>{numberFormat(breadBanGap)}</td>
                          <td>{numberFormat(totalGap)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="react-bootstrap-table table-responsive border-0 mt-2">
                    <table class="table table-head-custom table-vertical-center item-add-table borderTableims mb-5">
                      <tbody></tbody>
                    </table>
                  </div>
                  <div className="react-bootstrap-table table-responsive border-0 mt-2">
                    <table class="table table-head-custom table-vertical-center item-add-table borderTableims mb-5">
                      <thead>
                        <tr className="bg-light">
                          <th>
                            <b>Brand</b>
                          </th>
                          <th>
                            <b>Target</b>
                          </th>
                          <th>
                            <b>Achv</b>
                          </th>
                          <th>
                            <b>%</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {brandInformation?.map((brand, index) => (
                          <tr className="border" key={index}>
                            <td>{brand.brandName}</td>
                            <td>
                              {numberFormat(Math.floor(brand.primaryTarget))}
                            </td>
                            <td>
                              {numberFormat(Math.floor(brand.secondaryAcv))}
                            </td>
                            <td>
                              <span
                                style={{
                                  color:
                                    Math.floor(
                                      (brand.secondaryAcv /
                                        brand.primaryTarget) *
                                        100
                                    ) < 100
                                      ? "red"
                                      : "black",
                                }}
                              >
                                <b>
                                  {brand.primaryTarget > 0
                                    ? Math.floor(
                                        (brand.secondaryAcv /
                                          brand.primaryTarget) *
                                          100
                                      ) + "%"
                                    : 0 + "%"}
                                </b>
                              </span>
                            </td>
                          </tr>
                        ))}
                        <tr className="bg-light tableTotal">
                          <td className="fw-bold">
                            <b className="fw-bold">Total</b>
                          </td>
                          <td className="fw-bold">
                            {cumulativeReportData
                              ? numberFormat(
                                  Math.floor(cumulativeReportData.totalTarget)
                                )
                              : 0}
                          </td>
                          <td>
                            {cumulativeReportData
                              ? numberFormat(
                                  Math.floor(
                                    cumulativeReportData.totalAchivement
                                  )
                                )
                              : 0}
                          </td>
                          <td>
                            <b>
                              {cumulativeReportData
                                ? Math.floor(
                                    (cumulativeReportData.totalAchivement /
                                      cumulativeReportData.totalTarget) *
                                      100
                                  )
                                : 0}
                              %
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="react-bootstrap-table table-responsive border-0 mt-2">
                    <table class="table table-head-custom table-vertical-center item-add-table borderTableims mb-5">
                      <thead>
                        <tr>
                          <th className="bg-light">
                            <b>Summary</b>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>7 Brands are going per Trend (Time Pass %)</td>
                        </tr>
                        <tr>
                          <td>
                            17 Brands are NOT going per Trend (Time Pass %)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Report;
