import React from "react";
import { Route } from "react-router-dom";
import AccountsTargetEntry from "../../Accounts/Components/AccountsTargetEntry";
import ProductionLineContainer from "../../Cfo/ProductionLineContainer";
import ReportGroupA from "../../Cfo/ReportGroupA";
import TableIndex from "../../TestTable/TableIndex";
import ReactDataTable from "../Components/ReactDataTable";
import Report from "../../Cfo/Report";
import AllReportPage from "../../Cfo/AllReportPage";
import StandardMixing from "../../Cfo/StandardMixing";
import AblCManalysisContainer from "../../Cfo/AblCManalysisContainer";
import ProductionStockMonitoring from "../../Cfo/ProductionStockMonitoring";
import SalesDashboard from '../../../Map/pages/SalesDashboard'
import { lazy } from "react";
import RegionMap from "../../../Map/components/RegionMap";
import PerformerDetails from "../../../Map/components/PerformerDetails";
import DailyReport from "../../../Map/Report/DailyReport";
import DivisionWiseImsSummery from "../../../Map/Report/DivisionWiseImsSummery";
import RegionWiseImsSummery from "../../../Map/Report/RegionWiseImsSummery";
import ButtomThreeDsm from "../../../Map/Report/ButtomThreeDsm";
import TopTenRM from "../../../Map/Report/TopTenRM";
import Bottomtenrm from "../../../Map/Report/Bottomtenrm";
import TopTwentyTsm from "../../../Map/Report/TopTwentyTsm";
import BottomTwentyTsm from "../../../Map/Report/BottomTwentyTsm";
import TopFiveDSM from "../../../Map/Report/TopFiveDSM";
import HeighestTwentySalesOfficers from "../../../Map/Report/HeighestTwentySalesOfficers";
import BottomTwentySalesOfficers from "../../../Map/Report/BottomTwentySalesOfficers";
import MainRolling from "../../../Map/Report/Rolling/MainRolling";
import RolMain from "../../../Map/Report/Rolling/RolMain";
import DailyImsReportSummery from "../../../Map/Report/DailyImsReportSummery";
import TopTwentSalesValueAchOfficer from "../../../Map/Report/TopTwentSalesValueAchOfficer";
import BottomTwentyValueAchever from "../../../Map/Report/BottomTwentyValueAchever";
import CategoryWiseDivisionAch from "../../../Map/Report/CategoryWiseDivisionAch";
import FocusProductTargetVsAchievement from "../../../Map/Report/FocusProductTargetVsAchievement";
import FocusSkuTargetVsAch from "../../../Map/Report/FocusSkuTargetVsAch";
import TopSellingSku from "../../../Map/Report/TopSellingSku";
import MainRollingPre from "../../../Map/Report/Rolling/MainRollingPre";
import AllDailyReportMenu from "../../../Map/Report/AllDailyReportMenu";
import ReportMenuWithModal from "../../../Map/Report/ReportMenuWithModal";

const Region = lazy(() => import("../../../Map/pages/Region"));
const Division = lazy(() => import("../../../Map/pages/Division"));
const SingleDivision = lazy(() => import("../../../Map/pages/SingleDivision"));
const SingleTerritory = lazy(() => import("../../../Map/pages/SingleTerritory"));
const Zone = lazy(() => import("../../../Map/pages/Zone"));

const SingleZone = lazy(() => import("../../../Map/pages/SingleZone"));

const routeItems = [
  {
    path: "/billing/AccountsTargetEntry",
    name: "AFML BPT",
    component: AccountsTargetEntry,
    exact: true,
  },
  {
    path: "/billing/demoTable",
    name: "demo",
    component: ReactDataTable,
    exact: true,
  },
  {
    path: "/TestTable/demoTable",
    name: "demo1",
    component: TableIndex,
    exact: true,
  },
  {
    path: "/CFO/ProductionLine",
    name: "CFO Dashboard",
    component: ProductionLineContainer,
    exact: true,
  },
  {
    path: "/CFO/CManalysis",
    name: "CFO Dashboard",
    component: AblCManalysisContainer,
    exact: true,
  },
  {
    path: "/CFO/ProductionStockMonitoring",
    name: "CFO Dashboard",
    component: ProductionStockMonitoring,
    exact: true,
  },
  {
    path: "/CFO/report",
    name: "CFO Dashboard",
    component: ReportGroupA,
    exact: true,
  },
  {
    path: "/allReport",
    name: "All Report",
    component: AllReportPage,
    exact: true,
  },
  {
    path: "/standardMixing",
    name: "StandardMixing Report",
    component: StandardMixing,
    exact: true,
  },
  {
    path: "/sales-dashboard",
    name: "Hitmap Report",
    component: SalesDashboard,
    exact: true,
  },

  {
    path: "/sales-dashboard/zone",
    name: "Hitmap Report",
    component: SalesDashboard,
    exact: true,
  },
  {
    path: "/sales-dashboard/zone/:name",
    name: "Hitmap Report",
    component: SalesDashboard,
    exact: true,
  },

  {
    path: "/sales-dashboard/division",
    name: "Hitmap Report",
    component: SalesDashboard,
    exact: true,
  },
  {
    path: "/sales-dashboard/division/:name",
    name: "Hitmap Report",
    component: SalesDashboard,
    exact: true,
  },
  {
    path: "/sales-dashboard/territory/:id",
    name: "Hitmap Report",
    component: SalesDashboard,
    exact: true,
  }
  ,
  {
    path: "/sales-dashboard/performer/:id",
    name: "Hitmap Report",
    component: PerformerDetails,
    exact: true,
  },
  {
    path: "/report/daily-rolling",
    name: "Daily Report",
    component: MainRolling,
    exact: true,
  }
  ,
  {
    path: "/report/division-wise-ims-summery",
    name: "Daily Report",
    component: DivisionWiseImsSummery,
    exact: true,
  }
  , {
    path: "/report/region-wise-ims-summery",
    name: "Daily Report",
    component: RegionWiseImsSummery,
    exact: true,
  },
  {
    path: "/report/bottom-wise-three-dsm",
    name: "Daily Report",
    component: ButtomThreeDsm,
    exact: true,
  },
  {
    path: "/report/top-ten-rm",
    name: "Daily Report",
    component: TopTenRM,
    exact: true,
  },
  {
    path: "/report/bottom-ten-rm",
    name: "Daily Report",
    component: Bottomtenrm,
    exact: true,
  },
  {
    path: "/report/top-twenty-tsm",
    name: "Daily Report",
    component: TopTwentyTsm,
    exact: true,
  },
  {
    path: "/report/bottom-twenty-tsm",
    name: "Daily Report",
    component: BottomTwentyTsm,
    exact: true,
  },
  {
    path: "/report/top-five-dsm",
    name: "Daily Report",
    component: TopFiveDSM,
    exact: true,
  },
  {
    path: "/report/heighest-twenty-sales-officer",
    name: "Daily Report",
    component: HeighestTwentySalesOfficers,
    exact: true,
  },
  {
    path: "/report/bottom-twenty-sales-officers",
    name: "Daily Report",
    component: BottomTwentySalesOfficers,
    exact: true,
  }
  ,
  {
    path: "/rolling-report",
    name: "Daily Report",
    component: MainRolling,
    exact: true,
  },
  ,
  {
    path: "/daily-ims-summery",
    name: "Daily Report",
    component: DailyImsReportSummery,
    exact: true,
  },
  {
    path: "/report/top-twenty-ach-so",
    name: "Daily Report",
    component: TopTwentSalesValueAchOfficer,
    exact: true,
  },
  {
    path: "/report/bottom-twenty-ach-so",
    name: "Daily Report",
    component: BottomTwentyValueAchever,
    exact: true,
  },
  {
    path: "/report/category-wise-division-ach",
    name: "Daily Report",
    component: CategoryWiseDivisionAch,
    exact: true,
  }
  ,
  {
    path: "/report/focus-product-target-vs-ach",
    name: "Daily Report",
    component: FocusProductTargetVsAchievement,
    exact: true,
  },
  {
    path: "/report/focus-sku-target-vs-ach",
    name: "Daily Report",
    component: FocusSkuTargetVsAch,
    exact: true,
  },
  {
    path: "/report/top-selling-sku",
    name: "Daily Report",
    component: TopSellingSku,
    exact: true,
  },
  {
    path: "/preMonthRolling",
    name: "Daily Report",
    component: MainRollingPre,
    exact: true,
  }
  ,
  {
    path: "/allReportMenu",
    name: "Daily Report",
    component: ReportMenuWithModal,
     exact: true,
  }
];
function getAccountsRoutes() {
  {
    return routeItems.map((route, index) => (
      <Route
        path={route.path}
        component={route.component}
        exact={route.exact}
      />
    ));
  }
}
export default getAccountsRoutes;
