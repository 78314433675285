import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button, Spinner, Col } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { GetCategory,GetCustomerGroup, GetUnit, InputHandle, TargetSubmit } from "../redux/actions/AccountsAction";

const AccountsTergetEntry = (props) => {
  const { register, handleSubmit, errors, setValue, setFocus } = useForm();
  const dispatch = useDispatch();

  const options = [
    { value: "1", label: "Bulk" },
    { value: "2", label: "Consumer" },
  ];


  const [startDate, setStartDate] = useState(null);
  const [lockStatus, setLockStatus] = useState(null);
  const [selectUnit, setselectUnit] = useState(null);
  const [selectCategory, setselectCategory] = useState(null);
  const [selectTarget, setselectTarget] = useState(null);
  const [selectValue, setselectValue] = useState(null);


  const ddlUnit = useSelector((state) => state.accounts.ddlUnit);
  const ddlCategory = useSelector((state) => state.accounts.ddlCategory);
  const itemDataInput = useSelector((state) => state.accounts.itemDataInput);
  const ddlCusGroup = useSelector((state) => state.accounts.ddlCusGroup);

  const reports = useSelector((state) => state.accounts.reportData);

  console.log('itemDataInput', itemDataInput);

  useEffect(() => {
        dispatch(GetUnit());
        dispatch(GetCategory());
        // dispatch(GetCustomerGroup());
  }, [])
  
  const handleChange = (name,value) => {
    dispatch(InputHandle(name, value));
  };

  const handleUnitChange=(unit)=>{

    if(unit == 98){
      dispatch(GetCategory(unit));
    }else{
      dispatch(GetCategory(unit));
      dispatch(GetCustomerGroup(unit));
    }
   
  }

  const onSubmit = async (e) => {
    // if (startDate == null) {
    //   alert("Please select Date");
    //   return false;
    // } else if (selectUnit == null) {
    //   alert("Please select Unit");
    //   return false;
    // } else if (selectCategory == null) {
    //   alert("Please select Category");
    //   return false;
    // } else if (selectTarget == null) {
    //   alert("Please select Target");
    //   return false;
    // } else if (selectValue == null) {
    //   alert("Please select Value");
    //   return false;
    // } 
    //setDisabled(true);
    dispatch(TargetSubmit(itemDataInput,startDate));
  };
  const handleDateChange = ({ currentTarget: input }) => {
    setStartDate(input.value);
    
  };
  

  return (
    <div className="targetEntry">
      <form className="form form-label-right"
       onSubmit={handleSubmit(onSubmit)}
       method="post"
       encType="multipart/form-data"
       autoComplete="off"
      >
        <div className="card card-custom gutter-b pl-5 pr-5 mt-5 card-top-border p-3 bg-light">
          <div className="row mt-3">
            <div className="col-md-9">
              <div className="mt-2 p-2">
                <h3 className="mb-0 pb-0">Accounts Target Entry</h3>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="form-group row">
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label"> Select Date</label>
                <Form.Control
                  type="date"
                  placeholder="Enter Date"
                  name="fromDate"
                  className="fromStyle"
                  onChange={handleDateChange}
                  value={startDate}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3">
               <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Unit</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={ddlUnit} />}
                  rules={{ required: false }}
                  name="unit"
                  register={register}
                  value=""
                  onChange={(option)=>{
                    handleChange('unit',option.value);
                    handleUnitChange(option.value);
                   
                  }
                 
                }
                  setValue={setValue}
                />
              </Form.Group>
            </div>

            {/* <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Customer Group</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={ddlCusGroup} />}
                  rules={{ required: false }}
                  name="customerGroup"
                  register={register}
                  onChange={(option)=>handleChange('customerGroup',option.value)}
                  setValue={setValue}
                />
              </Form.Group>
            </div> */}

           {
              itemDataInput.unit ==53 &&
              <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Customer Group</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={ddlCusGroup} />}
                  rules={{ required: false }}
                  name="customerGroup"
                  register={register}
                  value=""
                  onChange={(option)=>handleChange('customerGroup',option.value)}
                  setValue={setValue}

                />
              </Form.Group>
            </div>

            }



           
            <div className="col-lg-3">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Select Category</label>
                <RHFInput
                  className="formSelect pt-0"
                  as={<Select options={ddlCategory} />}
                  rules={{ required: false }}
                  name="category"
                  register={register}
                  value=""
                  onChange={(option)=>handleChange('category',option.value)}
                  setValue={setValue}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mb-5 ">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Target Qty</label>
                <Form.Control
                  type="number"
                  placeholder="Enter Target"
                  name="targetQty"
                  className="fromStyle"
                  onChange={(e)=>handleChange('targetQty',e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col-lg-3 mb-5 ">
              <Form.Group as={Col} controlId="formGridState">
                <label className="form-label">Target Value</label>
                <Form.Control
                  type="number"
                  placeholder="Enter Value"
                  name="targetValue"
                  className="fromStyle"
                  onChange={(e)=>handleChange('targetValue',e.target.value)}
                />
              </Form.Group>
            </div>

            <div className="col-lg-3 mt-5">
              <button
                type="submit"
                className="btn btn-primary  btn-sm  text-center custome-addnew-btn item-attach mt-1 ml-4">
                Save
              </button>
            </div>
          </div>
        </div>

        {/* <div className="card card-custom gutter-b pl-5 pr-5 mb-5 ">
          <div className="react-bootstrap-table table-responsive mt-2">
            <table className="table table table-head-custom table-vertical-center">
              <thead>
                <th>CUSTOMER GROUP</th>
                <th>UNIT</th>
                <th>CATEGORY</th>
                <th>UNIT</th>
                <th>VALUE</th>
                <th>BP - TARGET JAN</th>
                <th>REMOVE</th>
              </thead>
              <tbody>
                <tr>
                  <td>BULK</td>
                  <td></td>
                  <td>Bulk Atta</td>
                  <td>MT</td>
                  <td>BDT</td>
                  <td>8129.0533503471</td>
                  <td>
                    <i className="fa fa-trash deletecss ml-3 " />
                  </td>
                </tr>
                <tr>
                  <td>BULK</td>
                  <td></td>
                  <td>Bulk Atta</td>
                  <td>MT</td>
                  <td>BDT</td>
                  <td>8129.0533503471</td>
                  <td>
                    <i className="fa fa-trash deletecss ml-3 " />
                  </td>
                </tr>
                <tr>
                  <td>BULK</td>
                  <td>MT</td>
                  <td>Bulk Atta</td>
                  <td>MT</td>
                  <td>BDT</td>
                  <td>1,18,129.0533503471</td>
                  <td>
                    <i className="fa fa-trash deletecss ml-3 " />
                  </td>
                </tr>
                <tr>
                  <td>BULK</td>
                  <td>MT</td>
                  <td>Bulk Atta</td>
                  <td>MT</td>
                  <td>BDT</td>
                  <td>1,18,129.0533503471</td>
                  <td>
                    <i className="fa fa-trash deletecss ml-3 " />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
      </form>
    </div>
  );
};
export default AccountsTergetEntry;
