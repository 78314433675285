import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { numberFormat } from "./Money";
import { sum } from "lodash";
import ColoredTableModal from "./ColoredTableModal";
import SimpleModal from "./SimpleModal";
import ModalForColoredTable from "./ModalForColoredTable";

const PER_PAGE = 12;

const CMinPercentageColoredTable = () => {
    const lwStandCMData = useSelector((state) => state.cm.lwStandCMData);
    const sumSales = useSelector((state) => state.cm.sumSalesLineWise);
    const sumSalesCal = useSelector((state) => state.cm.sumSalesCal);
    const standardSalesLineWise = useSelector((state) => state.cm.standardSalesLineWise);
    const ActualCMSumSalesLineWise = useSelector((state) => state.cm.ActualCMSumSalesLineWise);
    const standardSumValue = useSelector((state) => state.cm.standardSumValue);
    const ActualSumValue = useSelector((state) => state.cm.ActualSumValue);
    const detailsArrayYellow = useSelector((state) => state.cm.detailsArrayYellow);
    const detailsArrayRed = useSelector((state) => state.cm.detailsArrayRed);
    const detailsArrayGreen = useSelector((state) => state.cm.detailsArrayGreen);
    const totalValue = useSelector((state) => state.cm.totalValue);
    console.log('detailsArrayYellow :>> ', detailsArrayYellow);
    console.log('detailsArrayRed :>> ', detailsArrayRed);
    console.log('detailsArrayGreen :>> ', detailsArrayGreen);
    console.log('ef lwStandCMData :>> ', lwStandCMData);

    const [itemDetailShow3, setItemDetailShow3] = useState(false);
    const [itemifo3, setItemifo3] = useState(null);
    const [itemID, setItemID] = useState(null);

    const isLoading = useSelector((state) => state.cm.lwStandCMDataLoading);
    const [currentPage, setCurrentPage] = useState(1);

    const YellowCount = useSelector(
        (state) => state.cm.YellowCount
    );
    const ReduCount = useSelector(
        (state) => state.cm.ReduCount
    );
    const GreeenCount = useSelector(
        (state) => state.cm.GreeenCount
    );

    const nf = new Intl.NumberFormat("en-BD");

    // Function to calculate the total Standard
    const calculateStandardTotal = () => {
        let standardTotal = 0;

        lwStandCMData.forEach((line) => {
            line.details.forEach((detail) => {
                const standardValue = parseFloat(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.itemWiseMixing / line.lineWIsestandardMixingSum) * 100) / 100);
                standardTotal += standardValue;
            });
        });

        return standardTotal.toFixed(2);
    };

    const handleClick = (e, index) => {
        e.preventDefault();
        setCurrentPage(index);
    };

    const pageCount = Math.ceil(lwStandCMData?.length / PER_PAGE);

    const dataDetails = (item) => {
        setItemDetailShow3(true);
        setItemifo3(item);
    };

    return (
        <div className="row container-fluid marginremoveCM paddingremove">
            <div className="card col-lg-12 position-relative">
                <div className="mb-0 mt-3">
                    <span className="button-33 float-right">CM in %</span>
                    <span className="button-127 float-right">CM in Value TK </span>
                </div>
                <span className="mt-4"></span>
                {isLoading && (
                    <span className="spineercolor mt-5 mb-5">
                        <LoadingSpinner />
                    </span>
                )}
                {!isLoading && (
                    <div className=" ">
                        <div className="">
                            <div className="react-bootstrap-table table-responsive border-0">
                                <table class="table table-vertical-center item-add-table borderTableims mb-5">
                                    <thead className="">
                                        <tr className="text-center p-5 tableheadingCM">
                                            <th className="CM0"><span className="CM1Sub1">Line</span></th>
                                            <th ></th>
                                            <th className="CM1"><span className="CM1Sub1"><span className="product">Product</span></span></th>
                                            <th className="CM1"><span className="CM1Sub1">Sales %</span></th>
                                            <th className="CM2"><span className="CM1Sub1">Standard</span></th>
                                            <th className="CM2"><span className="CM1Sub1">Actual</span></th>
                                            <th className="CM2"><span className="CM1Sub1">Difference</span></th>
                                            <th ></th>
                                            <th className="CM3"><span className="CM1Sub1">Standard</span></th>
                                            <th className="CM3"><span className="CM1Sub1">Actual</span></th>
                                            <th className="CM3"><span className="CM1Sub1">Difference</span></th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {lwStandCMData
                                            .map((line) => (
                                                line.details.map((detail, detailIndex) => {

                                                    return (
                                                        <>
                                                            <tr key={detail.productID}>
                                                                {detailIndex === 0 && (
                                                                    <td className="CM0 CM1FixedWidth p-5" rowSpan={line.details.length} style={{ verticalAlign: 'top' }}>
                                                                        <span className="CM1Sub4" >{line.lineName}</span>
                                                                        <div class="main">
                                                                            <label class="switch">
                                                                                <input type="radio" name="value-radio" />
                                                                                <div class="button ">
                                                                                    <div class="light"></div>
                                                                                    <div onClick={() => dataDetails(detailsArrayRed)} class="dots"><span className="text-light btnspanbody" >{ReduCount}</span></div>
                                                                                </div>
                                                                            </label>
                                                                            <span className="valuetitlesize">Actual CM is Less Than / Equal 5</span>
                                                                            <label class="switch1">
                                                                                <input type="radio" checked="" name="value-radio" />
                                                                                <div class="button">
                                                                                    <div class="light"></div>
                                                                                    <div onClick={() => dataDetails(detailsArrayYellow)} class="dots"><span className="text-light btnspanbody" >{YellowCount}</span></div>
                                                                                </div>
                                                                            </label>
                                                                            <span className="valuetitlesize">Actual CM is Less Than / Equal 10</span>
                                                                            <label class="switch2">
                                                                                <input type="radio" name="value-radio" />
                                                                                <div class="button">
                                                                                    <div class="light"></div>
                                                                                    <div onClick={() => dataDetails(detailsArrayGreen)} class="dots"><span className="text-light btnspanbodyGreen" >{GreeenCount}</span></div>
                                                                                </div>
                                                                            </label>
                                                                            <span className="valuetitlesize">Actual CM is Grater Than 10</span>
                                                                        </div>
                                                                    </td>
                                                                    
                                                                )}
                                                                <td style={{ padding: '0 3px' }}></td>
                                                                <td className="CM1 CM1FixedWidth" style={{ width: '290px' }}>
                                                                    {/* Add title attribute for hover */}
                                                                    <span className="CM1Sub2" title={detail.productName}>
                                                                        {detail.productName}
                                                                    </span>
                                                                </td>

                                                                <td className="CM1 CM1FixedWidth">
                                                                    {/* Sales % */}
                                                                    <span className="CM1Sub2">
                                                                        {parseFloat((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100).toFixed(2)}%
                                                                    </span>
                                                                </td>
                                                                {/* <td className="CM2 CM1FixedWidth">
                                                                   
                                                                    <span className="CM1Sub2">{parseFloat((detail.itemWiseMixing / line.lineWIsestandardMixingSum) * 100).toFixed(2)}%</span>
                                                                </td> */}
                                                                <td className="CM2 CM1FixedWidth">
                                                                    {/* Standard */}
                                                                    <span className={parseFloat((detail.itemWiseMixing)).toFixed(2) <= 0 ? "CM1Sub2Red" : "CM1Sub2" && parseFloat((detail.itemWiseMixing)).toFixed(2) <= 10 ? "CM1Sub2Yellow" : "CM1Sub2"}>
                                                                        {parseFloat((detail.itemWiseMixing)).toFixed(2)}%
                                                                    </span>

                                                                </td>
                                                                <td className="CM2 CM1FixedWidth">
                                                                    {/* Actual */}
                                                                    <span className={parseFloat(detail.actualCM).toFixed(2) <= 0 ? "CM1Sub2Red" : "CM1Sub2" && parseFloat((detail.actualCM)).toFixed(2) <= 10 ? "CM1Sub2Yellow" : "CM1Sub2"}>
                                                                        {parseFloat(detail.actualCM).toFixed(2)}%
                                                                    </span>
                                                                </td>
                                                                <td className="CM2 CM1FixedWidth">
                                                                    {/* Difference */}
                                                                    <span className="CM1Sub2">
                                                                        {parseFloat(detail.actualCM - detail.itemWiseMixing).toFixed(2)}%
                                                                    </span>
                                                                </td>
                                                                <td style={{ padding: '0 3px' }}></td>
                                                                <td className="CM3 CM1FixedWidth">
                                                                    <span className={parseFloat(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.itemWiseMixing)) / 100) <= 0 ? "CM1Sub2Red" : "CM1Sub2"}>
                                                                        {numberFormat(parseInt(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.itemWiseMixing)) / 100))}
                                                                    </span>
                                                                </td>
                                                                <td className="CM3 CM1FixedWidth">
                                                                    <span className={parseFloat(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.actualCM)) / 100) <= 0 ? "CM1Sub2Red" : "CM1Sub2"}>
                                                                        {numberFormat(parseInt(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.actualCM)) / 100))}
                                                                    </span>
                                                                </td>
                                                                <td className="CM3 CM1FixedWidth">
                                                                    <span className={parseFloat(
                                                                        numberFormat(parseInt(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.actualCM)) / 100) - parseInt(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.itemWiseMixing)) / 100))
                                                                    ) <= 0 ? "CM1Sub2Red" : "CM1Sub2"}>
                                                                        {numberFormat(parseInt(
                                                                            ((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.actualCM)) / 100) - parseInt(((line.lineWiseDeliveryVal * ((detail.itemWiseDeliveryVal / line.lineWiseDeliveryVal) * 100)) / 100) * ((detail.itemWiseMixing)) / 100)
                                                                        )}
                                                                    </span>
                                                                </td>

                                                            </tr>

                                                        </>
                                                    )
                                                })
                                            ))}
                                        <tr>
                                            <td className="CM0 CM1FixedWidth p-5" >
                                            </td>
                                            <td style={{ padding: '0 3px' }}></td>
                                            <td className="CM1 CM1FixedWidth"><span className="CM1Sub1">Total All Product Line Of </span></td>
                                            <td className="CM1 CM1FixedWidth"><span className="CM1Sub1">{(parseFloat(sumSales).toFixed(2))}%</span></td>
                                            <td className="CM2 CM1FixedWidth"></td>
                                            <td className="CM2 CM1FixedWidth"></td>
                                            <td className="CM2 CM1FixedWidth"></td>
                                            <td style={{ padding: '0 3px' }}></td>
                                            <td className="CM3 CM1FixedWidth"></td>
                                            <td className="CM3 CM1FixedWidth"></td>
                                            <td className="CM3 CM1FixedWidth"></td>
                                        </tr>

                                        <tr>
                                            <td className="CM0 CM1FixedWidth" >
                                            </td>
                                            <td style={{ padding: '0 3px' }}></td>
                                            <td className="CM1 CM1FixedWidth"><span className="CM1Sub1">Total All  </span></td>
                                            <td className="CM1 CM1FixedWidth"><span className="CM1Sub1">{(parseFloat(sumSalesCal).toFixed(2))}%</span></td>
                                            <td className="CM2 CM1FixedWidth"></td>
                                            <td className="CM2 CM1FixedWidth"></td>
                                            <td className="CM2 CM1FixedWidth"></td>
                                            <td style={{ padding: '0 3px' }}></td>
                                            <td className="CM3 CM1FixedWidth"></td>
                                            <td className="CM3 CM1FixedWidth"></td>
                                            <td className="CM3 CM1FixedWidth"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <ModalForColoredTable
                                size="xl"
                                show={itemDetailShow3}
                                handleClose={() => setItemDetailShow3(false)}
                                handleShow={() => setItemDetailShow3(true)}
                            >
                                <ColoredTableModal
                                    handleClose={() => setItemDetailShow3(false)}
                                    itemInfo={itemifo3}
                                />
                            </ModalForColoredTable>
                        </div>
                    </div>
                )}
            </div>
            
        </div>
    );
};

export default CMinPercentageColoredTable;