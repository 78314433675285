import React from "react";
import * as dt from "../TestTable/response_sale_order.json";

export const columns = [
  {
    name: "Name",
    selector: "name",
    sortable: true
  },
  {
    name: "Po Number",
    selector: "purchase_order_no",
    sortable: true
  },
  {
    name: "Quotation No",
    selector: "quotation_no",
    sortable: true
  },
  {
    name: "Rfq no",
    selector: "rfq_no",
    sortable: true
  },
  {
    name: "Order Line",
    cell: (rw) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {rw.order_line.map((item, index) => (
          <div key={index}>
            <p>
              {index + 1}.{item.name}
            </p>
          </div>
        ))}
      </div>
    )
  }
];

export const data = dt.data.result;
