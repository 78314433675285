import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';
import faker from 'faker';
import { Chart } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import YearToyearPaginatateDetails from './YearToyearPaginatateDetails';
import LoadingSpinner from '../Billing/Components/LoadingSpinner';


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);



const CMyeartoyearPaginationChart = () => {
    const actualSalesQtyDataYear = useSelector(
        (state) => state.cm.actualSalesQtyDataYear
    );
    const actualSalesValDataYear = useSelector(
        (state) => state.cm.actualSalesValDataYear
    );
    const cmDataYear = useSelector(
        (state) => state.cm.cmDataYear
    );
    const dataPointsPerPage = 5; 

    const fullName = useSelector(
        (state) => state.cm.fullNameYear
    );

    const isLoading = useSelector(
        (state) => state.cm.cmAnalysisYearLoading
    );

    return (<>
        <div className="">
            <h5 className="button-122 float-right">YTD</h5>
        </div>
        <div >
            {isLoading ? (
                <span className="spineercolor mt-5 mb-5 text-center">
                    <LoadingSpinner className="text-center" />
                </span>
            ) : (

                <div className='mt-38'>

                    <YearToyearPaginatateDetails
                        data={actualSalesQtyDataYear}
                        lineData={actualSalesValDataYear}
                        areaData={cmDataYear}
                        dataPointsPerPage={dataPointsPerPage}
                        fullLabels = {fullName}

                    />
                </div>
            )}
        </div>
    </>

    );
}
export default CMyeartoyearPaginationChart
