
// Action Types
const SET_VIEW_MODE = 'SET_VIEW_MODE';
const SET_TITLE = 'SET_TITLE';
const SET_RANGE_VALUES = 'SET_RANGE_VALUES';

// Action Creators
export const setViewMode = () => ({
    type: SET_VIEW_MODE,
});

export const setTitle = (title) => ({
    type: SET_TITLE,
    payload: title,
});

export const setRangeValues = (values) => ({
    type: SET_RANGE_VALUES,
    payload: values,
});

// Initial State
const initialState = {
    viewMode: localStorage.getItem("viewMode") || "map",
    title: null,
    rangeValues: {
        best: { min: 100, max: "" },
        good: { min: 90, max: 99 },
        bad: { min: 80, max: 89 },
        worst: { min: 0, max: 79 },
    },
};

// Reducer
const MapReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VIEW_MODE:
            const newMode = state.viewMode === "map" ? "data" : "map";
            localStorage.setItem("viewMode", newMode);
            return { ...state, viewMode: newMode };
        case SET_TITLE:
            return { ...state, title: action.payload };
        case SET_RANGE_VALUES:
            return {
                ...state, rangeValues: {
                    ...state.rangeValues,
                    [action.payload.rangeName]: action.payload.newValues
                }
            };
        default:
            return state;
    }
};

export default MapReducer;
