import * as Types from "./DashboardType";
import { numberFormat } from "./Money";
const initialState = {
    actualStockLoading: false,
    actualStock:[],
    productionStock:[],
    targetProduction: 0,
    TotalDays:0,
};

const ProductionLineUpdateReducer = (state = initialState, action) => {
  switch (action.type) {

    case Types.GET_PRODUCTION_LINE_DATA_UPDATED:

        // actual stock start
      var conArray = [];
      const dateWiseActualStock = action.payload.data?.actualStock;
      if (dateWiseActualStock && Array.isArray(dateWiseActualStock)) {
        for (let i = 0; i < dateWiseActualStock.length; i++) {
          const closing = dateWiseActualStock[i].closing;
          const formattedQty = numberFormat(closing);
          // Store both the original number and its formatted representation
          conArray.push({ original: closing, formatted: formattedQty });
        }
      }

       // actual stock end

        // actual production start

      var prodArray = [];
      const dateWiseProductionStock = action.payload.data?.actualProduction;

      for (let i = 0; i < dateWiseProductionStock?.length; i++) {
        prodArray.push(dateWiseProductionStock[i].prodQty);
      }

       // actual production end

      return {
        ...state,
        actualStockLoading: action.payload.isLoading,
        actualStock: conArray,
        productionStock: prodArray,
        targetProduction: action.payload.data?.targetProduction,
        TotalDays: action.payload.data?.totalDays,
        stockPosition : action.payload.data?.stockPosition,
        averageSales : action.payload.data?.averageSales

      };
    
    default:
      return {
        ...state
      };
  }
};
export default ProductionLineUpdateReducer;
