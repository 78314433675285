import Axios from "axios";
import * as Types from "./DashboardType";



export const GetCurrentMonthCMAnalaysis = (Fdate, Tdate, productionLine, ProductId, ConditionId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_CM_ANALYSIS_MONTOMON_DATA,
        payload: responseList,
    });
    let url = "";
    url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCurrentMonthCMAnalaysis?`;

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }
    if (productionLine !== null) {
        url += `&ProductionLine=${productionLine}`;
    }
    if (ProductId !== null) {
        url += `&ProductId=${ProductId}`;
    }
    if (ConditionId !== null) {
        url += `&conditionID=${ConditionId}`;
    }

    await Axios.get(url).then((res) => {
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.isLoading = false;
    });
    dispatch({
        type: Types.GET_CM_ANALYSIS_MONTOMON_DATA,
        payload: responseList,
    });
};
export const GetCurrentYearCMAnalaysis = (Fdate, Tdate, productionLine, ProductId, ConditionId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_CM_ANALYSIS_YEAR_DATA,
        payload: responseList,
    });
    let url = "";
    url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCurrentYearCMAnalaysis?`;

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }
    if (productionLine !== null) {
        url += `&ProductionLine=${productionLine}`;
    }
    if (ProductId !== null) {
        url += `&ProductId=${ProductId}`;
    }
    if (ConditionId !== null) {
        url += `&conditionID=${ConditionId}`;
    }
 

    await Axios.get(url).then((res) => {
       
        responseList.data = res.data.data == null ? [] : res.data.data;
        responseList.isLoading = false;
    });
    dispatch({
        type: Types.GET_CM_ANALYSIS_YEAR_DATA,
        payload: responseList,
    });
};

export const GetLineWiseStandardCM = (Fdate, Tdate, productionLine, ProductId, ConditionId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_LINE_WISE_STANDARD_CM,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetLineWiseStandardCM?`;

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }
    if (productionLine !== null) {
        url += `&ProductionLine=${productionLine}`;
    }
    if (ProductId !== null) {
        url += `&ProductId=${ProductId}`;
    }
    if (ConditionId !== null) {
        url += `&conditionID=${ConditionId}`;
    }
    console.log('3rd url :>> ', url);
    try {
        const response = await Axios.get(url);
        console.log('3rd res :>> ', response);
        responseList.data = response.data?.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_LINE_WISE_STANDARD_CM,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const GetCmTrendsinceBeginning = (Fdate, Tdate, productionLine, ProductId, ConditionId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_CM_TRAND_SINCE_BEGINING,
        payload: responseList,
    });
    let url = "";
    url = `${process.env.REACT_APP_API_PUBLIC_URL}CmTrendsinceBeginningForChan?`;

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }
    if (productionLine !== null) {
        url += `&ProductionLine=${productionLine}`;
    }
    if (ProductId !== null) {
        url += `&ProductId=${ProductId}`;
    }
    if (ConditionId !== null) {
        url += `&conditionID=${ConditionId}`;
    }
 

    await Axios.get(url).then((res) => {
       console.log('res', res);
       responseList.data = res.data?.data == null ? [] : res.data?.data;
        responseList.isLoading = false;
    });
    dispatch({
        type: Types.GET_CM_TRAND_SINCE_BEGINING,
        payload: responseList,
    });
};

export const GetRecipeList = (ProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    let url = "";
    url = `${process.env.REACT_APP_API_PUBLIC_URL}GetRecipeList?ProductId=${ProductId}`;


    await Axios.get(url).then((res) => {
       console.log('res', res);
       responseList.data = res.data?.data == null ? [] : res.data?.data;
        responseList.isLoading = false;
    });
    dispatch({
        type: Types.GET_RECIPE_LIST,
        payload: responseList,
    });
};