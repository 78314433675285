import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import BillingReducer from "./Billing/reducers/BillingReducer";
import AccountsReducer from "../modules/Accounts/redux/reducers/AccountsReducer";
import DashboardCartReducer from "../modules/Cfo/DashboardCartReducer";
import ProductionLineReducer from "../modules/Cfo/ProductionLineReducer";
import PrimarySalesTrandReducer from "../modules/Cfo/PrimarySalesTrandReducer";
import ProductionLineUpdateReducer from "../modules/Cfo/ProductionLineUpdateReducer";
import CMAnalysisReducer from "../modules/Cfo/CMAnalysisReducer";
import authMenuPermissionReducer from "../_metronic/layout/components/aside/aside-menu/authMenuPermissionReducer";
import MapReducer from "../Map/store/MapReducer";
import MapReducerData from "../Map/Redux/MapReducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  billing:BillingReducer,
  accounts:AccountsReducer,
  dashboardcart :DashboardCartReducer,
  productionLine:ProductionLineReducer,
  primarysalestrand:PrimarySalesTrandReducer,
  updateProductionLine:ProductionLineUpdateReducer,
  cm:CMAnalysisReducer,
  menu: authMenuPermissionReducer,
  map:MapReducer,
  mapData: MapReducerData
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
