import React from 'react'
import {
    TransformWrapper,
    TransformComponent,
    useControls,
  } from "react-zoom-pan-pinch";
  import { FaPlus } from "react-icons/fa6";
  import { FaMinus } from "react-icons/fa6";
  import { GrPowerReset } from "react-icons/gr";

const ZoomEffect = ({children}) => {

    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
          <div className="map-zoom-buttons">
            <button
              className="btn btn-primary btn-sm mr-1"
              onClick={() => zoomIn()}
            >
              <FaPlus />
            </button>
            <button
              className="btn btn-primary btn-sm mr-1"
              onClick={() => zoomOut()}
            >
              <FaMinus />
            </button>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => resetTransform()}
            >
              <GrPowerReset />
            </button>
          </div>
        );
      };

  return (
    <TransformWrapper>
        <Controls />
        <TransformComponent>
            {children}
        </TransformComponent>
    </TransformWrapper>
  )
}

export default ZoomEffect