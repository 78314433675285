import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { GetProductionLine, GetProductionLineByProduction } from "./DashboardCartAction";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import RecipesModal from "./RecipesModal";
import SimpleModal from "./SimpleModal";
import { GetCmTrendsinceBeginning } from "./CMAnalysisAction";

const DemoLineRecharts = () => {
    const chartRef = useRef(null);
    const { register, handleSubmit, errors, setFocus, setValue } = useForm();
    const dispatch = useDispatch();
    const productionLine = useSelector((state) => state.dashboardcart.productionLine);
    const proLinebyPro = useSelector((state) => state.dashboardcart.proLinebyPro);
    const [selectProductType, setSelectProductType] = useState(0);
    console.log('selectProductType', selectProductType)

    const [selectProductionLine, setSelectProductionLine] = useState(0);
    const [itemDetailShow3, setItemDetailShow3] = useState(false);
    const [itemifo3, setItemifo3] = useState(null);
    const [itemID, setItemID] = useState(null);
    const [selectProductName, setSelectProductName] = useState(null);

    const CMtrandData = useSelector((state) => state.cm.CMtrandData);

    console.log('CMtrandData', CMtrandData);


    const TradeOfferPriceData = useSelector((state) => state.cm.TradeOfferPriceData);
    const isLoading = useSelector((state) => state.cm.CMTrandDataLoading);
    const dateData = useSelector((state) => state.dashboardcart.dateDatadetails);

    const formatCmx = cmx => {
        const crore = cmx;
       
        return `${parseFloat(crore).toFixed(2)}`;
    };

    const RecipesDetails = (datum) => {

        setItemDetailShow3(true);
        setItemifo3(null);
        setItemID(selectProductType);
    };

    useEffect(() => {
        initialData();
    }, []);

    const initialData = async () => {
        dispatch(GetProductionLine());
    };
    var d = new Date();
    var firstD =
        d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    var currentD =
        d.getFullYear() +
        "-" +
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + d.getDate()).slice(-2) +
        "";

    useEffect(() => {
        if (CMtrandData.length > 0) {
            renderChart();
        }
    }, [CMtrandData]);

    const renderChart = () => {
        const data = CMtrandData;
        console.log('data', data);
        const margin = { top: 20, right: 20, bottom: 60, left: 60 };
        const width = 1200 - margin.left - margin.right;
        const height = 250 - margin.top - margin.bottom;








        // Clear any existing SVG content before appending a new chart
        d3.select(chartRef.current).selectAll("svg").remove();

        const svg = d3.select(chartRef.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        const xScale = d3.scaleBand()
            .domain(data.map(d => d.month))
            .range([0, width])
            .padding(0.1);

        const yScale = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.cmx)])
            .nice()
            .range([height, 0]);

        // Define the gradient
        const gradient = svg.append("defs")
            .append("linearGradient")
            .attr("id", "bar-gradient")
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "0%")
            .attr("y2", "100%");

        // Define the gradient stops (colors and positions)
        gradient.append("stop")
            .attr("offset", "0%")
            .attr("style", "stop-color: #088da5"); // Start color
        gradient.append("stop")
            .attr("offset", "100%")
            .attr("style", "stop-color: #40e0d0"); // End color


        svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("x", d => xScale(d.month))
            .attr("y", d => yScale(d.cmx))
            .attr("width", xScale.bandwidth())
            .attr("height", d => height - yScale(d.cmx))
            .attr("fill", "url(#bar-gradient)")
            ;


    var tooltip = d3.select('.tooltip-area')
        .style('opacity', 0);

      const mouseover = (event, d) => {
        tooltip.style("opacity", 1);
      };

      const mouseleave = (event, d) => {
        // tooltip.style('opacity', 0);
      }

      const mousemove = (event, d,index) => {
        alert(event);
        alert(d);
        console.log('d', d);
        console.log('d', d?.tradeOfferPrice);
        console.log('event', event);
   
        const text = d3.select('.tooltip');
        text.text(`Sales were ${d?.tradeOfferPrice} in ${d?.tradeOfferPrice}`);
        const [x, y] = d3.pointer(event);

        console.log('x', x);
        console.log('y', y);

        tooltip
          .attr('transform', `translate(${x}, ${y})`);
      };
           
            
            

        data.forEach(datum => {

            console.log('datum', datum);

            svg.append("line")
                .attr("x1", xScale(datum.month) + xScale.bandwidth() / 2)
                .attr("y1", yScale(datum.cmx))
                .attr("x2", xScale(datum.month) + xScale.bandwidth() / 2)
                .attr("y2", height)
                .attr("stroke", "#ffff")
                .attr("stroke-width", 3);

            svg.append("text")
                .attr("x", xScale(datum.month) + xScale.bandwidth() / 2)
                .attr("y", yScale(datum.cmx) - 5)
                .attr("text-anchor", "middle")
                .style("font-size", "12px")
                .style("fill", "#ffff")
                .text(formatCmx(datum.cmx));

            svg.append("text")
                .attr("x", xScale(datum.month) + xScale.bandwidth() / 2)
                .attr("y", height + margin.top + 1)
                .style("text-anchor", "middle")
                .style("font-size", "12px")
                .style("fill", "#ffff")
                .text(`${datum.per}%`)
                .on("click", () => {
                    RecipesDetails(datum);
                });
                ;

            // Add onClick event to the backgroundRect element and call RecipesDetails
            const backgroundRect = svg.insert("rect", "text") // Insert before the text element
                .attr("x", xScale(datum.month) + xScale.bandwidth() / 2 - 22) // Adjust as needed
                .attr("y", height + margin.top - 12) // Adjust as needed
                .attr("width", 44) // Adjust as needed
                .attr("height", 18) // Adjust as needed
                
                //.style("fill", datum.per === 8|| datum.per === 10 || datum.per === 12 || datum.per === 14 || datum.per === 13 ? "#f8905a" : "#8486ce")
                .style("fill", datum.isReceipieOptimize === 1 ? "#f8905a" : "#8486ce")
                // .style("fill",  "#f8905a") // Change the fill color
                // .on("click", () => {
                //     RecipesDetails(datum);
                // });
        });

        const lineGenerator = d3.line()
            .x(d => xScale(d.month) + xScale.bandwidth() / 2)
            .y(d => yScale(d.cmx))
            .defined(d => d.cmx == d.cmx);


        // Define a function to add data labels
        const addDataLabels = (svg, data, dataKey) => {
            svg.selectAll(".data-label")
                .data(data)
                .enter()
                .append("text")
                .attr("class", "data-label")
                .attr("x", d => xScale(d.month) + xScale.bandwidth() / 2)
                .attr("y", d => yScale(d[dataKey]) - 10) // Adjust the y-position for label placement
                .style("text-anchor", "middle")
                .style("font-size", "12px")
                .style("fill", "#076D44")
                .text(d => formatCmx(d[dataKey])); // Use formatCmx to format the value
        };

        // Inside the renderChart function, after appending the green line chart:
        const lineGenerator2 = d3.line()
        
            .x(d => xScale(d?.month) + xScale.bandwidth() / 2)
            .y(d => yScale(d.tradeOfferPrice))
            .defined(d => d.tradeOfferPrice == d.tradeOfferPrice);


        // Append path element for the second line chart
        svg.append("path")
            .datum(CMtrandData)
            .attr("class", "line2")
            //.attr("d", lineGenerator2)
            .attr("d",lineGenerator2)
         
            .attr("fill", "none")
            .attr("stroke", "green")
            .attr("stroke-width", 3)
            .attr("stroke-dasharray", "7,7")
            // .on("mousemove", (d, i) => {mousemove(d,i)})
            // .on("mouseleave", mouseleave)
            // .on("mouseover", mouseover);
            ;









            



        // Green Call the addDataLabels function after it's defined
        addDataLabels(svg, CMtrandData, "tradeOfferPrice");


        svg.append("path")
            .datum(data)
            .attr("class", "line")
            .attr("d", lineGenerator)
            .attr("fill", "none")
            .attr("stroke", "#ffff")
            .attr("stroke-width", 3)
            .attr("stroke-dasharray", "7,7")
            ;
            
           
        svg.selectAll(".x-label")
            .data(data)
            .enter()
            .append("text")
            .attr("class", "x-label")
            .attr("x", d => xScale(d.month) + xScale.bandwidth() / 2)
            .attr("y", height + margin.top + 20)
            .style("text-anchor", "middle")
            .text(d => d.month);

        svg.selectAll(".y-label")
            .data(data)
            .enter()
            .append("text")
            .attr("class", "y-label")
            .attr("x", 0 - margin.left)
            .attr("y", d => yScale(d.cmx))
            .attr("dy", "0.4em")
            .style("text-anchor", "end")
            .text(d => formatCmx(d.cmx));

    };

    return <>
        <div className="cmCard  ">
            <div className="App card react-bootstrap-table table-responsive border-0">
                {
                    // CMtrandData.length > 0 &&
                    <>

                        <div className="form-group row CM-card-4TH cm4thchartmarginremove">
                            <div className="col-lg-3">
                           
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">Production Line</label>
                                    <RHFInput
                                        className="formSelect pt-0 "
                                        as={<Select options={productionLine} />}
                                        rules={{ required: false }}
                                        name="productionLine"
                                        register={register}
                                        placeholder="Select Production Line"
                                        isClearable
                                        onChange={(option) => {
                                            //handleChange("productionLine", option?.value);
                                            setSelectProductionLine(option?.value);
                                            setValue("productType", "");
                                            dispatch(GetProductionLineByProduction(option?.value, dateData?.startDate, dateData?.toDate));
                                            dispatch(
                                                GetCmTrendsinceBeginning(
                                                    dateData?.startDate,
                                                    dateData?.toDate,
                                                    option?.value,
                                                )
                                            );
                                        }}
                                        setValue={setValue}
                                    />
                                </Form.Group>
                            </div>
                            <div className="col-lg-3 mb-5">
                                <Form.Group as={Col} controlId="formGridState">
                                    <label className="form-label">SKU</label>
                                    <RHFInput
                                        className="formSelect pt-0 "
                                        as={<Select options={proLinebyPro} />}
                                        rules={{ required: false }}
                                        name="productType"
                                        register={register}
                                        placeholder="Select Product type"
                                        isClearable
                                        onChange={(option) => {
                                            //handleChange("productType", option?.value);
                                            setSelectProductType(option?.value);
                                            setSelectProductName(option?.label);
                                            dispatch(
                                                GetCmTrendsinceBeginning(
                                                    dateData?.startDate,
                                                    dateData?.toDate,
                                                    selectProductionLine,
                                                    option?.value,
                                                )
                                            );
                                        }}
                                        setValue={setValue}
                                    />
                                </Form.Group>
                            </div>

                        </div>
                    </>
                }

                {isLoading ? (
                    <span className="spineercolor mt-5 mb-5 text-center">
                        <LoadingSpinner className="text-center" />
                    </span>
                ) : (

                    <>
                        <div className="">
                            <div className="CM-card text-center pb-3 treading" ref={chartRef}>
                            <g className="tooltip-area">
          <text className="tooltip">aas</text>
        </g>
                                <div className=' p-5'>
                                    {
                                        CMtrandData.length > 0 &&

                                        <div className="cm4thlegand">
                                            <div className="column-container">
                                                <div className="column-item">
                                                    <h5 className="button-1001">Sales</h5>
                                                </div>
                                                <div className="column-item">
                                                    <h5 className="button-1002">Trade Offer</h5>
                                                </div>
                                                <div className="column-item">
                                                    <h5 className="button-1003">Contribution Margin</h5>
                                                </div>
                                                {/* <div className="column-item">
                                                    <h5 className="button-1004">Receipie Optimaization</h5>
                                                </div> */}
                                                <div className="column-item mt-5">
                                                    <span className="core-text">Core</span>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                                <h5 className="mb-3" >CM% TREND SINCE THE BEGINNING</h5>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <SimpleModal
                size="xl"
                show={itemDetailShow3}
                handleClose={() => setItemDetailShow3(false)}
                handleShow={() => setItemDetailShow3(true)}
            >
                <RecipesModal
                    handleClose={() => setItemDetailShow3(false)}
                    itemID3={selectProductType}
                    ItemName={selectProductName}
                />
            </SimpleModal>

            <h6 className="text-center p-3"><b>Note:</b>  Last recipe optimization or any CM changes decision must be reflected in the above graph in different popup. Details must be there</h6>
        </div>
    </>
};

export default DemoLineRecharts;
