import Axios from "axios";
import * as Types from "./DashboardType";


export const getZoneList = async (name, value) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZone`;
    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;

      })

      .catch(function (error) {

      });
    // responseList.data.unshift(objectVar)
  } catch (error) {

  }
  return responseList;
};
export const getZoneListEntry = async (name, value) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };

  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllZoneEntry`;
    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;

      })

      .catch(function (error) {

      });
    // responseList.data.unshift(objectVar)
  } catch (error) {

  }
  return responseList;
};

export const getDivisionListByZone = async (zoneId, type = 0) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllRegionByZone?zoneId=${zoneId}&type=${type}`;

    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;
      })
      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};
export const getDivisionListByZoneEntry = async (zoneId, type = 0) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllRegionByZoneForEntry?zoneId=${zoneId}&type=${type}`;

    await Axios
      .get(geturl)
      .then(function (response) {
        console.log('divisionewewe',response);
        responseList.data = response.data.data;
      })
      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};
export const getAreaListByDivision = async (divisinId, type = 0) => {
  console.log('type',type);
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllAreaByDivision?divisionId=${divisinId}&type=${type}`;


    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;
      })
      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};
export const getAreaListByDivisionEntry = async (divisinId, type = 0) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllAreaByDivisionEntry?divisionId=${divisinId}&type=${type}`;


    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;
      })
      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};
export const getTerritory = async (areaId, type) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllTerritoryBYArea?AreaId=${areaId}&type=${type}`;


    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;
      })
      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};
export const getTerritoryEntry = async (areaId, type) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/GeoSetup/GetAllTerritoryBYAreaEntry?AreaId=${areaId}&type=${type}`;



    await Axios
      .get(geturl)
      .then(function (response) {

        responseList.data = response.data.data;
      })
      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};

export const getDistributorAddress = async (distributionId) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetDistributorDeliveryAddress?DistribID=${distributionId}`;


    await Axios
      .get(geturl)
      .then(function (response) {


        responseList.data = response.data.data;
      })
      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};
export const getSalesDrigData = async () => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}GetTEst?ZoneId=0&DivisionID=0&Area=0`;


    await Axios
      .get(geturl)
      .then(function (response) {

        responseList.data = response.data.data;
      })
      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};


export const getDistributorReport = (areaId, fromDate, salesTypeId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  dispatch({ type: Types.GET_DISTRIBUTOR_DAILY_BALANCE, payload: responseList });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDailyBR?unitId=98&AreaID=${areaId}&DateT=${fromDate}&DistribCat=${salesTypeId}`;
  await Axios.get(url).then((res) => {

    responseList.data = res.data.data != null ? res.data.data : [];
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_DAILY_BALANCE, payload: responseList });
};


// export const getDistributorBrReport = (areaId,territoryId,fromDate,toDate,distribCatId) => async (dispatch) => {
//   let responseList = {
//     isLoading: true,
//     data:[],
//     status: true,
//   };
//   dispatch({ type: Types.GET_DISTRIBUTOR_DAILY_BALANCE, payload: responseList });
//   let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryandBR?unitId=98&AreaID=${areaId}&TerritoryId=${territoryId}&FromDate=${fromDate}&ToDate=${toDate}&DistribCat=${distribCatId}`;
//     await Axios.get(url).then((res) => {
//     responseList.data = res.data.data;
//     responseList.status = false;
//     responseList.isLoading = false;
//   });
//   dispatch({ type: Types.GET_DISTRIBUTOR_DAILY_BR_BALANCE, payload: responseList });
// };


export const getDistributorBrReport = (zoneId, divisionId, areaId, territoryId, fromDate, toDate, distribCatId) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: [],
    status: true,
  };
  dispatch({ type: Types.GET_DISTRIBUTOR_DAILY_BALANCE, payload: responseList });

  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}GetCustomerDeliveryandBR?`;
  console.log('url :>> ', url);

  if (zoneId !== null) {

    url += `&ZoneID=${zoneId}`
  }
  if (divisionId !== null) {

    url += `&DivisionID=${divisionId}`
  }
  if (areaId !== null) {
    url += `&AreaID=${areaId}`
  }
  if (territoryId !== null) {
    url += `&TerritoryId=${territoryId}`
  }
  if (fromDate !== null) {

    url += `&FromDate=${fromDate}`
  }
  if (toDate !== null) {

    url += `&ToDate=${toDate}`
  }
  if (distribCatId !== null) {

    url += `&DistribCat=${distribCatId}`
  }
  await Axios.get(url).then((res) => {
    console.log('dis-res :>> ', res);
    responseList.data = res.data.data == null?[]:res.data.data;
    responseList.status = false;
    responseList.isLoading = false;
  });
  dispatch({ type: Types.GET_DISTRIBUTOR_DAILY_BR_BALANCE, payload: responseList });
};

export const areaDataSelect = (areaId) => async (dispatch) => {
  dispatch({ type: Types.CHANGE_AREA_SELECT, payload: areaId });
};
export const getdropdownproductList = async (salesType) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}api/RemoteOrder/GetSubSalesItemPricebyUnit?unitId=98&DistribCat=${salesType}`;
    console.log('geturl', geturl);
    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;

      })

      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};
export const getSoList = async (terrioryId) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: false,
  };
  try {
    let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}GetSOPointListbyAreaForDashboard?TerritoryId=${terrioryId}`;
    console.log('geturl', geturl);
    await Axios
      .get(geturl)
      .then(function (response) {
        responseList.data = response.data.data;

      })

      .catch(function (error) {

      });
  } catch (error) {

  }
  return responseList;
};


