import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { numberFormat } from "./Money";

const PER_PAGE = 13;

const ProductionStockMonitoringGraph2 = () => {
  const productLineTableData = useSelector((state) => state.productionLine.productLineTableData);
  console.log('productLineTableData :>> ', productLineTableData);
  const isLoading = useSelector((state) => state.productionLine.toProductionTableLoading);

  const [currentPage, setCurrentPage] = useState(1);

  let nf = new Intl.NumberFormat("en-BD");

  const handleClick = (e, index) => {
    e.preventDefault();
    console.log('index', index);
    setCurrentPage(index);
  };

  const pageCount = Math.ceil(productLineTableData?.productWiseData?.length / PER_PAGE);
  const isLastPage = currentPage === pageCount;

  return (
    <div className="card ProductionLineTable">
      <div className="text-center bg-success pdfBGC pt-2 text-white rounded-top">
        <h5 className="pdfFontC">Production & Stock Monitoring</h5>
      </div>
      {isLoading && (
        <span className="spineercolor mt-5 mb-5">
          <LoadingSpinner />
        </span>
      )}
      <>
        {!isLoading && (
          <div>
            <div className="text-center pt-2">
              <h6>Net Stock Position</h6>
            </div>
            <div className="salesMonitoringTable">
              <table>
                <thead className="tableHeading">
                  <tr className="text-center">
                    <th className="p-1">Production Line</th>
                    <th>SKU</th>
                    <th>Available Stock</th>
                    <th>Avg Month Sales</th>
                    <th>CM Target Sales</th>
                    <th>Sales To date</th>
                    <th>Factory Required Stock</th>
                  </tr>
                </thead>
                <tbody className="border">
                  {productLineTableData.productWiseData &&
                    productLineTableData.productWiseData
                     .slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
                      .map((d) => (
                        <tr key={d.productName}>
                          <td>{d.category}</td>
                          <td>{d.productName}</td>
                          <td>{numberFormat(d.avaibleStock)}</td>
                          <td>{numberFormat(d.avgMonthTo)}</td>
                          <td>{numberFormat(d.cmTargtQty)}</td>
                          <td>{numberFormat(d.salesToDate)}</td>
                          <td className={(d.salesToDate-d.cmTargtQty)+d.avaibleStock<0?`overandunder`:null}>{numberFormat((d.salesToDate-d.cmTargtQty)+d.avaibleStock)}</td>
                          {/* <td className={d.overAndUnder<0?`overandunder`:null}>{numberFormat(d.salesToDate-d.cmTargtQty)+d.avaibleStock}</td> */}
                        </tr>
                      ))}

                  {isLastPage && (
                    <tr className="nettotalbg">
                      <td>Total</td>
                      <td></td>
                      <td>{numberFormat(productLineTableData.overallTotalAvailableStock)}</td>
                      <td>{numberFormat(productLineTableData.overallTotalAvgMonthTo)}</td>
                      <td>{numberFormat(productLineTableData.overallTotalCMTargtQty)}</td>
                      <td>{numberFormat(productLineTableData.overallTotalSalesToDate)}</td>
                      <td>{numberFormat(productLineTableData.overallTotalOverAndUnder)}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="pagination mb-2">
                {Array.from({ length: pageCount }, (_, i) => {
                  if (i === 0) {
                    return (
                      <a
                        href="#"
                        key={i}
                        onClick={(e) => handleClick(e, i + 1)}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        {i + 1}
                      </a>
                    );
                  } else if (i === pageCount - 1) {
                    return (
                      <a
                        href="#"
                        key={i}
                        onClick={(e) => handleClick(e, i + 1)}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        {i + 1}
                      </a>
                    );
                  } else if (
                    i === currentPage - 1 ||
                    i === currentPage ||
                    i === currentPage + 1 ||
                    i === pageCount - 2
                  ) {
                    return (
                      <a
                        href="#"
                        key={i}
                        onClick={(e) => handleClick(e, i + 1)}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        {i + 1}
                      </a>
                    );
                  } else if (i === pageCount - 3) {
                    return (
                      <span key={i} className="dots">
                        ...
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ProductionStockMonitoringGraph2;
