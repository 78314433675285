export const CHANGE_HANDLE_DATE_MANAGE_FOR_DASHBOARD = "CHANGE_HANDLE_DATE_MANAGE_FOR_DASHBOARD";
export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const ABL_MONTH_WISE_DATA_FOR_DASHBOARD = "ABL_MONTH_WISE_DATA_FOR_DASHBOARD";
export const CHANGE_PRODUCT_INPUT_REPORT = "CHANGE_PRODUCT_INPUT_REPORT";
export const GET_CATEGORY_TYPE = "GET_CATEGORY_TYPE";
export const GET_PRODUCT_TYPE = "GET_PRODUCT_TYPE";
export const GET_NSM_TYPE = "GET_NSM_TYPE";
export const GET_DSM_TYPE = "GET_DSM_TYPE";
export const GET_RM_TYPE = "GET_RM_TYPE";
export const GET_TSM_TYPE = "GET_TSM_TYPE";
export const ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD = "ABL_SALES_SINCE_INCEPTION_FOR_DASHBOARD";
export const GET_LINE_TYPE = "GET_LINE_TYPE";
export const GET_SALES_CHANNEL_TYPE = "GET_SALES_CHANNEL_TYPE";
export const ABL_PRIMARY_SALES_TRAND = "ABL_PRIMARY_SALES_TRAND";
export const CHANGE_START_END_DATE = "CHANGE_START_END_DATE";

//from dristributor

export const CHANGE_ITEM_INPUT = "CHANGE_ITEM_INPUT";
export const MULTIPLE_ITEM_ADD_INPUT = "MULTIPLE_ITEM_ADD_INPUT";
export const DELETE_MULTIPLE_ITEM = "DELETE_MULTIPLE_ITEM";
export const GET_UOM = "GET_UOM";
export const GET_ITEM_TYPE = "GET_ITEM_TYPE";
export const GET_ITEM_CATEGORY = "GET_ITEM_CATEGORY";
export const GET_ITEM_SUB_CATEGORY = "GET_ITEM_SUB_CATEGORY";
export const GET_ITEM_LIST = "GET_ITEM_LIST";
export const ITEM_SUBMIT = "ITEM_SUBMIT";
export const EMPTY_MULTIPLE_ITEM_LIST = "EMPTY_MULTIPLE_ITEM_LIST";
export const DELETE_TITEM = "DELETE_TITEM";
// export const AFTER_SUCCESSFULLY_INSERT = "AFTER_SUCCESSFULLY_INSERT";

//get item details
export const GET_ITEMS_DETAILS = "GET_ITEMS_DETAILS";

//edit items 
export const ITEM_EDIT = "ITEM_EDIT";
export const ITEM_EDITTING = "ITEM_EDITTING";


// Sales type start here
export const CHANGE_ZONE_INPUT = "CHANGE_ZONE_INPUT";
export const ZONE_SAVE = "ZONE_SAVE";
export const ZONE_SUBMIT_STATUS_REMOVE = "ZONE_SUBMIT_STATUS_REMOVE";
export const GET_ZONE_LIST = "GET_ZONE_LIST";
export const GET_DISTRIBUTOR_LIST = "GET_DISTRIBUTOR_LIST";
export const GET_PRODUCT_SELECTED = "GET_PRODUCT_SELECTED";
export const GET_PRODUCT_DELETE = "GET_PRODUCT_DELETE";
export const GET_SALES_PRODUCT_LIST = "GET_SALES_PRODUCT_LIST";
export const GET_SEARCH_DATA_INPUT = "GET_SEARCH_DATA_INPUT";
export const CHANGE_PRODUCT_QUANTITY = "CHANGE_PRODUCT_QUANTITY";
export const CHANGE_PRODUCT_INPUT = "CHANGE_PRODUCT_INPUT";
export const SALES_PRODUCT_SUBMIT = "SALES_PRODUCT_SUBMIT";
export const GET_CUSTOMER_BALANCE = "GET_CUSTOMER_BALANCE";
export const GET_SALES_ORDER_DETAILS = "GET_SALES_ORDER_DETAILS";
export const GET_SALES_ORDER_UPDATE_DETAILS = "GET_SALES_ORDER_UPDATE_DETAILS";
export const CHANGE_PRODUCT_UPDATE_QUANTITY = "CHANGE_PRODUCT_UPDATE_QUANTITY";
export const DELETE_SALES_TITEM = "DELETE_SALES_TITEM"; 


export const GET_DISTRIBUTOR_ZONE_LIST = "GET_DISTRIBUTOR_ZONE_LIST";
export const CHANGE_AREA_SELECT = "CHANGE_AREA_SELECT";
export const GET_DISTRIBUTOR_DAILY_BALANCE = "GET_DISTRIBUTOR_DAILY_BALANCE";
export const GET_DISTRIBUTOR_DAILY_BR_BALANCE = "GET_DISTRIBUTOR_DAILY_BR_BALANCE";
export const GET_SALES_PRODUCT_DEMAND_LIST = "GET_SALES_PRODUCT_DEMAND_LIST";
export const GET_CUSTOMER_PRODUCT_DELIVERY = "GET_CUSTOMER_PRODUCT_DELIVERY";
export const GET_CUSTOMER_PROD_DETAILS = "GET_CUSTOMER_PROD_DETAILS";
export const GET_SALES_LIST_EDIT_DATA = "GET_SALES_LIST_EDIT_DATA";
export const PRODUCT_SUBMIT_STATUS_REMOVE = "PRODUCT_SUBMIT_STATUS_REMOVE";
export const SALES_PRODUCT_UPDATE = "SALES_PRODUCT_UPDATE";
export const CLEAR_REMOTE_SALES_DATA = "CLEAR_REMOTE_SALES_DATA";
export const GET_SALES_APPROVED_SALES_REPORT = "GET_SALES_APPROVED_SALES_REPORT";
export const GET_SALES_APPROVED_DETAILS = "GET_SALES_APPROVED_DETAILS";
export const GET_PROMOTIONAL_LIST_DETAILS = "GET_PROMOTIONAL_LIST_DETAILS";


export const GET_SO_LIST_BY_AREA = "GET_SO_LIST_BY_AREA";
export const GET_STOCK_SECONDARY_PRODUCT = "GET_STOCK_SECONDARY_PRODUCT";
export const GET_SECONDARY_SALES_PRODUCT_DEMAND_LIST = "GET_SECONDARY_SALES_PRODUCT_DEMAND_LIST";
export const GET_SECONDARY_SALES_LIST_EDIT_DATA = "GET_SECONDARY_SALES_LIST_EDIT_DATA";
export const GET_SECONDARY_SALES_ORDER_UPDATE_DETAILS = "GET_SECONDARY_SALES_ORDER_UPDATE_DETAILS";
export const DELETE_SECONDARY_SALES_TITEM = "DELETE_SECONDARY_SALES_TITEM";
export const GET_STOCK_SECONDARY_PRODUCT_VALIDATION = "GET_STOCK_SECONDARY_PRODUCT_VALIDATION";
export const GET_STOCK_SECONDARY_PRODUCT_VALIDATION_UPDATE = "GET_STOCK_SECONDARY_PRODUCT_VALIDATION_UPDATE";


// Corporate 

export const CHANGE_CORPORATE_PRODUCT_QUANTITY = "CHANGE_CORPORATE_PRODUCT_QUANTITY";
export const CHANGE_PRODUCT_QUANTITY_DEMO = "CHANGE_PRODUCT_QUANTITY_DEMO";
export const GET_SALES_PRODUCT_LIST_FOR_TEMP = "GET_SALES_PRODUCT_LIST_FOR_TEMP";
export const CHANGE_PRODUCT_UPDATE_QUANTITY_FEB = "CHANGE_PRODUCT_UPDATE_QUANTITY_FEB";
export const GET_SECONDARY_SALES_ORDER_FEB_UPDATE_PRODUCT = "GET_SECONDARY_SALES_ORDER_FEB_UPDATE_PRODUCT";
export const CLEAR_REMOTE_SALES_DATA_FEB = "CLEAR_REMOTE_SALES_DATA_FEB";
export const SALES_PRODUCT_SUBMIT_FEB = "SALES_PRODUCT_SUBMIT_FEB";
export const HANDLE_MEMO_INPUT = "HANDLE_MEMO_INPUT";


// super depo

export const GET_SUB_SUPER_DEPO = "GET_SUB_SUPER_DEPO";
export const GET_SUB_SALES_PRODUCT_DEMAND_LIST = "GET_SUB_SALES_PRODUCT_DEMAND_LIST";
export const CHANGE_SUB_PRODUCT_QUANTITY = "CHANGE_SUB_PRODUCT_QUANTITY";
export const GET_SUB_SALES_PRODUCT_LIST = "GET_SUB_SALES_PRODUCT_LIST";
export const GET_SUB_SALES_ORDER_DETAILS = "GET_SUB_SALES_ORDER_DETAILS";
export const GET_SUB_SALES_ORDER_UPDATE_DETAILS = "GET_SUB_SALES_ORDER_UPDATE_DETAILS";
export const FUND_SUBMIT = "FUND_SUBMIT";



// FUND COLLECTION
export const GET_FUND_INPUT = "GET_FUND_INPUT";
export const GET_FUND_LIST = "GET_FUND_LIST";
export const GET_WORKING_DAY_LIST = "GET_WORKING_DAY_LIST";

//DamagedDemand GET_DAMAGED_PRODUCT_LIST
export const GET_DAMAGED_PRODUCT_LIST = "GET_DAMAGED_PRODUCT_LIST";
export const DAMAGE_DEMAND_SUBMIT = "DAMAGE_DEMAND_SUBMIT";
export const CHANGE_DAMAGE_PRODUCT_QUANTITY = "CHANGE_DAMAGE_PRODUCT_QUANTITY";
export const GET_DAMAGE_APPROVED_SALES_REPORT = "GET_DAMAGE_APPROVED_SALES_REPORT";
export const GET_DAMAGE_PRODUCT_DEMAND_LIST = "GET_DAMAGE_PRODUCT_DEMAND_LIST";


//dISTRIBUTORLIST SMS
export const GET_DISTRIBUTORLISTPERMISSION_FOR_SMS = "GET_DISTRIBUTORLISTPERMISSION_FOR_SMS";
export const SMS_SEND_FROM_IMS = "SMS_SEND_FROM_IMS";


//clearList

export const CLEAR_LIST = "CLEAR_LIST";

//damaged 
export const GET_DAMAGED_LIST = "GET_DAMAGED_LIST";
export const CHANGE_DAMAGE_QUANTITY = "CHANGE_DAMAGE_QUANTITY";
export const DAMAGE_SUBMIT = "DAMAGE_SUBMIT";
export const CLEAR_FIELD_DATA = "CLEAR_FIELD_DATA";
export const GET_DAMAGE_LIST = "GET_DAMAGE_LIST";
export const GET_DAMAGE_UPDATE_DETAILS = "GET_DAMAGE_UPDATE_DETAILS";
export const CHANGE_DAMAGE_UPDATE_QUANTITY = "CHANGE_DAMAGE_UPDATE_QUANTITY";
export const DAMAGE_UPDATE = "DAMAGE_UPDATE";
export const DELETE_DAMAGE_ITEM = "DELETE_DAMAGE_ITEM";
export const GET_DAMAGE_PRODUCT_LIST_DETAILS = "GET_DAMAGE_PRODUCT_LIST_DETAILS";
export const PRODUCT_SUBMIT_STATUS_REMOVE_FOR_DAMAGE = "PRODUCT_SUBMIT_STATUS_REMOVE_FOR_DAMAGE";
export const GET_DISTRIBUTOR_WISE_DAMAGE_REPORT = "GET_DISTRIBUTOR_WISE_DAMAGE_REPORT";
export const GET_DAMAGE_APPROVED_LIST_DETAILS = "GET_DAMAGE_APPROVED_LIST_DETAILS";
export const APPROVED_ITEM = "APPROVED_ITEM";
export const CHANGE_DAMAGE_QUANTITY_NEW = "CHANGE_DAMAGE_QUANTITY_NEW";
export const GET_DAMAGE_TYPE = "GET_DAMAGE_TYPE";
export const CLEAR_FIELD_FOR_DAMAGE = "CLEAR_FIELD_FOR_DAMAGE";
export const GET_TOTAL_DAMAGE_REPORT_SUMMERY = "GET_TOTAL_DAMAGE_REPORT_SUMMERY";
//dashboard
export const GET_DAMAGE_DATA = "GET_DAMAGE_DATA";
export const GET_LOCATION_WISE_DATA = "GET_LOCATION_WISE_DATA";
export const GET_PRODUCTION_LINE = "GET_PRODUCTION_LINE";
export const GET_PRODUCT_GROUP_TYPE = "GET_PRODUCT_GROUP_TYPE";



//production Line
export const GET_PRODUCTION_LINE_DATA="GET_PRODUCTION_LINE_DATA";
export const GET_PRODUCTION_LINE_TABLE_DATA="GET_PRODUCTION_LINE_TABLE_DATA";
export const GET_SALES_MIXING_MONITORING_DATA="GET_SALES_MIXING_MONITORING_DATA";
export const GET_SALES_MIXING_MONITORING_TABLE_DATA="GET_SALES_MIXING_MONITORING_TABLE_DATA";

//donut

export const GET_LOCATION_WISE_DATA_FOR_DONUT="GET_LOCATION_WISE_DATA_FOR_DONUT";
export const GET_PRODUCTION_LINE_BY_PRODUCTION="GET_PRODUCTION_LINE_BY_PRODUCTION";
export const GET_PRODUCTION_AND_STOCK_MONITORING_GRAPH="GET_PRODUCTION_AND_STOCK_MONITORING_GRAPH";
export const GET_DISTRIBUTOR_BY_TERRITORY_ID="GET_DISTRIBUTOR_BY_TERRITORY_ID";
export const GET_SO_POINT_BY_DISTRIBUTOR_ID="GET_SO_POINT_BY_DISTRIBUTOR_ID";
export const GET_SO_POINT_BY_TERRITORY_ID="GET_SO_POINT_BY_TERRITORY_ID";


//report
export const GET_CUMULATIVE_REPORT="GET_CUMULATIVE_REPORT"
export const GET_REPORT_DATA="GET_REPORT_DATA"
export const GET_LINE_WISE_SALES_STANDRED="GET_LINE_WISE_SALES_STANDRED"



// PRODUCTION LINE UPDATED

export const GET_PRODUCTION_LINE_DATA_UPDATED="GET_PRODUCTION_LINE_DATA_UPDATED"
export const GET_DISTIB_BANK_REC_DATA="GET_DISTIB_BANK_REC_DATA"


//CM ANALYSIS

export const GET_CM_ANALYSIS_MONTOMON_DATA="GET_CM_ANALYSIS_MONTOMON_DATA"
export const GET_CM_ANALYSIS_YEAR_DATA="GET_CM_ANALYSIS_YEAR_DATA"
export const GET_LINE_WISE_STANDARD_CM="GET_LINE_WISE_STANDARD_CM"
export const GET_CM_TRAND_SINCE_BEGINING="GET_CM_TRAND_SINCE_BEGINING"
export const GET_RECIPE_LIST="GET_RECIPE_LIST"











