import Axios from "axios";
import * as Types from "./DashboardType";

// primary sales trand
export const GetablPrimarySalesTrand = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine,selectSo,selectDistributorId,salesChannelId) => async (dispatch) => {

    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    dispatch({ type: Types.ABL_PRIMARY_SALES_TRAND, payload: responseList });
    let url = "";
    url = `${process.env.REACT_APP_API_PUBLIC_URL}GetMonthToMonthPrimarySalesTrend?`;
  
    if (ZoneID !== null) {
      url += `&ZoneID=${ZoneID}`
    }
    if (DivisionID !== null) {
      url += `&DivisionID=${DivisionID}`
    }
    if (AreaID !== null) {
      url += `&AreaID=${AreaID}`
    }
    if (Territory !== null) {
      url += `&Territory=${Territory}`
    }
    if (Fdate !== null) {
      url += `&Fdate=${Fdate}`
    }
    if (Tdate !== null) {
      url += `&Tdate=${Tdate}`
    }
    if (category !== null) {
      url += `&category=${category}`
    }
    if (ProductId !== null) {
      url += `&ProductId=${ProductId}`
    }
    if (ProductGroup !== null) {
      url += `&salesGroupId=${ProductGroup}`
    }
    if (productionLine !== null) {
      url += `&productionLine=${productionLine}`
    }
    if (selectSo !== null) {
      url += `&soPoint=${selectSo}`
    }
    if (selectDistributorId !== null) {
      url += `&distribID=${selectDistributorId}`
    }
    if (salesChannelId !== null) {
      url += `&SalesChannelId=${salesChannelId}`
    }
    await Axios.get(url).then((res) => {
      
      responseList.data = res.data.data == null ? [] : res.data.data;
      responseList.isLoading = false;
      
    });
    dispatch({ type: Types.ABL_PRIMARY_SALES_TRAND, payload: responseList });
  };
  // primary sales trand