import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import faker from "faker";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function SecondarySalesTrendingPercentageWise() {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const ablData = useSelector((state) => state.dashboardcart.ablData);
  const ablprice = useSelector((state) => state.dashboardcart.ablprice);
  const abldate = useSelector((state) => state.dashboardcart.abldate);
  const isLoading = useSelector(
    (state) => state.dashboardcart.monthtomonthLoading
  );
  const TrendValuePercentage = useSelector(
    (state) => state.dashboardcart.TrendValuePercentage
  );

  const verticleData = ablprice;
  const horizontalData = abldate;

  let nf = new Intl.NumberFormat("en-BD");

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
            label: (context) => {
                const datasetLabel = context.dataset.label || "";
                const value = context.parsed.y;

                return [
                    `${datasetLabel}: ${value}`
                ].join("\n");
            },
        },
    },
      legend: {
        position: "bottom",
        display: true,
      },
      datalabels: {
        display: true,
        color: function(dataColor) {
          //return context.dataset.data[context.dataIndex] > 50 ? 'red' : 'green';
        },
      },
      // title: {
      //     display: true,
      //     text: '1 Million',
      //     font: {
      //       size: 12,
      //     },
      //   },
    },
    scales: {
      y1: {
        display: false,
    },
      y: {
        title: {
          display: true,
          text: "Millions",
          font: {
            size: 12,
          },
        },
      },
    },
  };

  const labels = horizontalData;

  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: [reportData.lineName] + "Growth %",
        borderColor: "#0b8793",
        borderWidth: 2,
        fill: false,
        data: TrendValuePercentage,
        yAxisID: "y1",
        datalabels: {
          display: true,
          color: function(dataColor) {
            return dataColor.dataset.data[dataColor.dataIndex] < 0
              ? "white"
              : "black";
          },
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: function(value, context) {
            return nf.format(value) + "%";
          },
          // backgroundColor: 'rgba(106, 90, 205, 0.5)',
          backgroundColor: function(dataColor) {
            return dataColor.dataset.data[dataColor.dataIndex] < 0
              ? "#FF0000"
              : "#CBDCCB";
          },
        },
      },
      {
        type: "bar",
        label:
          [reportData.lineName] + " Secondary Sales Trend Rolling 12 Months",
        backgroundColor: "#0b8793",
        data: verticleData,

        borderColor: "white",
        borderWidth: 2,
        yAxisID: "y",
        datalabels: {
          display: true,
          color: "white",
          align: "bottom",
          anchor: "end",
          rotation: 270,
          formatter: function(value, context) {
            return "BDT " + nf.format(value);
          },
        },
      },
    ],
  };

  return (
    <div className="card mb-0 ">
      <span className="spineercolor mt-5 mb-5">
        {isLoading && <LoadingSpinner />}
      </span>
      <>
        {!isLoading && (
          <>
            <div className="text-center">
              <h5>
                Secondary Sales Rolling 12 Months Growth (%) - At core{" "}
              </h5>
            </div>
            <Chart
              options={options}
              data={data}
              height={137}
              plugins={[ChartDataLabels]}
            />
          </>
        )}
      </>
    </div>
  );
}
