import React, { useState } from "react";
import SimpleModal from "../master/components/Modal/SimpleModal";
import Report from "./Report";
import ReportGroupA from "./ReportGroupA";
import { getAblCumulativeSalesReportWithPackType, getLineWiseSalesStandard } from "./DashboardCartAction";
import { getCumulativeReport } from "./ProductionLineAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import StandardMixing from "./StandardMixing";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const AllReportPage = () => {
  const [downloadReport, setDownloadReport] = useState(false);
  const [groupAReport, setGroupAReport] = useState(false);
  const [standardMixing, setStandardMixing] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [itemifo, setItemifo] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    let d = new Date();
    let firstD =
      d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    let currentD =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      "";
    dispatch(getAblCumulativeSalesReportWithPackType(firstD, currentD));

    dispatch(getCumulativeReport(firstD, currentD));
  };
  const handleChangeStartDate = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setToDate(input.value);

  };
  const handleShowReport = () => {
    if (startDate && toDate) {
      dispatch(
        getLineWiseSalesStandard(
          startDate,
          toDate,
        )
      );
    } else {
      alert("Enter From and To Date.");
    }
  };
  return (
    <div className="card container-fluid"
      style={{
        backgroundImage: `url(${toAbsoluteUrl("/media/bg/flower.avif")})`
      }} >
      <div className="card  p-5 dashboardCartReport mt-5 ">
        <div className="form-group row">
          <div className="col-lg-3 mb-4">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date:</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="fromDate"
                value={startDate}
                className="form-control cusheight"
                onChange={handleChangeStartDate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-3 ">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date:</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="todate"
                value={toDate}
                className="form-control cusheight"
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>


          <div className="col-lg-3 mt-1">
            <button
              className="button-621 mt-5"
              onClick={() => handleShowReport()}
            >
              Show
            </button>
          </div>
        </div>
      </div>
      <div className="row p-4">
        <div
          className="col-lg-3 btn-grad"
          onClick={() => setDownloadReport(true)}
        >
          <i class="fas fa-file-alt"></i> Brand Sales Report
        </div>
        <div
          className="col-lg-3 btn-grad2"
          onClick={() => setGroupAReport(true)}
        >
          <i class="fas fa-file-alt"></i> Pack Wise Report
        </div>
        {/* <div
          className="col-lg-3 btn-grad"
          onClick={() => setStandardMixing(true)}
        >
          <i class="fas fa-file-alt"></i> Standard Mixing
        </div> */}
        <div
          className="col-lg-3 btn-grad3"
          onClick={() => setStandardMixing(true)}
        >

          <NavLink to="/standardMixing">
            <i class="fas fa-file-alt"></i> <span className="text-white">Standard Mixing</span>
          </NavLink>
        </div>
      </div>
      {/* <div className="col-lg-3">
        <button
          type="button"
          class="btn btn-info btn-sm mt-5"
          onClick={() => setDownloadReport(true)}
        >
          Report
        </button>
        <button
          type="button"
          class="btn bgcolor btn-sm mt-5 "
          onClick={() => setGroupAReport(true)}
        >
          Group-A
        </button>
      </div> */}
      <SimpleModal
        size="xl"
        show={downloadReport}
        handleClose={() => setDownloadReport(false)}
        handleShow={() => setDownloadReport(true)}
      >
        <Report
          handleClose={() => setDownloadReport(false)}
          itemDetailnIfo={itemifo}
        />
      </SimpleModal>
      <SimpleModal
        size="xl"
        show={groupAReport}
        handleClose={() => setGroupAReport(false)}
        handleShow={() => setGroupAReport(true)}
        className={groupAReport ? "full-page-modal" : ""}
      >
        <ReportGroupA
          handleClose={() => setGroupAReport(false)}
          itemDetailnIfo={itemifo}
        />
      </SimpleModal>
      {/* <SimpleModal
        size="xl"
        show={standardMixing}
        handleClose={() => setStandardMixing(false)}
        handleShow={() => setGroupAReport(true)}
        className={groupAReport ? "full-page-modal" : ""}
      >
        <StandardMixing
          handleClose={() => setStandardMixing(false)}
          itemDetailnIfo={itemifo}
        />
      </SimpleModal> */}
    </div>
  );
};

export default AllReportPage;
