import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setRangeValues } from "../../store/MapReducer";

const EditRangeModal = ({ onClose, rangeName, toast }) => {
  const dispatch = useDispatch();
  const rangeValues = useSelector(state => state.map.rangeValues);

  const [newValues, setNewValues] = useState({
    min: rangeValues[rangeName].min,
    max: rangeValues[rangeName].max,
  });

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const submitHandler = (e) => {
    if(newValues.max > newValues.min){
      dispatch(setRangeValues({rangeName, newValues}));
      toast.success('Range Updated!')
      onClose()
    }
    else{
      toast.error('Range Mismatched!')
    }
    
  };

  return (
    <div className="modal">
      <div className="modal-content-range">
        <div className="text-end">
          <span className="close" onClick={onClose}>
            &times;
          </span>
        </div>
        <h5 className="mb-3">
          Edit Range {/* for {capitalizeFirstLetter(rangeName)} */} Performance
        </h5>
        <div className="table-container container-fluid">
          <div className="row p-1">
            <div className="col-md-4 px-1 mb-md-0 mb-2 d-flex align-items-center">
              <label className="mb-0 mr-2">Min</label>
              <input
                type="text"
                className="form-control"
                value={newValues.min}
                disabled={rangeName === 'worst' ? true : false}
                onChange={(e) => {
                  e.persist();
                  const inputValue = e.target.value;
                  setNewValues((prev) => ({
                    ...prev,
                    min: inputValue === '' ? 0 : parseInt(inputValue),
                  }));
                }}
              />
            </div>
            <div className="col-md-4 px-1 mb-md-0 mb-2 d-flex align-items-center">
              <label className="mb-0 mr-2">Max</label>
              <input
                type="text"
                className="form-control"
                value={newValues.max}
                disabled={rangeName === 'best' ? true : false}
                onChange={(e) => {
                  e.persist();
                  const inputValue = e.target.value;
                  setNewValues((prev) => ({
                    ...prev,
                    max: inputValue === '' ? 0 : parseInt(inputValue),
                  }));
                }}
              />
            </div>
            <div className="col-md-4 px-1">
              <button
                className="btn btn-primary btn-block"
                type="submit"
                onClick={submitHandler}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRangeModal;
