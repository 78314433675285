import React from 'react';

import DataTable from 'react-data-table-component';
import Export from "react-data-table-component"
import Movie from './Movie';
import DataTableExtensions from "react-data-table-component-extensions";

import "../../Accounts/Components/demostyle.css";

const columns = [
    {
      name: "Zone ID",
      selector: "title",
      sortable: true,
      selector: row => row.zoneID,
    },
    {
        name: "Zone NAME",
        selector: "title",
        sortable: true,
        selector: row => row.zoneName,
      },
      {
        name: "Zone ID",
        selector: "title",
        sortable: true,
        selector: row => row.zoneID,
      },
      {
        name: "DIVISION ID",
        selector: "title",
        sortable: true,
        selector: row => row.divisionID,
      },
      {
        name: "DIVISION NAME",
        selector: "title",
        sortable: true,
        selector: row => row.divisionName,
      },
      {
        name: "DIS POINT ID",
        selector: "title",
        sortable: true,
        selector: row => row.disPointID,
      },
      {
        name: "DIS POINT NAME",
        selector: "title",
        sortable: true,
        selector: row => row.disPointName,
      },
      {
        name: "PRODUCT CAT NAME",
        selector: "title",
        sortable: true,
        selector: row => row.prodCatName,
      },
      {
        name: "PRODUCT CLOSING QTY",
        selector: "title",
        sortable: true,
        selector: row => row.productClosingCTQty,
      },
      {
        name: "PRODUCT CLOSING PC QTY",
        selector: "title",
        sortable: true,
        selector: row => row.productClosingPCQty,
      },
      {
        name: "PRODUCT CLOSING PRICE",
        selector: "title",
        sortable: true,
        selector: row => row.productClosingPrice,
      },
      {
        name: "PRODUCT NAME",
        selector: "title",
        sortable: true,
        selector: row => row.productName,
      },
      {
        name: "Zone ID",
        selector: "title",
        sortable: true,
        selector: row => row.zoneID,
      },
      {
        name: "Zone ID",
        selector: "title",
        sortable: true,
        selector: row => row.zoneID,
      },
      {
        name: "Zone ID",
        selector: "title",
        sortable: true,
        selector: row => row.zoneID,
      },
      {
        name: "Zone ID",
        selector: "title",
        sortable: true,
        selector: row => row.zoneID,
      },
    {
      name: "Directior",
      selector: "director",
      sortable: true
    },
    {
      name: "Runtime (m)",
      selector: "runtime",
      sortable: true,
      right: true
    }
  ];

const data = [
    {
        id: 1,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 2,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 2,
        title: 'Ghostbusters',
        year: '1984',
        month: '1984',
    },
]

function ReactDataTable() {

    const [pending, setPending] = React.useState(true);
	const [rows, setRows] = React.useState([]);
	const [stockData, setStockData] = React.useState([]);

    const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []);

    React.useEffect(() => {

        initialData();
		const timeout = setTimeout(() => {
			setRows(data);
			setPending(false);
		}, 2000);
		return () => clearTimeout(timeout);
	}, []);
    function downloadCSV() {
       
    }

    const initialData=async()=>{
      
        // const stockDatas = await getSecondaryStockForExcel('2022-09-30',null,null,null,null);
        // setStockData(stockDatas.data);
        // console.log('stockDatas', stockDatas);
    }

    const tableData = {
        columns,
        data:stockData,
      };

      console.log('tableData', tableData);

    return (
        <DataTableExtensions filterHidden={false} exportHeaders {...tableData}
        >
        <DataTable
            columns={columns}
            data={stockData}
            noHeader
            defaultSortField="id"
            defaultSortAsc={false}
            pagination 
            //progressPending={pending}
            //actions={actionsMemo}
            //striped
            //dense
            highlightOnHover
            
           
        />
        </DataTableExtensions>
    );
};

export default ReactDataTable;