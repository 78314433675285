import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingSpinner from '../Billing/Components/LoadingSpinner';
import { GetRecipeList } from './CMAnalysisAction';
import { Form } from 'react-bootstrap';

const RecipesModal = (itemID3) => {
  const dispatch = useDispatch();

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    dispatch(GetRecipeList(itemID3.itemID3));
  };

  const recipeData = useSelector((state) => state.cm.recipeData);

  console.log('recipeData', recipeData);
  const isLoading = useSelector((state) => state.cm.recipeDataLoading);
  const [password, setPassword] = useState('');
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);
  const correctPassword = '12345';

  // State to track the selected version
  const [selectedVersion, setSelectedVersion] = useState(null);

  useEffect(() => {
    if (password === correctPassword) {
      setIsPasswordCorrect(true);
    }
  }, [password, correctPassword]);

  // Function to handle tab selection
  const handleTabSelect = (version) => {
    setSelectedVersion(version);
  };

  // Find the latest version
  useEffect(() => {
    if (recipeData?.recipeList?.length > 0) {
      const latestVersion = Math.max(
        ...recipeData.recipeList.map((data) => data.version)
      );
      setSelectedVersion(latestVersion);
    }
  }, [recipeData]);

  return (
    <div className='customModal'>
      <h3 className='text-center'>Recipe</h3>
      {isPasswordCorrect ? (
        <>
          {/* Version Tabs */}
          <div className='text-center' >
            <ul className="nav nav-tabs text-center">
              {recipeData?.recipeList?.length > 0 &&
                [...new Set(recipeData.recipeList.map((data) => data.version))].map((version, index) => (
                  <li className="nav-item" key={index}>
                    <button
                      className={`nav-link ${selectedVersion === version ? 'active' : ''
                        }`}
                      onClick={() => handleTabSelect(version)}
                    >
                      Version {version}
                    </button>
                  </li>
                ))}
            </ul>
          </div>

          {isLoading ? (
            <span className="spinner-color mt-5 mb-5 text-center">
              <LoadingSpinner className="text-center" />
            </span>
          ) : (
            <div className="react-bootstrap-table table-responsive border-0 pl-5 pr-5 pb-5">
              <table className="table table-head-custom table-vertical-center item-add-table borderTableims recepieta mb-5 table-striped">
                <thead>
                  <tr className="backcolor">
                    <th scope="col">SL</th>
                    <th scope="col">Item Code</th>
                    <th scope="col">Item Name</th>
                    <th scope="col">Uom</th>
                    <th scope="col">Required Qty</th>
                    <th scope="col">Allowance</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Check if recipeData?.recipeList is defined and contains data */}
                  {recipeData?.recipeList?.length > 0 ? (
                    recipeData.recipeList
                      .filter((data) => data.version === selectedVersion)
                      .map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data?.itemcode}</td>
                          <td>{data?.itemDesc}</td>
                          <td>{data?.uom}</td>
                          <td>{data?.qty}</td>
                          <td>{data?.ratio}%</td>
                        </tr>
                      ))
                  ) : (
                    // Display a message when there's no data
                    <tr>
                      <td colSpan="6" className='text-center'>No data available</td>
                    </tr>
                  )}
                  {/* Calculate and display the total */}
                  {recipeData?.recipeList?.length > 0 && (
                    <tr>
                      <td colSpan="4">Total:</td>
                      <td>
                        { parseFloat (recipeData.recipeList
                          .filter((data) => data.version === selectedVersion)
                          .reduce((total, data) => total + data.qty, 0)).toFixed(3)}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>


              {/* Packing/Others Table */}
              <h4>Packing/Others:</h4>
              <table className="table table-head-custom table-vertical-center item-add-table borderTableims recepieta mb-5 table-striped">
                <thead>
                  <tr className="backcolor">
                    <th scope="col">SL</th>
                    <th scope="col">Item Code</th>
                    <th scope="col">Item Name</th>
                    <th scope="col">Uom</th>
                    <th scope="col">Required Qty</th>
                    <th scope="col">Allowance</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Check if recipeData?.packingList is defined and contains data */}
                  {recipeData?.packingList?.length > 0 ? (
                    recipeData.packingList
                      .filter((data) => data.version === selectedVersion)
                      .map((data, index) => (
                        <tr key={index}>
                          <td>{index++ + 1}</td>
                          <td>{data?.itemcode}</td>
                          <td>{data?.itemDesc}</td>
                          <td>{data?.uom}</td>
                          <td>{data?.qty}</td>
                          <td>{data?.ratio}%</td>
                        </tr>
                      ))
                  ) : (
                    // Display a message when there's no data
                    <tr>
                      <td colSpan="6" className='text-center'>No data available</td>
                    </tr>
                  )}
                  {/* Calculate and display the total */}
                  {recipeData?.packingList?.length > 0 && (
                    <tr>
                      <td colSpan="4">Total:</td>
                      <td>
                        { parseFloat (recipeData.packingList
                          .filter((data) => data.version === selectedVersion)
                          .reduce((total, data) => total + data.qty, 0)).toFixed(3)}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* Dry Out put */}
              <h4>Dry Out Put:</h4>
              <table className="table table-head-custom table-vertical-center item-add-table borderTableims recepieta mb-5 table-striped">
                <thead>
                  <tr className="backcolor">
                    <th scope="col">SL</th>
                    <th scope="col">Dry Out Put</th>
                    <th scope="col">Cartoon Weight</th>
                    <th scope="col">Packet Weight</th>
                    <th scope="col">Yield</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {/* Check if recipeData?.packingList is defined and contains data */}
                  {recipeData?.dryOutPut?.length > 0 ? (
                    recipeData.dryOutPut
                      // .filter((data) => data.version === selectedVersion)
                      .map((data, index) => (
                        <tr key={index}>
                          <td>{index++ + 1}</td>
                          <td>{data?.dryOutPut}</td>
                          <td>{data?.cartoonWeightKh}</td>
                          <td>{data?.pktWeightkg}</td>
                          <td>{data?.yield}</td>
                        </tr>
                      ))
                  ) : (
                    // Display a message when there's no data
                    <tr>
                      <td colSpan="6" className='text-center'>No data available</td>
                    </tr>
                  )}
                  {/* Calculate and display the total */}
                  {/* {recipeData?.packingList?.length > 0 && (
                    <tr>
                      <td colSpan="4">Total:</td>
                      <td>
                        { parseFloat (recipeData.dryOutPut
                          // .filter((data) => data.version === selectedVersion)
                          .reduce((total, data) => total + data.dryOutPut, 0)).toFixed(3)}
                      </td>
                      <td></td>
                    </tr>
                  )} */}
                </tbody>
              </table>
            </div>
          )}
        </>
      ) : (
        <div className="p-5">
          <span>Enter Password: </span>
          <div className="text-center">
            <Form.Group controlId="formGridState">
              <Form.Control
                className="form-control reffcss"
                type="text"
                placeholder="Enter the password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecipesModal;
