/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
import { GetMenuListsByPermission } from "./authMenuPermissionAction";

export function AsideMenuList({ layoutProps }) {

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  const menuList = useSelector((state) => state.menu.menuList);
  const isMenuLoading = useSelector((state) => state.menu.isMenuLoading);

  useEffect(() => {
    dispatch(GetMenuListsByPermission());
  }, [dispatch]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Main Menu</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/sidebar/dashboard.svg")}
              />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}


        {/* Demo start */}
        {/* <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/sidebar/icon-dashboard.svg"
                )}
              />
            </span>
            <span className="menu-text">Secondary Sales</span>
            <i className="menu-arrow" />
          </NavLink>

          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/secondarySales/entry">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Secondary Sales Entry</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/secondarySales/List">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Secondary Sales List</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/territory/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Territory</span>
                  <i className="menu-arrow" />
                </NavLink>
                <NavLink className="menu-link menu-toggle" to="/area/list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Area</span>
                  <i className="menu-arrow" />
                </NavLink>
              </li>
            
            </ul>
          </div>
        </li> */}
        {/* Demo end */}
        <li
          className={`menu-item menu-item-submenu`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
        </li>

        {
          typeof menuList != 'undefined' &&
          menuList?.map((menu, index) => (
            <li
              className={`menu-item menu-item-submenu`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/sidebar/icon-dashboard.svg"
                    )}
                  />
                </span>
                <span className="menu-text">{menu.motherMenu}</span>
                <i className="menu-arrow" />
              </NavLink>

              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {typeof menu.p_ApplicationURLChildren !== 'undefined' && menu.p_ApplicationURLChildren.map((subMenu, subIndex) => (
                    <li

                      // className={`menu-item menu-item-submenu`}

                    className={`menu-item ${getMenuItemActive(subMenu.path)}`}
                      
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      // className={`menu-item ${getMenuItemActive(subMenu.path)}`}
                    >
                      <NavLink
                        className="menu-link menu-toggle"
                        to={subMenu.path}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">{subMenu.displayname}</span>
                        <i className="menu-arrow" />
                      </NavLink>
                    </li>
                  ))}

                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
          ))
        }
        {/* <NavLink className="menu-link menu-toggle" to="/secondarySales/skuwise">
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">Sku wise </span>
          <i className="menu-arrow" />
        </NavLink>
        <NavLink className="menu-link menu-toggle" to="/primarySales/cumalative">
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">Bread Burn Report</span>
          <i className="menu-arrow" />
        </NavLink> */}
        {/* <NavLink className="menu-link menu-toggle" to="/tradeOffer/sales">
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
          <span className="menu-text">Sample</span>
          <i className="menu-arrow" />
        </NavLink> */}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
