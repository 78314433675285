import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  GetABLMOnthWiseData,
  GetCategoryType,
  GetDamageData,
  GetDashboardTargetDate,
  GetDSMType,
  GetLineType,
  GetLocationWiseSales,
  GetLocationWiseSalesForDonut,
  GetNSMType,
  GetProductGroup,
  GetProductionLine,
  GetProductionLineByProduction,
  GetProductType,
  GetRMType,
  GetTSMType,
  handleDateManageForDashboardCart,
  InputHandle,
  InputHandleChangeDate,
  InputReportHandle,
} from "./DashboardCartAction";
import {
  getAreaListByDivisionEntry,
  getDivisionListByZoneEntry,
  getTerritoryEntry,
  getZoneListEntry,
} from "./DistributorReportAction";
import {
  getProductionLineTableData,
  getProductionandStockMonitoringGraph,
  getSalesMixingMonitoringData,
  getSalesMixingMonitoringTableData,
} from "./ProductionLineAction";

import { useReactToPrint } from "react-to-print";
import Report from "./Report";
import { getLineWiseSalesStandard, getUpdatedProductionLineTableData } from "./ProductionLineUpdatedAction";
import { GetCmTrendsinceBeginning, GetCurrentMonthCMAnalaysis, GetCurrentYearCMAnalaysis, GetLineWiseStandardCM } from "./CMAnalysisAction";
const CMAnalysisInput = () => {


  const { register, handleSubmit, errors, setFocus, setValue } = useForm();
  const dispatch = useDispatch();

  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const isLoading = useSelector((state) => state.dashboardcart.toCardLoading);
  const categorytype = useSelector(
    (state) => state.dashboardcart.ddcategorytype
  );
  const nsmtype = useSelector((state) => state.dashboardcart.ddnsmtype);
  const dsmtype = useSelector((state) => state.dashboardcart.dddsmtype);
  const rmtype = useSelector((state) => state.dashboardcart.ddrmtype);
  const tsmtype = useSelector((state) => state.dashboardcart.ddtsmtype);
  const product = useSelector((state) => state.dashboardcart.ddproducttype);
  const linetype = useSelector((state) => state.dashboardcart.linetype);

  const productGroupType = useSelector(
    (state) => state.dashboardcart.productGroupType
  );
  const productionLine = useSelector(
    (state) => state.dashboardcart.productionLine
  );
  const proLinebyPro = useSelector((state) => state.dashboardcart.proLinebyPro);

  const [zone, Setzone] = useState([]);
  const [division, SetDivision] = useState([]);
  const [selectZone, setSelectZone] = useState(0);
  const [area, setArea] = useState([]);
  const [selectDivision, setSelectDivision] = useState(0);
  const [selectArea, setSelectArea] = useState(0);
  const [selectGeoType, setSelectGeoType] = useState(2);
  const [terriory, setTerritory] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState(0);
  const [productlist, SetProductlist] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectCategory, setSelectCategoryType] = useState(0);
  const [selectProductType, setSelectProductType] = useState(0);
  const [selectcategoryName, setSelectCategoryName] = useState(0);
  const [selectProductGroupType, setSelectProductGroupType] = useState(0);
  const [selectProductionLine, setSelectProductionLine] = useState(0);
  console.log('selectProductionLine :>> ', selectProductionLine);
  const [selectConditionType, setSelectConditionType] = useState(0);



  const condition = [
    { value: '1', label: 'Top 5 SKU-High CM%' },
    { value: '2', label: 'Top 10 SKU-High CM%' },
    { value: '3', label: 'Top 15 SKU-High CM%' },
    { value: '4', label: 'All SKU-CM%' },
    { value: '5', label: 'Top 5 SKU-Low CM%' },
    { value: '6', label: 'Top 10 SKU-Low CM%' },
    { value: '7', label: 'Top 15 SKU-Low CM%' },
    { value: '8', label: 'All SKU-Negative CM%' },

  ];

  const options = [
    { value: "1", label: "Value" },
    { value: "2", label: "Volume" },
  ];

  const handleClearData = () => {
    // setSelectProductType(null);
    // setSelectProductGroupType(null);
    // setSelectProductionLine(null);
  };

  const inputChangeHandle = (name, value) => {
    dispatch(InputHandle(name, value));
  };

  const handleChange = (name, value) => {
    dispatch(InputReportHandle(name, value));
  };

  const groupData = useSelector(
    (state) => state.cm.groupData
  );

  console.log('getFormattedFirstDate', getFormattedFirstDate());
  console.log('getFormattedCurrentDate', getFormattedCurrentDate());


  useEffect(() => {
    initialData();
  }, []);

  // const productList = async () => {
  //     console.log('productList', productList)
  //     let productData = await GetProductType(selectCategory);
  //     console.log('productData', productData)
  //     SetProductlist(productData.data);
  //   }
  var dt = new Date();
  var firstDDate =
    dt.getFullYear() + "-" + ("0" + (dt.getMonth() + 1)).slice(-2) + "-" + dt.getDate();

  console.log('firstDDate', firstDDate);

  const initialData = async () => {
    var d = new Date();
    var firstD =
      d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + 1;
    var currentD =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      "";



    let zoneData = await getZoneListEntry();
    // setStartDate(firstD);
    // setToDate(currentD);
    Setzone(zoneData.data);


    dispatch(
      GetCurrentMonthCMAnalaysis(
        firstD,
        currentD,
        selectProductionLine,
        selectProductType,
        selectConditionType,

      )
    );
    dispatch(
      GetCurrentYearCMAnalaysis(
        firstD,
        currentD,
        selectProductionLine,
        selectProductType,
        selectConditionType,

      )
    );
    dispatch(
      GetLineWiseStandardCM(
        firstD,
        currentD,
        (selectProductionLine !== undefined && selectProductionLine !== 0) ? selectProductionLine : 10,
        selectProductType,
        selectConditionType,

      )
    );
    dispatch(
      GetCmTrendsinceBeginning(
        firstD,
        currentD,
        selectProductionLine,
        selectProductType,
        selectConditionType,

      )
    );



    dispatch(GetCategoryType());
    dispatch(GetLineType());
    dispatch(GetProductGroup());
    dispatch(GetProductionLine());
    dispatch(GetProductionLineByProduction());

  };

  const divisionList = async (id) => {
    let divisionData = await getDivisionListByZoneEntry(id, selectGeoType);
    SetDivision(divisionData.data);
  };
  const getAreaData = async (id) => {
    let areaData = await getAreaListByDivisionEntry(id, selectGeoType);
    setArea(areaData.data);
  };
  const getTerritoryData = async (id) => {
    let territoryData = await getTerritoryEntry(id, selectGeoType);
    setTerritory(territoryData.data);
  };

  console.log('startDate', startDate);

  const zoneDropdownData = () => {
    let options = [];
    if (zone?.length > 0) {
      zone.forEach((item) => {
        let itemData = {
          value: item.zoneID,
          label: item.zoneName,
        };

        options.push(itemData);
      });
    }
    // setValue("divisionData", "");

    return options;
  };
  const divisionDropdownData = () => {
    let options = [];
    if (division?.length > 0) {
      division.forEach((item) => {
        let itemData = {
          value: item.divisionID,
          label: item.divisionName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const areaDropdownData = () => {
    let options = [];
    if (area?.length > 0) {
      area.forEach((item) => {
        let itemData = {
          value: item.areaID,
          label: item.areaName,
        };

        options.push(itemData);
      });
    }
    return options;
  };
  const territoryDropdownData = () => {
    let options = [];
    if (terriory?.length > 0) {
      terriory.forEach((item) => {
        let itemData = {
          value: item.territoryId,
          label: item.territoryName,
        };

        options.push(itemData);
      });
    }
    return options;
  };

  const onSubmit = async (e) => { };

  const dashValidation = () => {

    if (startDate == null) {
      alert("Please Input From Date");
      return false;
    }
    else if (toDate == null) {
      alert("Please Input ToDate");
      return false;
    }
    // else if (selectProductionLine == 0) {
    //   alert("Please Select Production Line");
    //   return false;
    // }
    else {
      return true;
    }
  }

  function getFormattedFirstDate() {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = date.getDate();
    console.log('day', day);
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${"01"}`;
  }

  function getFormattedCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    let day = date.getDate();
    if (day < 10) {
      day = `0${day}`;
    }
    return `${year}-${month}-${day}`;
  }

  const handleShowProductLineReport = () => {
    const validation = dashValidation();
    if (validation) {
      dispatch(
        GetCurrentMonthCMAnalaysis(
          startDate,
          toDate,
          selectProductionLine,
          selectProductType,
          selectConditionType,

        )
      );
      dispatch(
        GetCurrentYearCMAnalaysis(
          startDate,
          toDate,
          selectProductionLine,
          selectProductType,
          selectConditionType,

        )
      );
      dispatch(
        GetLineWiseStandardCM(
          startDate,
          toDate,
          (selectProductionLine !== undefined && selectProductionLine !== 0) ? selectProductionLine : 10,
          selectProductType,
          selectConditionType
        )
      );

      dispatch(
        GetCmTrendsinceBeginning(
          startDate,
          toDate,
          selectProductionLine,
          selectProductType,
          selectConditionType,

        )
      );
    }



  };
  const handleChangeStartDate = ({ currentTarget: input }) => {
    dispatch(InputHandleChangeDate(input.name, input.value));
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    dispatch(InputHandleChangeDate(input.name, input.value));
    setToDate(input.value);
  };
  return (
    <div>
      <div className="card p-3 dashboardCart ">
        <div className="form-group row">
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">From Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Deadweight"
                name="startDate"
                value={startDate}

                className="form-control cusheight"
                onChange={handleChangeStartDate}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label"> To Date</label>
              <Form.Control
                type="date"
                placeholder="Enter Todate"
                name="toDate"
                value={toDate}
                className="form-control cusheight "
                onChange={handleChangeTodate}
              />
            </Form.Group>
          </div>
          {/* <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Measurement</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={options} />}
                rules={{ required: false }}
                name="measurement "
                register={register}
                value=""
                isClearable
                onChange={(option) =>
                  handleChange("measurement", option?.value)
                }
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Zones</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={zoneDropdownData()} />}
                rules={{ required: false }}
                name="zoneData"
                register={register}
                // value={selectZone}
                value=""
                isClearable
                onChange={(option) => {
                  divisionList(option?.value);
                  setSelectZone(option?.value);
                  setValue("divisionData", "");
                  setValue("areaData", "");
                  setValue("territory", "");
                  dispatch(GetNSMType(option?.value));
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Division</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={divisionDropdownData()} />}
                rules={{ required: false }}
                name="divisionData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getAreaData(option?.value);
                  setSelectDivision(option?.value);
                  setValue("areaData", "");
                  setValue("territory", "");
                  dispatch(GetDSMType(option?.value));
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Region</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={areaDropdownData()} />}
                rules={{ required: false }}
                name="areaData"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  // setType(option.value)
                  getTerritoryData(option?.value);
                  setSelectArea(option?.value);
                  dispatch(GetRMType(option?.value));

                  setValue("territory", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Select Territory</label>
              <RHFInput
                className="formSelect pt-0"
                as={<Select options={territoryDropdownData()} />}
                rules={{ required: false }}
                name="territory"
                register={register}
                value=""
                isClearable
                // value={CertificateMasterInput.isActive}
                onChange={(option) => {
                  setSelectTerritory(option?.value);
                  dispatch(GetTSMType(option?.value));
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Sales Group</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={productGroupType} />}
                rules={{ required: false }}
                name="productGroupType"
                register={register}
                placeholder="Select Product Group"
                isClearable
                onChange={(option) => {
                  handleChange("productGroupType", option?.value);
                  handleChange("salesGroupName", option?.label);
                  setSelectProductGroupType(option?.value);
                  setValue("lineType", "");
                  setValue("productType", "");
                  setValue("nsmType", "");
                  setValue("dsmType", "");
                  setValue("rmType", "");
                  setValue("tsmType", "");
                  setValue("productionLine", "");
                  handleClearData();
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div> */}

          {/* <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Marketing Line</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={linetype} />}
                rules={{ required: false }}
                name="lineType"
                register={register}
                placeholder="Select Line type"
                isClearable
                onChange={(option) => {
                  handleChange("lineType", option?.value);
                  handleChange("lineName", option?.label);
                  setSelectCategoryType(option?.value);
                  dispatch(GetProductType(option?.value, startDate, toDate));
                  setValue("productType", "");
                  setValue("nsmType", "");
                  setValue("dsmType", "");
                  setValue("rmType", "");
                  setValue("tsmType", "");
                  setValue("productionLine", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div> */}
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Production Line</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={productionLine} />}
                rules={{ required: false }}
                name="productionLine"
                register={register}
                placeholder="Select Production Line"
                isClearable
                onChange={(option) => {
                  handleChange("productionLine", option?.value);
                  setSelectProductionLine(option?.value);
                  dispatch(
                    GetProductionLineByProduction(
                      option?.value,
                      startDate,
                      toDate
                    )
                  );
                  setValue("productType", "");
                  setValue("nsmType", "");
                  setValue("dsmType", "");
                  setValue("rmType", "");
                  setValue("tsmType", "");
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">Condition</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={condition} />}
                rules={{ required: false }}
                name="conditionType"
                register={register}
                placeholder="Select Condition type"
                isClearable
                onChange={(option) => {
                  setSelectConditionType(option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>

          {/* <div className="col-lg-2">
                        <Form.Group as={Col} controlId="formGridState">
                            <label className="form-label">Category</label>
                            <RHFInput
                                className="formSelect pt-0 "
                                as={<Select options={categorytype} />}
                                rules={{ required: false }}
                                name="categoryType"
                                register={register}
                                placeholder="Select Category type"
                                isClearable
                                onChange={(option) => {
                                    handleChange('categoryType', option?.value);
                                    handleChange('categoryName', option?.label);
                                    setSelectCategoryType(option?.value)
                                    dispatch(GetProductType(option?.value,startDate,toDate));
                                }}
                                setValue={setValue}
                            />
                        </Form.Group>
                    </div> */}
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">SKU</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={proLinebyPro} />}
                rules={{ required: false }}
                name="productType"
                register={register}
                placeholder="Select Product type"
                isClearable
                onChange={(option) => {
                  handleChange("productType", option?.value);
                  setSelectProductType(option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          {/* <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">NSM</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={nsmtype} />}
                rules={{ required: false }}
                name="nsmType"
                register={register}
                placeholder="Select NSM type"
                isClearable
                onChange={(option) => {
                  handleChange("nsmType", option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">DSM</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={dsmtype} />}
                rules={{ required: false }}
                name="dsmType"
                register={register}
                placeholder="Select DSM type"
                isClearable
                onChange={(option) => {
                  handleChange("dsmType", option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">RM</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={rmtype} />}
                rules={{ required: false }}
                name="rmType"
                register={register}
                placeholder="Select RM type"
                isClearable
                onChange={(option) => {
                  handleChange("rmType", option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-2">
            <Form.Group as={Col} controlId="formGridState">
              <label className="form-label">TSM</label>
              <RHFInput
                className="formSelect pt-0 "
                as={<Select options={tsmtype} />}
                rules={{ required: false }}
                name="tsmType"
                register={register}
                placeholder="Select TSM type"
                isClearable
                onChange={(option) => {
                  handleChange("tsmType", option?.value);
                }}
                setValue={setValue}
              />
            </Form.Group>
          </div> */}

          <div className="col-lg-1">
            <button
              className="button-621 mt-5"
              onClick={() => handleShowProductLineReport()}
            >
              Show
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CMAnalysisInput;
