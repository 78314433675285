import React, { useEffect, useMemo, useState } from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
const PER_PAGE = 16;
const SalesMixingMonitoringTable = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const salesMixingData = useSelector(
    (state) => state.productionLine.salesMixingMonitoringTableData
  );
  console.log("salesMixingData", salesMixingData);
  const isLoading = useSelector(
    (state) => state.productionLine.toSalesMonitoringTableLoading
  );
  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  const pageCount = Math.ceil(salesMixingData.length / PER_PAGE);
  return (
    <div className=" card ProductionLineTable ">
      <div className="text-center bg-success pdfBGC2 pt-2 text-white rounded-top">
        <h5 className="pdfFont2C">Sales Mixing Monitoring</h5>
      </div>
      {isLoading && (
        <span className="spineercolor mt-5 mb-5">
          <LoadingSpinner />
        </span>
      )}
      <>
        {!isLoading && (
          <div>
            <div className="text-center pt-2">
              <h6>Comparison with CM%</h6>
            </div>
            <div className="salesMonitoringTable">
              <table>
                <thead>
                  <tr className="text-center">
                    <th className="p-1">Production Line</th>
                    <th>SKU</th>
                    <th>Current CM %</th>
                    <th>MTD-Act Sales Mix %</th>
                    <th>Std Sales Mixing %</th>

                    <th className="p-1"> KPI </th>
                    <th className="p-1"> </th>
                  </tr>
                </thead>
                <tbody className="border">
                  {salesMixingData
                    .slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE)
                    .map((d) => (
                      <tr key={d.productId}>
                        <td>{d.categoryName}</td>
                        <td>{d.productName}</td>
                        <td>{parseFloat(d.currentCM).toFixed(2)}% </td>
                        <td>{parseFloat((d.deliveryQty/d.lineQty)*100).toFixed(2)} %</td>
                        {/* <td>{d.skuWiseSales}</td> */}
                        {/* <td>{parseFloat(d.productionLineQty/d.skuWiseSales).toFixed(2)}</td> */}
                        <td>{parseFloat(d.stdCapacity).toFixed(2)} %</td>

                        {/* <td>

                          {parseFloat(d.mtdAccualSales-d.stdCapacity).toFixed(2)>0? <span><i className="fas fa-arrow-down text-success  item-list-icon"></i> {parseFloat(d.mtdAccualSales-d.stdCapacity).toFixed(2)}</span>:<span><i className="fas fa-arrow-up text-danger item-list-icon"></i> {parseFloat(d.mtdAccualSales-d.stdCapacity).toFixed(2)}</span>}

                          
                          
                        </td> */}
                        <td>
                          <Dot value={parseFloat(d.currentCM).toFixed(0)} />
                        </td>
                        <td>
                          <Arrow
                            value={parseFloat(d.currentCM).toFixed(0)}
                            mtdSalesMix={d.mtdAccualSales}
                            stdSales={d.stdCapacity}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <div className="pagination mb-2">
                {Array.from({ length: pageCount }, (_, i) => {
                  if (i === 0) {
                    return (
                      <a
                        href="#"
                        key={i}
                        onClick={(e) => handleClick(e, i + 1)}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        {i + 1}
                      </a>
                    );
                  } else if (i === pageCount - 1) {
                    return (
                      <a
                        href="#"
                        key={i}
                        onClick={(e) => handleClick(e, i + 1)}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        {i + 1}
                      </a>
                    );
                  } else if (
                    i === currentPage - 1 ||
                    i === currentPage ||
                    i === currentPage + 1 ||
                    i === pageCount - 2
                  ) {
                    return (
                      <a
                        href="#"
                        key={i}
                        onClick={(e) => handleClick(e, i + 1)}
                        className={currentPage === i + 1 ? "active" : ""}
                      >
                        {i + 1}
                      </a>
                    );
                  } else if (i === pageCount - 3) {
                    return (
                      <span key={i} className="dots">
                        ...
                      </span>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>

            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default SalesMixingMonitoringTable;

const Dot = ({ value }) => {
  let dotColor;

  if (value <= 5) {
    dotColor = "red";
  } else if (value >= 6 && value <= 12) {
    dotColor = "yellow";
  } else if (value >= 12) {
    dotColor = "green";
  }

  return (
    <span
      style={{
        backgroundColor: dotColor,
        width: "10px",
        height: "10px",
        borderRadius: "50%",
        display: "inline-block",
      }}
    ></span>
  );
};
const Arrow = ({ value, mtdSalesMix, stdSales }) => {
  let arrow;

  if (value <= 5) {
    arrow =
      mtdSalesMix > stdSales ? (
        <div className="redArrow d-flex flex">
          <i class="fas fa-arrow-down"></i>
          <i class="fas fa-arrow-down"></i>
        </div>
      ) : (
        <div className="greenArrow">
          <i class="fas fa-arrow-up"></i>
          <i class="fas fa-arrow-up"></i>
        </div>
      );
  } else if (value >= 6 && value <= 12) {
    arrow =
      mtdSalesMix > stdSales ? (
        <i class="fas fa-arrow-down"></i>
      ) : (
        <i class="fas fa-arrow-up"></i>
      );
  } else if (value >= 12) {
    arrow =
      mtdSalesMix > stdSales ? (
        <i class="fas fa-arrow-up"></i>
      ) : (
        <i class="fas fa-arrow-down"></i>
      );
  }

  return (
    <div>
      <span className="cm-Arrow">{arrow}</span>
    </div>
  );
};
