import React from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { currentdate } from '../../Accounts/Components/DateHelper';

const DownloadButton = (props) => {
    const current = currentdate();
    const excelName = props.excelname === undefined ? "TableExcel" : props.excelname + " " + current;

    const handleExportClick = () => {
        // Trigger the download programmatically
        const element = document.getElementById('test-table-xls-button');
        if (element) {
            element.click();
        }
    };

    return (
        <>
         
            <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="excelBtn visually-hidden button-010" // Add a CSS class to visually hide the button
                table="table-to-xls"
                filename={excelName}
                sheet="tablexls"
                buttonText="Export"
            />
        </>
    );
};

export default DownloadButton;
