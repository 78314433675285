import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from "react-bootstrap";
import { FaAngleDoubleDown } from 'react-icons/fa';
import { CategoryWiseDivisonAchDataVw, DivisionWiseImsSummeryVW, currentMonthWorkingDays, dailyImsSummeryreport } from '../Redux/MapAction';
import LoadingSpinner from '../../modules/Billing/Components/LoadingSpinner';
import { numberFormat } from '../../Global/numberFormat';
import DownloadDropdown from "../../modules/master/PrintDwopdown/ExcelDawnload";


const DailyImsReportSummery = () => {

    const currentDate = new Date();
    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };
    console.log('selectedMonth', selectedMonth)
    const handleSummeryReport = async () => {
        dispatch(CategoryWiseDivisonAchDataVw(selectedMonth))
        await dispatch(currentMonthWorkingDays())


    }
    const { register, setValue } = useForm();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const dispatch = useDispatch();

    const workingDays = useSelector(state => state?.mapData?.currentMonhWorking?.allData);
    const dailyImsData = useSelector(state => state?.mapData?.dailyImsData?.allData);
    const headerData = useSelector(state => state?.mapData?.dailyImsData?.skippedData);
    const dailyImsDataisLoading = useSelector(state => state?.mapData?.dailyImsDataisLoading);


    useEffect(() => {
        initialData();
    }, [dispatch])

    const initialData = async () => {
        await dispatch(dailyImsSummeryreport(selectedMonth))
        await dispatch(currentMonthWorkingDays())


    }


    return (
        <div className='container ims-division-wise-report'>
            <div className=' container-fluid top_card3-map rounded mb-5'>
                <Accordion activeKey={isAccordionOpen ? "1" : "0"}>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className="arrayButton" onClick={handleAccordionToggle}>
                        <div>
                            <div className="dailysales text-center dailysalesmargin dailysalesfont row  ">
                                <span className="sales-title-map" style={{ width: '550px', margin: 'auto' }} > Daily Ims Report Summery
                                    <div>
                                        <FaAngleDoubleDown className="float-right toparrow blink-text dropdownArrow" />
                                        <span className="clickHere float-right">
                                            {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1" onEnter={() => setIsAccordionOpen(true)} onExit={() => setIsAccordionOpen(false)}>
                        <div>
                            <div className="row d-flex justify-content-center bg-blue ">
                                <div className="col-md-1.5 d-flex align-items-center justify-content-md-start justify-content-center">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label text-light mb-0 text-right mr-3">Select Month </label>
                                        <div>
                                            <input
                                                type="month"
                                                id="monthPicker"
                                                value={selectedMonth}
                                                onChange={handleMonthChange}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                                <div className="col-md-1.5 d-flex justify-content-center mt-3 ml-5">
                                    <button className="btn btn-primary btn-sm " onClick={() => handleSummeryReport()}>Filter</button>
                                </div>
                            </div>

                        </div>

                    </Accordion.Collapse>
                </Accordion>
            </div>
            {
                dailyImsDataisLoading &&
                <LoadingSpinner />

            }

            {!dailyImsDataisLoading && <p className='export-for-excel'>
                <DownloadDropdown excelname='Daily Wise divison ach  Report' />

            </p>

            }

            {!dailyImsDataisLoading &&
                <div className="table-container">

                    <table id="table-to-xls" className="territory-table">
                        <thead className='header-working'>
                            <th>  </th>
                            <th> Working days:  {workingDays?.totWorkingDay}</th>
                            <th></th>
                            <th>  </th>
                            <th></th>
                            <th></th>
                            <th>  </th>
                            <th></th>
                            <th> Working days Pass: {workingDays?.passWorkingDay}  </th>
                            <th>  </th>
                            <th></th>
                            <th></th>
                            <th>  </th>
                            <th></th>
                            <th>  Need IMS: {workingDays?.imsTarget.toFixed(2)}% </th>
                            <th></th>
                            <th></th>
                            <th>  </th>
                            <th></th>
                            <th></th>
                            <th>  </th>
                            <th></th>

                        </thead>
                        <thead>
                            <tr>
                                <th> Name of the
                                    Division</th>
                                <th>So QTY</th>
                                <th> Online Target (Lac)</th>
                                <th>IMS Target (Lac)</th>
                                <th> Order <br />
                                    {headerData?.oraderDateTime}
                                </th>
                                <th> Ims(C.M) <br />
                                    {headerData?.imsCmDate}
                                </th>
                                <th> Ims(L.M) <br />
                                    {headerData?.imsLmDate}

                                </th>
                                <th> Non Ex Value <br />
                                    {headerData?.nonExecutionValueDate}

                                </th>
                                <th> Per So Ims <br />
                                    {headerData?.perSoImsDate}

                                </th>

                                <th> No Excution
                                    %
                                </th>
                                <th>Today's Fund Plan</th>

                                <th> Cum. Ims    {headerData?.cumImsDate}
                                    <br />

                                </th>
                                <th> Ach. Ims %  <br />

                                </th>

                                <th>  Landing % <br />

                                </th>

                                <th> Cum. Ims   {headerData?.cumImsLastMonthDate}
                                    <br />

                                </th>

                                <th> Gw over Prev. Month <br />

                                </th>

                                <th>  Short End of   {headerData?.shortEndOffCMonthDate}
                                    <br />

                                </th>
                                <th>   Last day lifting {headerData?.lastDayLiftingDate}
                                    <br />

                                </th>
                                <th>  Cum. lifting  {headerData?.cumLiftingDate}
                                    <br />

                                </th>
                                <th>  Last day Collection <br />
                                    {headerData?.lastDayCollectionDate}

                                </th>
                                <th>  Cum, Fund Collection 2024<br />
                                    {headerData?.cumFundCollectionDate}

                                </th>

                                <th>
                                    Ach. Online %
                                </th>


                            </tr>
                        </thead>
                        <tbody className="territory-tr">
                            {dailyImsData?.map((item, index) => (
                                <tr key={index} className={`${item?.divisionName === "North" || item?.divisionName === "South" ? 'font-weights' : ''}`}
                                    style={{ backgroundColor: item?.divisionName === "National" ? 'yellow' : '' }}>
                                    <td>{item?.divisionName} </td>
                                    <td>{item?.totSo} </td>
                                    <td>{item?.onlineTarget} </td>
                                    <td> {item?.imsTarget}</td>
                                    <td> {item?.oraderDate}</td>
                                    <td  > {item?.imsCm}</td>
                                    <td>{item?.imsLm} </td>
                                    <td>{item?.nonExecutionValue} </td>
                                    <td>{item?.perSoIms} </td>
                                    <td>{item?.nonExecutionPer}% </td>
                                    <td> {item?.todayFundPlan}</td>
                                    <td> {item?.cumImsLastMonth}</td>
                                    <td  > {item?.achImsPer}%</td>
                                    <td  > {item?.landing}%</td>
                                    <td  > {item?.cumIms}</td>
                                    <td>{item?.gwOverPrevMonth}% </td>
                                    <td>{item?.shortEndOffCMonth}</td>
                                    <td>{item?.lastDayLifting}</td>
                                    <td> {item?.cumLifting}</td>
                                    <td> {item?.lastDayCollection}</td>
                                    <td  > {item?.cumFundCollection}</td>
                                    <td>{item?.achOnline} %</td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>

            }


        </div >
    );
};

export default DailyImsReportSummery;