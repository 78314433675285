import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const MapNavigation = () => {
  const [switchZone, setSwitchZone] = useState(null);
  const [switchDivision, setSwitchDivision] = useState(null);
  console.log('switch', switchZone)
  const navigate = useHistory();
  const location = useLocation();

  const changeDivision = (division) => {
    switch (division) {
      case "Dhaka":
        navigate.push("/sales-dashboard/division/dhaka");
        break;
      case "Chittagong":
        navigate.push("/sales-dashboard/division/chattogram");
        break;
      case "Khulna":
        navigate.push("/sales-dashboard/division/khulna");
        break;
      case "Barisal":
        navigate.push("/sales-dashboard/division/barishal");
        break;
      case "Sylhet":
        navigate.push("/sales-dashboard/division/sylhet");
        break;
      case "cumilla":
          navigate.push("/sales-dashboard/division/cumilla");
          break;
      case "Mymensingh":
        navigate.push("/sales-dashboard/division/mymensingh");
        break;
      case "North_BB":
        navigate.push("/sales-dashboard/division/north_BB");
        break;
      case "South_BB":
        navigate.push("/sales-dashboard/division/south_BB");
        break;
      case "North_Bengal":
        navigate.push("/sales-dashboard/division/north_bengal");
        break;
      case "Select":
        navigate.push("/sales-dashboard");
        break;
      default:
        break;
    }
  };

  const changeZone = (zone) => {
    switch (zone) {
      case "North":
        navigate.push("/sales-dashboard/zone/north");
        break;
      case "South":
        navigate.push("/sales-dashboard/zone/south");
        break;
      case "National":
        navigate.push("/sales-dashboard/zone/national");
        break;
      case "Select":
        navigate.push("/sales-dashboard/zone");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (location.pathname === "/sales-dashboard" || location.pathname === "/sales-dashboard/division" || location.pathname === "/sales-dashboard/zone") {
      setSwitchDivision("Select");
      setSwitchZone("Select");
    } else if (location.pathname.includes("dhaka")) {
      setSwitchDivision("Dhaka");
    } else if (location.pathname.includes("chittagong")) {
      setSwitchDivision("Chittagong");
    } else if (location.pathname.includes("khulna")) {
      setSwitchDivision("Khulna");
    } else if (location.pathname.includes("barishal")) {
      setSwitchDivision("Barishal");
    } else if (location.pathname.includes("sylhet")) {
      setSwitchDivision("Sylhet");
    } else if (location.pathname.includes("mymensingh")) {
      setSwitchDivision("Mymensingh");
    } else if (location.pathname.includes("north_BB")) {
      setSwitchDivision("North_BB");
    } else if (location.pathname.includes("south_BB")) {
      setSwitchDivision("South_BB");
    } else if (location.pathname.includes("north_bengal")) {
      setSwitchDivision("North_Bengal");
    }
    else if (location.pathname.includes("cumilla")) {
      setSwitchDivision("cumilla");
    }
    else if (location.pathname.includes("north")) {
      setSwitchZone("North");
    } else if (location.pathname.includes("south")) {
      setSwitchZone("South");
    } else if (location.pathname.includes("national")) {
      setSwitchZone("National");
    }
  }, [location]);

  return (
    <>
      <div className="mb-3">
        <select
          className="form-control"
          value={switchZone || ""}
          onChange={(e) => {
            changeZone(e.target.value);
          }}
        >
          <option value="Select">Select Zone</option>
          <option value="North">North</option>
          <option value="South">South</option>
          <option value="National">National</option>
        </select>
      </div>

      <div className="mb-4">
        <select
          className="form-control"
          value={switchDivision || ""}
          onChange={(e) => {
            changeDivision(e.target.value);
          }}
        >
          <option value="Select">Select Division</option>
          <option value="Dhaka">Dhaka</option>
          <option value="Chittagong">Chittagong</option>
          <option value="Khulna">Khulna</option>
          <option value="Barisal">Barisal</option>
          <option value="Sylhet">Sylhet</option>
          <option value="cumilla">Cumilla</option>
          <option value="Mymensingh">Mymensingh</option>
          <option value="North_BB">North BB</option>
          <option value="South_BB">South BB</option>
          <option value="North_Bengal">North Bengal</option>
        </select>
      </div>
    </>
  );
};

export default MapNavigation;
