import React from "react";
import { useSelector } from "react-redux";
import { Spring } from "react-spring";

const VerticalProgress = ({ progress }) => {
  const Primaryachivement = useSelector((state) => state.dashboardcart.Primaryachivement);
  var checkNan = isNaN(Primaryachivement)?0:Primaryachivement; 
  return (
    <Spring from={{ percent: 0 }} to={{ percent: checkNan }}>
      {({ percent }) => (
        <><div> <span className="mr-2">100%</span></div>
         <div className="progress vertical vertical_bar_bottom_color_1">
          <div style={{height: `${checkNan}%`}} className="progress-bar">
            <span>{`${checkNan}%`}</span>
          </div>
        </div>
        </>
       
      )}
    </Spring>
  );
};

export default VerticalProgress;
