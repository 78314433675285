import Axios from "axios";
import * as Types from "./DashboardType";

export const InputReportHandle = (name, value) => (dispatch) => {
  const formData = {
    name: name,
    value: value,
  };
  dispatch({ type: Types.CHANGE_PRODUCT_INPUT_REPORT, payload: formData });
};
export const getProductionLineData = () => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({
    type: Types.GET_PRODUCTION_LINE_DATA,
    payload: responseList,
  });
  // let url = "https://jsonplaceholder.typicode.com/posts";
  // await Axios.get(url).then((res) => {
  responseList.data = [
    1520,
    12545,
    1545,
    1457,
    1225,
    4568,
    5454,
    4547,
    4585,
    4574,
    4548,
    136,
    4855,
  ];
  responseList.isLoading = false;
  // });
  dispatch({
    type: Types.GET_PRODUCTION_LINE_DATA,
    payload: responseList,
  });
};
export const getProductionLineTableData = (ZoneID, DivisionID, AreaID, Territory, Fdate, Tdate, category, ProductId, ProductGroup, productionLine) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({
    type: Types.GET_PRODUCTION_LINE_TABLE_DATA,
    payload: responseList,
  });
  let url = `${process.env.REACT_APP_API_PUBLIC_URL}SalesStockMonitorinTable?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`;
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`;
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`;
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`;
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`;
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`;
  }
  if (category !== null) {
    url += `&category=${category}`;
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`;
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`;
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`;
  }

  try {
    const response = await Axios.get(url);
    responseList.data = response.data.data == null ? [] : response.data.data;
    responseList.isLoading = false;
    dispatch({
      type: Types.GET_PRODUCTION_LINE_TABLE_DATA,
      payload: responseList,
    });
  } catch (error) {
    // Handle any error that occurred during the API request
    console.log('Error:', error);
  }
};

export const getSalesMixingMonitoringData = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine ) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({
    type: Types.GET_SALES_MIXING_MONITORING_DATA,
    payload: responseList,
  });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}SalesStockMonitoringSkuWiseGraph?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`;
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`;
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`;
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`;
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`;
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`;
  }
  if (category !== null) {
    url += `&category=${category}`;
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`;
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`;
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`;
  }
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
  });
  dispatch({
    type: Types.GET_SALES_MIXING_MONITORING_DATA,
    payload: responseList,
  });
};
export const getSalesMixingMonitoringTableData = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine ) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({
    type: Types.GET_SALES_MIXING_MONITORING_TABLE_DATA,
    payload: responseList,
  });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}stockComparisionwithCM?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`;
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`;
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`;
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`;
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`;
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`;
  }
  if (category !== null) {
    url += `&category=${category}`;
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`;
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`;
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`;
  }
  await Axios.get(url).then((res) => {
  
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
  });
  dispatch({
    type: Types.GET_SALES_MIXING_MONITORING_TABLE_DATA,
    payload: responseList,
  });
};
export const getProductionandStockMonitoringGraph = (ZoneID, DivisionID, AreaID, Territory,Fdate,Tdate,category,ProductId,ProductGroup,productionLine ) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({
    type: Types.GET_PRODUCTION_AND_STOCK_MONITORING_GRAPH,
    payload: responseList,
  });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}ProductionandStockMonitoringGraph?`;

  if (ZoneID !== null) {
    url += `&ZoneID=${ZoneID}`;
  }
  if (DivisionID !== null) {
    url += `&DivisionID=${DivisionID}`;
  }
  if (AreaID !== null) {
    url += `&AreaID=${AreaID}`;
  }
  if (Territory !== null) {
    url += `&Territory=${Territory}`;
  }
  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`;
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`;
  }
  if (category !== null) {
    url += `&category=${category}`;
  }
  if (ProductId !== null) {
    url += `&ProductId=${ProductId}`;
  }
  if (ProductGroup !== null) {
    url += `&salesGroupId=${ProductGroup}`;
  }
  if (productionLine !== null) {
    url += `&productionLine=${productionLine}`;
  }
  await Axios.get(url).then((res) => {
    console.log('res production & stock monitoring', res);
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
  });
  dispatch({
    type: Types.GET_PRODUCTION_AND_STOCK_MONITORING_GRAPH,
    payload: responseList,
  });
};
export const getCumulativeReport = (Fdate,Tdate) => async (dispatch) => {
  let responseList = {
    isLoading: true,
    data: {},
    status: true,
  };
  dispatch({
    type: Types.GET_CUMULATIVE_REPORT,
    payload: responseList,
  });
  let url = "";
  url = `${process.env.REACT_APP_API_PUBLIC_URL}AblCumulativeSalesReport?`;

  if (Fdate !== null) {
    url += `&Fdate=${Fdate}`;
  }
  if (Tdate !== null) {
    url += `&Tdate=${Tdate}`;
  }
  console.log('url report', url)
  await Axios.get(url).then((res) => {
    responseList.data = res.data.data == null ? [] : res.data.data;
    responseList.isLoading = false;
  });
  dispatch({
    type: Types.GET_CUMULATIVE_REPORT,
    payload: responseList,
  });
};
