export const GET_OVER_ALL_DIVISION= "GET_OVER_ALL_DIVISION";
export const GET_OVER_ALL_ZONE= "GET_OVER_ALL_ZONE";
export const GET_OVER_ALL_REGION= "GET_OVER_ALL_REGION";
export const GET_OVER_ALL_TERRITORY= "GET_OVER_ALL_TERRITORY";
export const GET_OVER_ALL_ZONE_DATA_FOR_MODAL= "GET_OVER_ALL_ZONE_DATA_FOR_MODAL";
export const GET_OVER_ALL_DIVISION_DATA_FOR_MODAL= "GET_OVER_ALL_DIVISION_DATA_FOR_MODAL";
export const GET_OVER_ALL_REGION_FOR_MODAL= "GET_OVER_ALL_REGION_FOR_MODAL";
export const GET_OVER_ALL_TERRITORY_FOR_MODAL= "GET_OVER_ALL_TERRITORY_FOR_MODAL";
export const GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL= "GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL";
export const GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL_BUTTOM= "GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL_BUTTOM";
export const GET_OVER_ALL_PERFORMANCE_BUTTOM_FOR_TOP= "GET_OVER_ALL_PERFORMANCE_BUTTOM_FOR_TOP";
export const GET_OVER_ALL_PERFORMANCE_BUTTOM_For_POOR_FAST= "GET_OVER_ALL_PERFORMANCE_BUTTOM_For_POOR_FAST";

export const GET_ZONE_DIVISION_LIST = "GET_ZONE_DIVISION_LIST";
export const GET_AREA_TERRITORY_LIST = "GET_AREA_TERRITORY_LIST";
export const GET_LOCATION_WISE_TOP_PERFORMERbISCKUT_CHANEL = "GET_LOCATION_WISE_TOP_PERFORMERbISCKUT_CHANEL";
export const GET_LOCATION_WISE_BOTTOM_PERFORMERbISCKUT_CHANEL = "GET_LOCATION_WISE_BOTTOM_PERFORMERbISCKUT_CHANEL";
export const GET_LOCATION_WISE_TOP_PERFORMER_FAST_CHANEL = "GET_LOCATION_WISE_TOP_PERFORMER_FAST_CHANEL";
export const GET_LOCATION_WISE_BOTTOM_PERFORMER_FAST_CHANEL = "GET_LOCATION_WISE_BOTTOM_PERFORMER_FAST_CHANEL";


//data view chart
export const GET_LOCATION_WISE_BISKUT_CHANEL_DATA_VIEW = "GET_LOCATION_WISE_BISKUT_CHANEL_DATA_VIEW";
export const GET_LOCATION_WISE_BOTTOM_BISKUT_CHANEL_DATA_VIEW = "GET_LOCATION_WISE_BOTTOM_BISKUT_CHANEL_DATA_VIEW";
export const GET_LOCATION_WISE_TOP_FAST_CHANEL_DATA_VIEW = "GET_LOCATION_WISE_TOP_FAST_CHANEL_DATA_VIEW";
export const GET_LOCATION_WISE_BOTTOM_FAST_CHANEL_DATA_VIEW = "GET_LOCATION_WISE_BOTTOM_FAST_CHANEL_DATA_VIEW";



//report


export const GEt_DIVISION_WISE_IMS_SUMMERY_VIEW = "GEt_DIVISION_WISE_IMS_SUMMERY_VIEW";
export const GEt_REGION_WISE_IMS_SUMMERY_VIEW = "GEt_REGION_WISE_IMS_SUMMERY_VIEW";
export const  BOTTOM_THREE_DSM_VIEW = "BOTTOM_THREE_DSM_VIEW";
export const  TOP_TEN_RM_VIEW = "TOP_TEN_RM_VIEW";
export const  BOTTOM_TEN_RM_VIEW = "BOTTOM_TEN_RM_VIEW";
export const  TOP_TWENTY_TSM_VIEW = "TOP_TWENTY_TSM_VIEW";
export const  BOTTOM_TWENTY_TSM_VIEW = "BOTTOM_TWENTY_TSM_VIEW";
export const  TOP_FIVE_DSM_VIEW = "TOP_FIVE_DSM_VIEW";
export const  HEIGEST_ACHIEVERS_TWENTY_SALES_OFFICERS = "HEIGEST_ACHIEVERS_TWENTY_SALES_OFFICERS";
export const  BOTTOM_TWENTY_SALES_OFFICERS = "BOTTOM_TWENTY_SALES_OFFICERS";
export const  IMS_SUMMERY_ALL_VIEW = "IMS_SUMMERY_ALL_VIEW";
export const  TOP_TWENTY_SALES_OFFICERS = "TOP_TWENTY_SALES_OFFICERS";
export const  BOTTOM_TWENTY_SALES_ACH_OFFICERS = "BOTTOM_TWENTY_SALES_ACH_OFFICERS";
export const  CATEGORY_WISE_DIVISON_ACHIVEMENT = "CATEGORY_WISE_DIVISON_ACHIVEMENT";
export const  FOCUS_PRODUCT_TARGET_VS_ACHIEMENT = "FOCUS_PRODUCT_TARGET_VS_ACHIEMENT";
export const  FOCUS_SKU_gTARGET_VS_ACHIEMENT = "FOCUS_SKU_gTARGET_VS_ACHIEMENT";
export const  TOP_SELLING_SKU = "TOP_SELLING_SKU";
export const  CURRENT_MONTH_WORKING_DAYS = "CURRENT_MONTH_WORKING_DAYS";
export const  DAILY_IMS_LIFTING_SUMMERY_DETAILS = "DAILY_IMS_LIFTING_SUMMERY_DETAILS";


//previous month

export const GET_PREVIOUS_MONTH_DIVISION_WISE_IMS_SUMMERY = "GET_PREVIOUS_MONTH_DIVISION_WISE_IMS_SUMMERY";
export const GET_PREVIOUS_MONTH_REGION_WISE_IMS_SUMMERY = "GET_PREVIOUS_MONTH_REGION_WISE_IMS_SUMMERY";
export const GET_PREVIOUS_MONTH_TOP_FIVE_DSM = "GET_PREVIOUS_MONTH_TOP_FIVE_DSM";
export const GET_PREVIOUS_MONTH_BOTTOM_THREE_DSM = "GET_PREVIOUS_MONTH_BOTTOM_THREE_DSM";
export const GET_PREVIOUS_MONTH_TOP_TEN_RM_DATA = "GET_PREVIOUS_MONTH_TOP_TEN_RM_DATA";
export const GET_PREVIOUS_MONTH_BOTTOM_TEN_RM_DATA = "GET_PREVIOUS_MONTH_BOTTOM_TEN_RM_DATA";
export const GET_PREVIOUS_MONTH_TOP_TWENTY_TSM_DATA = "GET_PREVIOUS_MONTH_TOP_TWENTY_TSM_DATA";
export const GET_PREVIOUS_MONTH_BOTTOM_TWENTY_TSM_DATA = "GET_PREVIOUS_MONTH_BOTTOM_TWENTY_TSM_DATA";
export const GET_PREVIOUS_MONTH_TOP_TWENTY_SO_DATA = "GET_PREVIOUS_MONTH_TOP_TWENTY_SO_DATA";
export const GET_PREVIOUS_MONTH_SO_VALUE_ACH_DATA = "GET_PREVIOUS_MONTH_SO_VALUE_ACH_DATA";
export const GET_PREVIOUS_MONTH_BOTTOM_SO_VALUE_ACH_DATA = "GET_PREVIOUS_MONTH_BOTTOM_SO_VALUE_ACH_DATA";
export const PREVIOUS_MONTH_WORKING_DAYS = "PREVIOUS_MONTH_WORKING_DAYS";

