import React, { useEffect } from "react";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import { getProductionLineData } from "./ProductionLineAction";
import faker from "faker";
import { numberFormat } from "./Money";
ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);
const ProductionLineUpdated = () => {
    const dispatch = useDispatch();
    const mtdAcctualProd = useSelector(
        (state) => state.productionLine.mtdAcctualProd
    );
    const reportData = useSelector((state) => state.productionLine.reportData);

    const totalDay = useSelector((state) => state.productionLine.totalDay);
    const totalCapacityQty = useSelector(
        (state) => state.productionLine.totalCapacityQty
    );
    const totalCapacityWeight = useSelector(
        (state) => state.productionLine.totalCapacityWeight
    );
    const totalCapacityValue = useSelector(
        (state) => state.productionLine.totalCapacityValue
    );
    const MtdAcctualProdVal = useSelector(
        (state) => state.productionLine.MtdAcctualProdVal
    );
    const Measurement = reportData.measurement;

    // UPDATED
    const actualStock = useSelector((state) => state.updateProductionLine.actualStock);
    const productionStock = useSelector((state) => state.updateProductionLine.productionStock);
    console.log('actualStock :>> ', actualStock);
    const actualStockOriginal = actualStock.map((item) => item.original);
  const actualStockFormatted = actualStock.map((item) => item.formatted);


    const actualStockLoading = useSelector((state) => state.updateProductionLine.actualStockLoading);
    const targetProduction = useSelector((state) => state.updateProductionLine.targetProduction);
    const TotalDays = useSelector((state) => state.updateProductionLine.TotalDays);
    const stockPosition = useSelector((state) => state.updateProductionLine.stockPosition);
    const averageSales = useSelector((state) => state.updateProductionLine.averageSales);


    useEffect(() => {
        dispatch(getProductionLineData());
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
                display: true,
                labels: {
                    usePointStyle: true,
                },
            },
        },
        datalabels: {
            display: true,
            color: "black",
        },
        interaction: {
            mode: 'index', // Enables tooltip mode on hover
            intersect: false,
        },
        animation: {
            duration: 0, // Set the duration to 0 for an immediate tooltip display
        },
        tooltip: {
            callbacks: {
              label: (context) => {
                const datasetLabel = context.dataset.label || "";
                let value = context.parsed.y;
                console.log('value :>> ', value);
                if (typeof value === "number") {
                  value = numberFormat(value);
                }
                return `${datasetLabel}: ${numberFormat(value)}`;
              },
            },
          },
    };
    
    const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
    const tgtPrd = [];
    for (let i = 0; i < labels.length; i++) {
        tgtPrd.push(mtdAcctualProd / 31);
    }
    console.log(tgtPrd);
    const data = {
        labels,
        datasets: [
            {
              type: "bar",
              label: "Act Stock",
              backgroundColor: "#71b345",
              data: actualStockOriginal,
              order: 5,
              borderColor: "#71b345",
              borderWidth: 0,
              datalabels: {
                display: true,
                color: "white",
                align: "bottom",
                anchor: "end",
                rotation: 270,
              },
            },
            {
              type: "line",
              label: "Act Prd",
              backgroundColor: "#EEB35A",
              borderColor: "#E39318",
              borderWidth: 2,
              fill: false,
              tension: "0.2",
              pointStyle: "circle",
              pointRadius: 3,
              pointHoverRadius: 5,
              datalabels: {
                display: false,
                color: "#EEB35A",
                font: {
                  weight: "bold",
                  size: 12,
                },
              },
              data: productionStock,
              order: 1, // Set a higher order value for the line chart to be drawn in front of the bar chart
            },
            {
              type: "line",
              label: "Tgt Prd",
              data: labels.map(() => faker.datatype.number({ min: targetProduction / 26, max: targetProduction / 26 })),
              backgroundColor: "#facc3c",
              borderColor: "#facc3c",
              pointBorder: "#facc3c",
              borderDash: [5, 5],
              order: 1,
              pointRadius: 0,
              pointStyle: "circle",
              datalabels: {
                display: false,
                color: "black",
              },
            }
          ],
          
    };
    return (
        <div className="card position-relative">
            <div className="text-center bg-success pdfBGC pt-2 text-white rounded-top">
                <h5 className="pdfFontC">Production & Stock Monitoring</h5>
            </div>
            {actualStockLoading && (
                <span className="spineercolor mt-5 mb-5">

                    <LoadingSpinner />
                </span>
            )}
            <>
                {!actualStockLoading && (
                    <>
                        <div className="monthlyPrdIdealWeight">
                            <small>Monthly PRD Ideal</small>
                            <br />
                            <small>Capacity - {" "}{numberFormat(totalCapacityQty)} CT</small> <br/>
                            <small>Capacity:{" "}{numberFormat(totalCapacityWeight)} KG</small><br/>
                            <small>Capacity:{" "}{numberFormat(parseInt(totalCapacityValue))} Tk</small>
                        </div>
                        {/* <div className="monthlyPrdIdealWeight">
                            <small>Monthly PRD Ideal</small>
                            <br />
                           
                        </div>
                        <div className="monthlyPrdIdealWeight">
                            <small>Monthly PRD Ideal</small>
                            <br />
                            
                        </div> */}
                        <div className="MTDActPrdData">
                            <small>MTD Act Prd:{" "}{numberFormat(mtdAcctualProd)} CT</small><br/>
                            <small>MTD Act Prd:{" "}{numberFormat(parseInt(MtdAcctualProdVal))} Val</small>
                        </div>
                        <div className="StockPosition">
                            <small>Stock Position: {numberFormat(parseInt(stockPosition))} CT</small>
                        </div>
                        <div className="AVGSales">
                            <small>{numberFormat(averageSales)} Days AVG Sales</small>
                        </div>
                        <Chart options={options} data={data} height={450} width={770} />
                    </>
                )}
            </>
        </div>
    );
};

export default ProductionLineUpdated;
