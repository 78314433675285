import React from "react";
import Nav from "../components/dashboard/Nav";
import PerformanceContainer from "../components/dashboard/PerformanceContainer";
import ColorRange from "../components/ColorRange";
import MapNavigation from "../components/MapNavigation";
import PerformanceFilters from "../components/PerformanceFilters";
import MapFilters from "../components/MapFilters";
import Routers from "../config/Routers";
import { useSelector } from 'react-redux';
import { Accordion, Button, Card } from "react-bootstrap";
import { useState } from "react";
import { FaAngleDoubleDown } from 'react-icons/fa';


const SalesDashboard = () => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const title = useSelector((state) => state.map.title);


  const [showColumn1, setShowColumn1] = useState(false);

  const toggleColumns = () => {
    setShowColumn1(!showColumn1);
  };

  const [showColumn2, setShowColumn2] = useState(false);

  const toggleColumns2 = () => {
    setShowColumn2(!showColumn2);
  };


  console.log('show', showColumn2)


  return (
    <div className="main-sales-com">
      <div
        className="container-fluid px-2 px-md-5"
        style={{ overflowX: "hidden" }}
      >
        <div className="row /* d-flex justify-content-center */">
          {showColumn1 &&


            <div className= {showColumn2 ? ' col-md-8  pt-2':'col-4  pt-2' }>
              <PerformanceFilters showColumn2={showColumn2} toggleColumns2={toggleColumns2} />
              {/*           <PerformanceContainer /> */}
            </div>

          }

        {
         ! showColumn2 &&
          <div className={showColumn1 ? 'col-md-7' : 'col-lg-12'}>

          <div className="top_card3-map">
            <MapFilters showColumn1={showColumn1} toggleColumns={toggleColumns} />
          </div>

          <div className="bg-primary text-light p-1 rounded mb-1">
            <h5 className="text-center mb-0">{title}</h5>
          </div>
          <div className="row map-view-full">
            <div className={showColumn1 ? 'col-md-3' : 'col-lg-2'}   /* "col-md-3 main-map" */>
              <div >
                <ColorRange />
                <MapNavigation />
              </div>
            </div>
            <div className="col-md-9 ">
              <Routers showColumn1={showColumn1} />
            </div>
          </div>
        </div>
        }

  
        </div>
      </div>
    </div>
  );
};

export default SalesDashboard;
