import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Accordion } from "react-bootstrap";
import { FaAngleDoubleDown } from 'react-icons/fa';
import { DivisionWiseImsSummeryVW, bottomTwentySalesOfficersvw, currentMonthWorkingDays, heighestAchieversTwentySalesOfficers } from '../Redux/MapAction';
import LoadingSpinner from '../../modules/Billing/Components/LoadingSpinner';
import { numberFormat } from '../../Global/numberFormat';
import DownloadDropdown from "../../modules/master/PrintDwopdown/ExcelDawnload";

const BottomTwentySalesOfficers = () => {

    const dispatch = useDispatch();
    const { register, setValue } = useForm();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const currentDate = new Date();
    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };
    console.log('selectedMonth', selectedMonth)
    const handleSummeryReport = async () => {
        dispatch(bottomTwentySalesOfficersvw(selectedMonth))

    }

    useEffect(() => {
        initialData();
    }, [dispatch])

    const initialData = async () => {
        await dispatch(bottomTwentySalesOfficersvw(selectedMonth))
        await dispatch(currentMonthWorkingDays())


    }
    const workingDays = useSelector(state => state?.mapData?.currentMonhWorking?.allData);
    const divisionDataViews = useSelector(state => state?.mapData?.bottomTwentySalesOfficers?.allData);
    const divisionDataViewIsLoading = useSelector(state => state?.mapData?.bottomTwentySalesOfficersIsloading);
    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];
    const monthNumber = divisionDataViews && divisionDataViews.length > 0 ? divisionDataViews[0]?.month : null;
    const monthName = monthNames[monthNumber - 1]; // Adjusting index since array is zero-based

    return (
        <div className='container ims-division-wise-report'>



            <div className=' container-fluid top_card3-map rounded mb-5'>
                <Accordion activeKey={isAccordionOpen ? "1" : "0"}>

                    <Accordion.Toggle as={Button} variant="link" eventKey="1" className="arrayButton" onClick={handleAccordionToggle}>
                        <div>
                            <div className="dailysales text-center dailysalesmargin dailysalesfont row  ">
                                <span className="sales-title-map" style={{ width: '450px', margin: 'auto' }} >Bottom twenty sales officers
                                    <div>
                                        <FaAngleDoubleDown className="float-right toparrow blink-text dropdownArrow" />
                                        <span className="clickHere float-right">
                                            {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                                        </span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1" onEnter={() => setIsAccordionOpen(true)} onExit={() => setIsAccordionOpen(false)}>
                        <div>
                            <div className="row d-flex justify-content-center bg-blue ">



                                <div className="col-md-1.5 d-flex align-items-center justify-content-md-start justify-content-center">
                                    <Form.Group as={Col} controlId="formGridState">
                                        <label className="form-label text-light mb-0 text-right mr-3">Select Month </label>
                                        <div>
                                            <input
                                                type="month"
                                                id="monthPicker"
                                                value={selectedMonth}
                                                onChange={handleMonthChange}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>


                                <div className="col-md-1.5 d-flex justify-content-center mt-3 ml-5">
                                    <button className="btn btn-primary btn-sm " onClick={() => handleSummeryReport()}>Filter</button>
                                </div>
                            </div>

                        </div>

                    </Accordion.Collapse>
                </Accordion>
            </div>
            {
                divisionDataViewIsLoading &&
                <LoadingSpinner />

            }

            {!divisionDataViewIsLoading && <>
                <p className='export-for-excel'>
                    <DownloadDropdown excelname='Bootom twenty sales officers' />

                </p>
                {divisionDataViews?.length <= 0 ? <p className='noAvailableata'>No Available data here</p> :

                    <div className="table-container">
                        <table id="table-to-xls" className="territory-table">
                            <thead className='header-working'>
                                <th>  </th>
                                <th> Working days:  {workingDays?.totWorkingDay}</th>
                                <th></th>
                                <th>  </th>
                                <th></th>
                                <th> Working days Pass: {workingDays?.passWorkingDay}  </th>
                                <th>  </th>
                                <th></th>
                                <th>  Need IMS: {workingDays?.imsTarget.toFixed(2)}% </th>
                                <th></th>

                            </thead>
                            <br />
                            <thead className='forFontstyle'>
                                <tr>
                                    <th> SI</th>
                                    <th> So</th>
                                    <th>Total IMS Target</th>
                                    <th>Total IMS <br /> {monthName}</th>
                                    <th>IMS Ach%</th>
                                    <th>IMS Landing %</th>
                                    <th>SO Qty </th>
                                    <th align='center' >IMS Growth% </th>
                                    <th>Average
                                        Per So
                                        Per Day
                                        IMS </th>
                                    <th>Ranking
                                        (tar Vs
                                        IMS
                                        Ach%)
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="territory-tr">
                                {divisionDataViews?.map((item, index) => (
                                    <tr key={index}>
                                        <td className='text-center-style heigtsetInHeader'>{index + 1}</td>
                                        <td className='text-center-style heigtsetInHeader'>{item?.sO_Point_Name}</td>
                                        <td>{numberFormat(item?.imsTarget)}</td>
                                        <td> {numberFormat(item?.totImsCm)}</td>
                                        <td> {item?.imsAchPer}%</td>
                                        <td className={` ${item?.imsLandingPer < 100 ? 'redColorStyle' : 'greenColorStyle'} text-center-style`} >{item?.imsLandingPer}%</td>
                                        <td className='text-center-style heigtsetInHeader'>{item?.totSo}</td>
                                        <td className={` ${item?.imsGrowth < 1 ? 'redColorStyle' : null} text-center-style`} > {item?.imsGrowth}%</td>
                                        <td className='text-center-style'> {numberFormat(item?.perSoIms)}</td>
                                        <td className='text-center-style'>{item?.imsRanking}</td>
                                    </tr>
                                ))}

                            </tbody>

                        </table>
                    </div>
                }
            </>

            }
        </div>
    );
};

export default BottomTwentySalesOfficers;