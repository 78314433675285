import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Pdf from "react-to-pdf";
import LoadingSpinner from '../Billing/Components/LoadingSpinner';
import Print from "../master/PrintDwopdown/Print";
import { getAblCumulativeSalesReportWithPackType } from './DashboardCartAction';

export const numberFormat = (number) => {
    if (number == null) {
        return 0
    }
    else {
        const value = number.toString();
        const formattedValue = value.replace(/(\d)(?=(\d{2})+\d$)/g, "$1,");
        return formattedValue
    }
};

const ReportGroupA = () => {

    const reportDataLoading = useSelector((state) => state.dashboardcart.reportDataLoading);

    const reportData = useSelector((state) => state.dashboardcart.reportDataGroupA);
    const componentRef = useRef();
    const dispatch = useDispatch();
    console.log('reportData.standardPack?.standardPackTotal :>> ', reportData.standardPack?.standardPackTotal);




    return (
        <div className='card fullcardbg m-3'>
            <div className="row mt-3 mr-2">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div class="btn-group btn-group-sm float-right" role="group">
                        <button type="button" class="btn  "><Print refvalue={componentRef} /></button>
                        {/* <button type="button" class="btn btn-secondary">
                            <Pdf
                                size="A4"
                                targetRef={componentRef}
                                filename="Details"
                                x={2}
                                y={2}
                                className='pdf-container'
                            >
                                {({ toPdf }) => (
                                    <div onClick={toPdf}>
                                        <i className="far fa-file-pdf"></i>
                                        <span className="ml-3">Pdf</span>
                                    </div>
                                )}
                            </Pdf>
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="row" ref={componentRef}>
                <div className="col-lg-12">
                    <div className=" col-lg-12 text-center text-uppercase text-bold">
                        <button className="button-010" >GROUP - A</button>
                    </div>
                </div>
                {/* <div className='card offset-lg-1 col-lg-10 bg-light'>
                    <div className="row">
                        <div className="col-lg-6 p-4">
                            <div className="div1">
                                <p className="text-uppercase txtsize">
                                    CSD Today's DO: <span className="text-primary">0</span>
                                </p>
                                <p className="text-uppercase txtsize">
                                    Previous DO: <span className="text-primary">0</span>
                                </p>
                                <p className="text-uppercase txtsize">

                                    Total: <span className="text-primary">0</span>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 p-4">
                            <div className="div2">
                                <p className="text-uppercase txtsize">
                                    Instt. Today's DO: <span className="text-primary">0</span>
                                </p>
                                <p className="text-uppercase txtsize">
                                    Previous DO: <span className="text-primary">0</span>
                                </p>
                                <p className="text-uppercase txtsize">
                                    Total: <span className="text-primary">0</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className=" col-lg-12 p-4 text-center text-uppercase text-bold">
                    <button className="button-0101 grandtotal" >GRAND TOTAL - {numberFormat(Math.floor(reportData?.totalTarget))}</button>
                </div>
                <div className='col-lg-12'>
                    {reportDataLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5 pr-5 pb-5 ">
                        <table className="table table-head-custom table-vertical-center  item-add-table borderTableims mb-5">
                            {reportData.standardPack?.length > 0 &&
                                <thead >
                                    <tr className="backcolor">
                                        <th colSpan="7" className="text-center">STANDARD PACK</th>
                                    </tr>
                                    <tr>
                                        <th>Product</th>
                                        <th>Target</th>
                                        <th>Today's Lifting</th>
                                        <th>Lifting Upto</th>
                                        <th>Cumalative</th>
                                        <th>Difference</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {reportData.standardPack?.length > 0 &&
                                    reportData.standardPack?.map((item, index) => {
                                        const percentage = Math.floor(((item.todaysLifting + item.liftingUpto) / item.target) * 100);
                                        const displayPercentage = isFinite(percentage) ? percentage : 0;

                                        return (
                                            <tr key={index}>
                                                <td>{item.productName}</td>
                                                <td>{numberFormat(Math.floor(item.target))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting))}</td>
                                                <td>{numberFormat(Math.floor(item.liftingUpto))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting + item.liftingUpto))}</td>
                                                <td>
                                                    <span style={{ color: displayPercentage < 100 ? 'red' : 'black' }}>
                                                        {displayPercentage}%
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {reportData.standardPack?.length > 0 &&
                                    <tr className='text-center'>
                                        <td>Total</td>
                                        <td>{numberFormat(Math.floor(reportData?.standardPackTotal))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.standardPackTodaysLifting))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.standardPackLiftingUpto))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.standardPackTodaysLifting + reportData?.standardPackLiftingUpto))}</td>
                                        <td>{Math.floor(((reportData?.standardPackTodaysLifting + reportData?.standardPackLiftingUpto) / reportData?.standardPackTotal) * 100)}%</td>
                                    </tr>}


                            </tbody>



                        </table>
                    </div>
                    {/* {!reportDataLoading && reportData.length === 0 && (
                        <div className="alert alert-warning mt-5">
                            Sorry ! List Not Found.
                        </div>
                    )} */}

                </div>
                <div className='col-lg-12'>
                    {reportDataLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5 pr-5 pb-5 ">
                        <table className="table table-head-custom table-vertical-center  item-add-table borderTableims mb-5">

                            {reportData.familyPack?.length > 0 &&
                                <thead >
                                    <tr className="backcolor1">
                                        <th colSpan="7" className="text-center">FAMILY PACK</th>
                                    </tr>
                                    <tr>
                                        <th>Product</th>
                                        <th>Target</th>
                                        <th>Today's Lifting</th>
                                        <th>Lifting Upto</th>
                                        <th>Cumalative</th>
                                        <th>Difference</th>
                                    </tr>
                                </thead>
                            }

                            <tbody>
                                {reportData.familyPack?.length > 0 &&
                                    reportData.familyPack?.map((item, index) => {
                                        const percentage = Math.floor(((item.todaysLifting + item.liftingUpto) / item.target) * 100);
                                        const displayPercentage = isFinite(percentage) ? percentage : 0;

                                        return (
                                            <tr key={index}>
                                                <td>{item.productName}</td>
                                                <td>{numberFormat(Math.floor(item.target))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting))}</td>
                                                <td>{numberFormat(Math.floor(item.liftingUpto))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting + item.liftingUpto))}</td>
                                                <td>
                                                    <span style={{ color: displayPercentage < 100 ? 'red' : 'black' }}>
                                                        {displayPercentage}%
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {reportData.familyPack?.length > 0 &&
                                    <tr className='text-center'>
                                        <td>Total</td>
                                        <td>{numberFormat(Math.floor(reportData?.familyPackTotal))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.familyPackTodaysLifting))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.familyPackLiftingUpto))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.familyPackTodaysLifting + reportData?.familyPackLiftingUpto))}</td>
                                        <td>{Math.floor(((reportData?.familyPackTodaysLifting + reportData?.familyPackLiftingUpto) / reportData?.familyPackTotal) * 100)}%</td>
                                    </tr>}

                            </tbody>

                        </table>
                        {/* {!reportDataLoading && reportData.length === 0 && (
                            <div className="alert alert-warning mt-5">
                                Sorry ! List Not Found.
                            </div>
                        )} */}
                    </div>


                </div>
                <div className='col-lg-12'>
                    {reportDataLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5 pr-5 pb-5 ">
                        <table className="table table-head-custom table-vertical-center  item-add-table borderTableims mb-5">
                            {reportData.miniPack?.length > 0 &&
                                <thead >
                                    <tr className="backcolor2">
                                        <th colSpan="7" className="text-center">MINI PACK</th>
                                    </tr>
                                    <tr>
                                        <th>Product</th>
                                        <th>Target</th>
                                        <th>Today's Lifting</th>
                                        <th>Lifting Upto</th>
                                        <th>Cumalative</th>
                                        <th>Difference</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {reportData.miniPack?.length > 0 &&
                                    reportData.miniPack?.map((item, index) => {
                                        const percentage = Math.floor(((item.todaysLifting + item.liftingUpto) / item.target) * 100);
                                        const displayPercentage = isFinite(percentage) ? percentage : 0;

                                        return (
                                            <tr key={index}>
                                                <td>{item.productName}</td>
                                                <td>{numberFormat(Math.floor(item.target))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting))}</td>
                                                <td>{numberFormat(Math.floor(item.liftingUpto))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting + item.liftingUpto))}</td>
                                                <td>
                                                    <span style={{ color: displayPercentage < 100 ? 'red' : 'black' }}>
                                                        {displayPercentage}%
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {reportData.miniPack?.length > 0 &&
                                    <tr className='text-center'>
                                        <td>Total</td>
                                        <td>{numberFormat(Math.floor(reportData?.miniPackTotal))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.miniPackTodaysLifting))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.miniPackLiftingUpto))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.miniPackTodaysLifting + reportData?.miniPackLiftingUpto))}</td>
                                        <td>{Math.floor(((reportData?.miniPackTodaysLifting + reportData?.miniPackLiftingUpto)) / reportData?.miniPackTotal) * 100}%</td>
                                    </tr>}

                            </tbody>
                        </table>
                    </div>
                    {/* {!reportDataLoading && reportData.length === 0 && (
                        <div className="alert alert-warning mt-5">
                            Sorry ! List Not Found.
                        </div>
                    )} */}

                </div>
                <div className='col-lg-12'>
                    {reportDataLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5 pr-5 pb-5 ">
                        <table className="table table-head-custom table-vertical-center  item-add-table borderTableims mb-5">
                            {reportData.combopack?.length > 0 &&
                                <thead >
                                    <tr className="backcolor">
                                        <th colSpan="7" className="text-center">COMBO PACK</th>
                                    </tr>
                                    <tr>
                                        <th>Product</th>
                                        <th>Target</th>
                                        <th>Today's Lifting</th>
                                        <th>Lifting Upto</th>
                                        <th>Cumalative</th>
                                        <th>Difference</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {reportData.combopack?.length > 0 &&
                                    reportData.combopack?.map((item, index) => {
                                        const percentage = Math.floor(((item.todaysLifting + item.liftingUpto) / item.target) * 100);
                                        const displayPercentage = isFinite(percentage) ? percentage : 0;

                                        return (
                                            <tr key={index}>
                                                <td>{item.productName}</td>
                                                <td>{numberFormat(Math.floor(item.target))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting))}</td>
                                                <td>{numberFormat(Math.floor(item.liftingUpto))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting + item.liftingUpto))}</td>
                                                <td>
                                                    <span style={{ color: displayPercentage < 100 ? 'red' : 'black' }}>
                                                        {displayPercentage}%
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {reportData.combopack?.length > 0 &&
                                    <tr className='text-center'>
                                        <td>Total</td>
                                        <td>{numberFormat(Math.floor(reportData?.combopackPackTotal))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.combopackPackTodaysLifting))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.combopackPackLiftingUpto))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.combopackPackTodaysLifting + reportData?.combopackPackLiftingUpto))}</td>
                                        <td>{Math.floor(((reportData?.combopackPackTodaysLifting + reportData?.combopackPackLiftingUpto) / reportData?.combopackPackTotal) * 100)}%</td>
                                    </tr>}


                            </tbody>



                        </table>
                    </div>
                    {/* {!reportDataLoading && reportData.length === 0 && (
                        <div className="alert alert-warning mt-5">
                            Sorry ! List Not Found.
                        </div>
                    )} */}

                </div>
                <div className='col-lg-12'>
                    {reportDataLoading && <LoadingSpinner text="Loading Items List..." />}
                    <div className="react-bootstrap-table table-responsive border-0 pl-5 pr-5 pb-5 ">
                        <table className="table table-head-custom table-vertical-center  item-add-table borderTableims mb-5">
                            {reportData.economyPack?.length > 0 &&
                                <thead >
                                    <tr className="backcolor">
                                        <th colSpan="7" className="text-center">ECONOMY PACK</th>
                                    </tr>
                                    <tr>
                                        <th>Product</th>
                                        <th>Target</th>
                                        <th>Today's Lifting</th>
                                        <th>Lifting Upto</th>
                                        <th>Cumalative</th>
                                        <th>Difference</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {reportData.economyPack?.length > 0 &&
                                    reportData.economyPack?.map((item, index) => {
                                        const percentage = Math.floor(((item.todaysLifting + item.liftingUpto) / item.target) * 100);
                                        const displayPercentage = isFinite(percentage) ? percentage : 0;

                                        return (
                                            <tr key={index}>
                                                <td>{item.productName}</td>
                                                <td>{numberFormat(Math.floor(item.target))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting))}</td>
                                                <td>{numberFormat(Math.floor(item.liftingUpto))}</td>
                                                <td>{numberFormat(Math.floor(item.todaysLifting + item.liftingUpto))}</td>
                                                <td>
                                                    <span style={{ color: displayPercentage < 100 ? 'red' : 'black' }}>
                                                        {displayPercentage}%
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                {reportData.economyPack?.length > 0 &&
                                    <tr className='text-center'>
                                        <td>Total</td>
                                        <td>{numberFormat(Math.floor(reportData?.economyPackTotal))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.economyTodaysLifting))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.economyLiftingUpto))}</td>
                                        <td>{numberFormat(Math.floor(reportData?.economyTodaysLifting + reportData?.economyLiftingUpto))}</td>
                                        <td>{Math.floor(((reportData?.economyTodaysLifting + reportData?.economyLiftingUpto) / reportData?.economyPackTotal) * 100)}%</td>
                                    </tr>}


                            </tbody>



                        </table>
                    </div>
                    {/* {!reportDataLoading && reportData.length === 0 && (
                        <div className="alert alert-warning mt-5">
                            Sorry ! List Not Found.
                        </div>
                    )} */}

                </div>
                <div className='col-lg-12'>
                    <div className='col-lg-12'>
                        {reportDataLoading && <LoadingSpinner text="Loading Items List..." />}
                        <div className="react-bootstrap-table table-responsive border-0 pl-5 pr-5 pb-5 ">
                            <table className="table table-head-custom table-vertical-center item-add-table borderTableims mb-5">
                                <thead className='text-center'>
                                    <tr>
                                        <th rowSpan="5" className="text-center">Biscuit</th>
                                        <th className="text-center">Standard Pack</th>
                                        <td> {numberFormat(Math.floor(reportData?.standardPackTotal))}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">Family Pack</th>
                                        <td>{numberFormat(Math.floor(reportData?.familyPackTotal))}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">Mini Pack</th>
                                        <td>{numberFormat(Math.floor(reportData?.miniPackTotal))}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">Combo Pack</th>
                                        <td>{numberFormat(Math.floor(reportData?.combopackPackTotal))}</td>
                                    </tr>
                                    <tr>
                                        <th className="text-center">Economy Pack</th>
                                        <td>{numberFormat(Math.floor(reportData?.economyPackTotal))}</td>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {/* <tr>
                                        <td colSpan="2" className='text-center'>Total Biscuit</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className='text-center'>Total Confectionery</td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className='text-center'>Total Biscuit & Total Confectionery </td>
                                        <td>0</td>
                                    </tr> */}
                                    <tr>
                                        <td colSpan="2" className='text-center'>Total</td>
                                        <td> {numberFormat(Math.floor(reportData?.totalTarget))}</td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>
                        {/* {!reportDataLoading && reportData.length === 0 && (
                            <div className="alert alert-warning mt-5">
                                Sorry ! List Not Found.
                            </div>
                        )} */}

                    </div>

                </div>
                {/* <div className='col-lg-12 text-center '><span >Akij Insaf IT</span></div> */}

            </div>
        </div>
    )
}

export default ReportGroupA

