import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { Chart } from "react-google-charts";

ChartJS.register(ArcElement, Tooltip, Legend);

export function LocationWiseSalesPieChart() {
  const isLoading = useSelector((state) => state.dashboardcart.locationLoading);
  const LocationWisePieData = useSelector(
    (state) => state.dashboardcart.LocationWisePieData
  );
  const zoneData = useSelector((state) => state.dashboardcart.zoneData);
  const total = useSelector((state) => state.dashboardcart.total);
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const Measurement = reportData.measurement;
  const chartData = useSelector((state) => state.dashboardcart.chartData);
  const locationTotalQty = useSelector((state) => state.dashboardcart.locationTotalQty);

  let nf = new Intl.NumberFormat("en-BD");

  const options = {
    responsive: true,
    chart: {
      width: 50,
    },
    plugins: {
      legend: {
        position: "bottom",
        display: true,
      },
      datalabels: {
        display: true,
        color: "white",
        formatter: function(value, context) {
          return value + " M";
        },
      },
    },

    scales: {
      y: {
        title: {
          display: true,
          text: "Millions",
          font: {
            size: 12,
          },
        },
      },
    },

    responsive: [
      {
        breakpoint: 780,
        options: {
          chart: {
            width: 100,
          },
        },
      },
      {
        breakpoint: 400,
        options: {
          chart: {
            width: 50,
          },
        },
      },
    ],
  };

  const data = {
    labels: zoneData,
    datasets: [
      {
        data: LocationWisePieData,
        backgroundColor: [
          "#0b8793",
          "#00C9FF",
          "#fcb045",
          "#ff9068",
          "#4e6af5",
          "#e86193",
          "#c973ff",
          "#927694",
        ],
        borderColor: [
          "#0b8793",
          "#00C9FF",
          "#fcb045",
          "#ff9068",
          "#4e6af5",
          "#e86193",
          "#c973ff",
          "#927694",
        ],
        borderWidth: 1,
      },
    ],
  };

  const optionsData = {
    is3D: true,
    colors: [
      "#0b8793",
      "#00C9FF",
      "#fcb045",
      "#ff9068",
      "#4e6af5",
      "#e86193",
      "#c973ff",
      "#927694",
    ],
    width: 500, // Set the width to 500 pixels
    height: 350,
    chartArea: { width: "100%",height:"85%"},
    pieSliceText: "value-and-percentage",
    pieSliceTextStyle: {
      color: "white",
      fontSize: 12,
    },
    legend: { position: 'right' },
  };
  const optionsDataLabel = {
    is3D: true,
    colors: [
      "#55a15e",
      "#00C9FF",
      "#fcb045",
      "#ff9068",
      "#4e6af5",
      "#e86193",
      "#c973ff",
      "#927694",
    ],
    width: 500, // Set the width to 500 pixels
    height: 300,
    chartArea: { width: "100%",height:"100%"},
    pieSliceText: "value-parcntage",
    pieSliceTextStyle: {
      color: "white",
      fontSize: 12,
    },
    legend: { position: 'right' },
  };
  return (
    <div className="mb-0 card">
      <span className="spineercolor mt-5 mb-5">
        {isLoading && <LoadingSpinner />}
      </span>
      <>
        {!isLoading && (
          <>
            <div className="locatioChartHead">
              <div className="text-center">
                <h5>Location Wise Sales at Million</h5>
              </div>
              <div className="text-center location">
                <h6>Total:{Measurement == 1 ? total + " M" : locationTotalQty} </h6>
              </div>
            </div>
            <div className="row googleChart">
              <div className="col-lg-1">
              </div>
              {/* <Pie options={options} data={data}  height="140"  plugins={[ChartDataLabels]} /> */}
              <Chart
                chartType="PieChart"
                data={chartData}
                options={optionsData}
              />
             
            </div>
          </>
        )}
      </>
    </div>
  );
}
