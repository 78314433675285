import React, { useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { getOverAllDivisionData, getOverAllDivisionModal, getOverAllRegionData, getOverAllRegionForModal, getOverAllTerritorynData, getOverAllZoneData, getOverAlltERRITORYForModal, getOverZoneDataForModal } from "../Redux/MapAction";
import { useEffect } from "react";
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { RHFInput } from "react-hook-form-input";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { GetProductionLine, GetProductionLineByProduction, InputReportHandle } from "../../modules/Cfo/DashboardCartAction";
import { Accordion } from "react-bootstrap";
import { FaAngleDoubleDown } from 'react-icons/fa';



const MapFilters = ({ toggleColumns, showColumn1 }) => {
  const dispatch = useDispatch();
  const { register, setValue } = useForm();
  const [selectProductionLine, setSelectProductionLine] = useState(0);
  const [selectProductId, setselectProductId] = useState(0);
  const productionLine = useSelector((state) => state.dashboardcart?.productionLine);
  const proLinebyPros = useSelector((state) => state.dashboardcart?.proLinebyPro);
  const handleChange = (name, value) => {
    dispatch(InputReportHandle(name, value));
  };


  console.log('productionLine', productionLine)

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const handleAccordionToggle = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  const title = useSelector((state) => state.map.title);


  console.log('channel.current.value', channel?.current.value)
  const saleType = useRef();
  const channel = useRef();
  const line = useRef();
  const sku = useRef();
  var d = new Date();
  var firstD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-01";
  var currentD = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2) + "";
  const [startDate, setStartDate] = useState(firstD);
  const [endDate, setEndDate] = useState(currentD);



  const filterData = () => {
    setIsAccordionOpen(false);

    let saleTypeValue = saleType.current.checked ? 2 : 1;
    dispatch(
      getOverAllDivisionData(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD,
        selectProductionLine,
        selectProductId
      )
    );
    dispatch(
      getOverAllZoneData(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD,
        selectProductionLine,
        selectProductId

      )
    );
    dispatch(
      getOverAllRegionData(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD,
        selectProductionLine,
        selectProductId
      )
    );


    dispatch(
      getOverAllTerritorynData(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD,
        selectProductionLine,
        selectProductId
      )
    );

    dispatch(
      getOverZoneDataForModal(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD,
        selectProductionLine,
        selectProductId
      )
    );

    dispatch(
      getOverAllDivisionModal(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD,
        selectProductionLine,
        selectProductId
      )
    );

    dispatch(
      getOverAllRegionForModal(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD,
        selectProductionLine,
        selectProductId
      )
    );
    dispatch(
      getOverAlltERRITORYForModal(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD,
        selectProductionLine,
        selectProductId
      )
    );

    dispatch(GetProductionLineByProduction(selectProductionLine, startDate || firstD,
      endDate || currentD));

    //setValue('productionLine', '')


  }


  useEffect(() => {
    initialData();
  }, [dispatch])

console.log('saleType.current.checked ? ', saleType?.current?.checked ? 2 : 1)
  const initialData = async () => {
    let saleTypeValue = saleType.current.checked ? 2 : 1;

    dispatch(
      getOverAllDivisionData(
        channel.current.value,
        saleTypeValue,
        firstD,
        currentD
      )
    );

    dispatch(
      getOverAllZoneData(
        channel.current.value,
        saleTypeValue,
        firstD,
        currentD
      )
    );

    dispatch(
      getOverAllRegionData(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD
      )
    );


    dispatch(
      getOverAllTerritorynData(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD
      )
    );

    dispatch(
      getOverZoneDataForModal(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD
      )
    );

    dispatch(
      getOverAllDivisionModal(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD
      )
    );

    dispatch(
      getOverAllRegionForModal(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD
      )
    );

    dispatch(
      getOverAlltERRITORYForModal(
        channel.current.value,
        saleTypeValue,
        startDate || firstD,
        endDate || currentD
      )
    );

    dispatch(GetProductionLine(0))

    dispatch(GetProductionLineByProduction(selectProductionLine,
      startDate || firstD,
      endDate || currentD,
    ));


  }


  const handleChangeStartDate = ({ currentTarget: input }) => {
    setStartDate(input.value);
  };

  const handleChangeTodate = ({ currentTarget: input }) => {
    setEndDate(input.value);
  };


  return (


    <div>
      <Accordion activeKey={isAccordionOpen ? "1" : "0"}>
        <Button variant="warning" onClick={toggleColumns}>{showColumn1 ? '<<' : '>>'}</Button>
        <Accordion.Toggle as={Button} variant="link" eventKey="1" className="arrayButton" onClick={handleAccordionToggle}>
          <div>
            <div className="dailysales text-center dailysalesmargin dailysalesfont row  ">


              <span className="sales-title-map" style={{ width: '500px', margin: 'auto' }}> Sales Performance Map
                <div>
                  <FaAngleDoubleDown className="float-right toparrow blink-text dropdownArrow" />
                  <span className="clickHere float-right">
                    {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                  </span>
                </div>
              </span>
            </div>

          </div>
        </Accordion.Toggle>

        <Accordion.Collapse eventKey="1" onEnter={() => setIsAccordionOpen(true)} onExit={() => setIsAccordionOpen(false)}>
          <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
            <div className="container-fluid bg-blue p-1 rounded mb-2">


              <div className="row d-flex justify-content-center  ">


                <div className={showColumn1 ? 'col-md-4' : 'col-md-2'}>
                  <div className="switch-container mt-5 mb-3">
                    <div>
                      <p className="mb-0 text-light">Primary </p>
                    </div>
                    <div>
                      <label className="switchMap">
                        <input type="checkbox" ref={saleType} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                    <div>
                      <p className="mb-0 text-light">Secondary </p>
                    </div>
                  </div>
                </div>


                <div className="col-md-1.5 d-flex align-items-center justify-content-md-end justify-content-center mb-md-0 mb-3">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label text-light mb-0 text-right mr-3">Form Date</label>
                    <Form.Control
                      type="date"
                      name="fromDate"
                      value={startDate}
                      className="form-control cusheight"
                      onChange={handleChangeStartDate}
                    />
                  </Form.Group>

                </div>
                <div className="col-md-1.5 d-flex align-items-center justify-content-md-start justify-content-center">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label text-light mb-0 text-right mr-3">To Date    </label>

                    <Form.Control
                      type="date"
                      placeholder="Enter Todate"
                      name="todate"
                      value={endDate}
                      className="form-control cusheight "
                      onChange={handleChangeTodate}
                    />
                  </Form.Group>
                </div>



                <div className="col-md-1.5">
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label text-light mb-0 text-right mr-3"> Channel</label>
                    <select className="form-control formSelect pt-0 " ref={channel}>
                      <option value="3">All</option>
                      <option value="1">Biscuit Channel</option>
                      <option value="2">Bread Bun Channel</option>


                    </select>
                  </Form.Group>
                </div>



                <div className={showColumn1 ? 'col-md-3' : 'col-md-2'}>

                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label text-light mb-0 text-right mr-3">Production Line</label>
                    <RHFInput
                      className="formSelect pt-0 "
                      as={<Select options={productionLine} />}
                      rules={{ required: false }}
                      name="productionLine"
                      register={register}
                      placeholder="Select Production Line"
                      isClearable
                      onChange={(option) => {
                        handleChange("productionLine", option?.value);
                        setSelectProductionLine(option?.value);
                        dispatch(
                          GetProductionLineByProduction(
                            option?.value,
                            startDate || firstD,
                            endDate || currentD
                          )
                        );
                        setValue("productType", "");
                        setselectProductId("");

                      }}
                      setValue={setValue}
                    />
                  </Form.Group>
                </div>

                <div className={showColumn1 ? 'col-md-4' : 'col-md-2'}>
                  <Form.Group as={Col} controlId="formGridState">
                    <label className="form-label text-light mb-0 text-right mr-3">SKU</label>
                    <RHFInput
                      className="formSelect pt-0 "
                      as={<Select options={proLinebyPros} />}
                      rules={{ required: false }}
                      name="productType"
                      register={register}
                      placeholder="Select Product type"
                      isClearable
                      onChange={(option) => {
                        handleChange("productType", option?.value);
                        setselectProductId(option?.value);
                      }}
                      setValue={setValue}
                    />
                  </Form.Group>
                </div>

                <div className="col-md-1.5">

                  <div className="   mt-5">
                    <button className="btn btn-primary" onClick={() => filterData()}>Filter</button>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </Accordion.Collapse>
      </Accordion>
    </div>

  );
};

export default MapFilters;
