import React, { useEffect, useState, useRef } from "react";
import { Accordion, Button, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";
import { AnualSalesSinceInception } from "./AnualSalesSinceInception";
import { CumulativeRevenueLast12Months } from "./CumulativeRevenueLast12Months";
import { DamageChart } from "./DamageChart";
import DashboardInput from "./DashboardInput";
import DoughnutChartLocationWIseSales from "./DoughnutChartLocationWIseSales";
import { LocationWiseSalesPieChart } from "./LocationWiseSalesPieChart";
import LocationWIseSalesPieCHart2 from "./LocationWIseSalesPieCHart2";
import Money from './Money'
import Money2 from './Money2'
import { PerDayTargetDashboard } from './PerDayTargetDashboard'
import { SecondarySalesTrending } from "./SecondarySalesTrending";
import { SecondarySalesTrendingPercentageWise } from "./SecondarySalesTrendingPercentageWise";
import VerticalProgress from './VerticalProgress'
import VerticalProgress2 from './VerticalProgress2'
import {FaAngleDoubleDown} from 'react-icons/fa';
import { PrimarySalesTrending } from "./PrimarySalesTrending";
import { PrimarySalesTrendingPercentageWise } from "./PrimarySalesTrendingPercentageWise";

const DashboardDemo = () => {
    
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
      };

    const dashboardInfo = useSelector((state) => state.dashboardcart.dashboardData);
  
    const ttldays = useSelector((state) => state.dashboardcart.ttldays);
    const reportData = useSelector((state) => state.dashboardcart.reportData);
    const isLoading = useSelector((state) => state.dashboardcart.toCardLoading);

    const Measurement = reportData.measurement;

    return (

        <div className="container-fluid mb-6 ">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 paddingremove col-xl-12 sticky-top zindexmodified">

                    <div className="top_card3">
                        <Accordion defaultActiveKey="0">

                            <Accordion.Toggle as={Button} variant="link" eventKey="1" className="arrayButton" onClick={handleAccordionToggle}>
                                <div>
                                    <div className="dailysales text-center dailysalesmargin dailysalesfont row">
                            
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                        Sales Performance Dashboard&nbsp;
                                        <div>
                                            <FaAngleDoubleDown className="float-right toparrow blink-text dropdownArrow" />
                                            <span className="clickHere float-right">
                                                {isAccordionOpen ? "Click to Close" : "Click to Expand"}
                                            </span>
                                        </div>
                                    </div>

                                </div>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                                <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
                                    <DashboardInput />
                                </div>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>
                    <div className="top_card4">
                        <Accordion defaultActiveKey="0">

                            <Accordion.Toggle as={Button} variant="link" eventKey="1" className="arrayButton">
                                <div>
                                    <div className="dailysales text-center dailysalesmargin dailysalesfont">
                                    Sales Performance Dashboard
                                    </div>

                                </div>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey="1">
                                <div className="col-lg-12 col-md-12 col-sm-12 paddingremove">
                                    <DashboardInput />
                                </div>
                            </Accordion.Collapse>
                        </Accordion>
                    </div>


                </div>

                {/* <div className="top_card_dashboardChart"> */}

                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <PerDayTargetDashboard />
                </div>
                <div className="col-lg-1 col-md-12 col-sm-12 paddingremove">
                    <div className="vertical_bar">
                        <span className="spineercolor mt-5 mb-5">{isLoading && <LoadingSpinner />}</span>
                        <>
                            {!isLoading && (
                                <>
                                    <VerticalProgress />
                                    <div className="vertical_bar_bottom vertical_bar_bottom1">
                                        MTD-Primary Sales
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 primary paddingremove">
                    <Money />
                </div>
                <div className="col-lg-1 paddingremove">
                    <div className="vertical_bar2">
                        <span className="spineercolor mt-5 mb-5">{isLoading && <LoadingSpinner />}</span>
                        <>
                            {!isLoading && (
                                <>
                                    <VerticalProgress2 />
                                    <div className=" vertical_bar_bottom2">
                                        MTD-Secondary Sales
                                    </div>
                                </>
                            )}
                        </>
                    </div>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 primary paddingremove">
                    <Money2 />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-1 mb-1 paddingremove">
                    <div className="top_card2"></div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <PrimarySalesTrending />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <PrimarySalesTrendingPercentageWise />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <SecondarySalesTrending />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <SecondarySalesTrendingPercentageWise />
                </div>
               
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <CumulativeRevenueLast12Months />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <DamageChart />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <AnualSalesSinceInception />
                </div>
                {/* <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <LocationWiseSalesPieChart />
                    <LocationWIseSalesPieCHart2/>
                </div> */}
                <div className="col-lg-6 col-md-12 col-sm-12 paddingremove">
                    <DoughnutChartLocationWIseSales />
                </div>

            </div>
        </div>
    )
}

export default DashboardDemo