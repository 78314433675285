import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const YearToyearPaginatateDetails = ({ data, lineData, areaData, dataPointsPerPage, fullLabels }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indexes of the data for the current page.
  const startIndex = (currentPage - 1) * dataPointsPerPage;
  const endIndex = startIndex + dataPointsPerPage;
  const dataForCurrentPage = data.slice(startIndex, endIndex);
  const dataline = lineData.slice(startIndex, endIndex);
  const cmData = areaData.slice(startIndex, endIndex);
  const labelsAll = fullLabels.slice(startIndex, endIndex);

  // Extract labels, values, and line values for the Chart.js datasets.
  const labels = dataForCurrentPage.map((item) => item.label);
  const barValues = dataForCurrentPage.map((item) => item.value);
  const lineValues = dataline.map((item) => item.value);
  const cmValues = cmData.map((item) => item.value);
  const tooltipslabel = labelsAll.map((item) => item.label);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Actual Sales',
        data: barValues,
        type: 'bar',
        backgroundColor: 'rgb(93, 98, 181)',
        borderColor: 'rgb(93, 98, 181)',
        borderWidth: 3,
        order: 3,
        datalabels: {
          display: true,
          color: 'rgb(93, 98, 181)',
          align: 'end',
          anchor: 'end',
          font: {
            weight: 'bold',
            size: 12,
          },
        },
      },
      {
        type: 'line',
        label: 'Actual CM',
        borderColor: '#43ccfe',
        borderWidth: 2,
        data: lineValues,
        order: 1,
        datalabels: {
          display: true,
          color: '#ffff',
          //backgroundColor: "#43ccfe",
        },
      },
      {
        label: 'CM%',
        data: cmValues,
        type: 'line',
        fill: true,
        borderWidth: 2,
        order: 2,
        //backgroundColor: 'rgba(242, 114, 111, 0.9)',
        borderColor: 'rgba(242, 114, 111)',
        datalabels: {
          display: true,
          color: 'black',
          backgroundColor: "rgba(242, 114, 111, 0.9)",
          formatter: (value) => value + '%',
          font: {
            weight: 'bold',
            size: 12,
          },
        },
        cubicInterpolationMode: 'monotone',
      }

    ],
  };

  const totalPages = Math.ceil(data.length / dataPointsPerPage);

  const handleClick = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pagination = [];

  // Display first page
  pagination.push(
    <a
      href="#"
      key={1}
      onClick={(e) => handleClick(e, 1)}
      className={currentPage === 1 ? "active" : ""}
    >
      1
    </a>
  );

  // Display ellipsis (...) if there are intermediate pages
  if (totalPages > 3) {
    pagination.push(<span key="ellipsis">...</span>);
  }

  // Display pages 2 to totalPages-1
  for (let i = 2; i < totalPages; i++) {
    // Display only the pages around the current page
    if (Math.abs(i - currentPage) <= 2) {
      pagination.push(
        <a
          href="#"
          key={i}
          onClick={(e) => handleClick(e, i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </a>
      );
    }
  }

  // Display ellipsis (...) if there are intermediate pages
  if (totalPages > 3 && currentPage < totalPages - 1) {
    pagination.push(<span key="ellipsis">...</span>);
  }

  // Display last page
  if (totalPages > 1) {
    pagination.push(
      <a
        href="#"
        key={totalPages}
        onClick={(e) => handleClick(e, totalPages)}
        className={currentPage === totalPages ? "active" : ""}
      >
        {totalPages}
      </a>
    );
  }


  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <BsChevronLeft />
        </button>
        <div style={{ flex: 1 }}>
          {data.length === 0 ? (
            <div className='text-center'>No data available.</div>
          ) : (

            <Bar
              data={chartData}
              options={{
                responsive: true,
                maintainAspectRatio: true,
                layout: {
                  padding: {
                      top: 30
                  }
              },
                scales: {
                  x: {
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: 'Core',
                      font: {
                        size: 12,
                      },
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    mode: "index",
                    intersect: false,
                    callbacks: {
                      label: (context) => {
                        const datasetLabel = context.dataset.label || "";
                        const value = context.parsed.y;

                        return [
                          `${datasetLabel}: ${value}`
                        ].join("\n");
                      },
                    },
                  },

                  legend: {
                    display: false,
                    position: "bottom",
                  },
                  datalabels: {
                    display: true,
                    color: '#424E63',
                    font: {
                      weight: 'bold',
                      size: 12,
                    },

                  },
                },
              }}
              plugins={[ChartDataLabels]}
            />

          )}
        </div>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <BsChevronRight />
        </button>
      </div>
      {/* <div className="pagination mb-2">
        {pagination}
      </div> */}
    </div>
  );
};

export default YearToyearPaginatateDetails;
