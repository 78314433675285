import React from 'react'
import { numberFormat } from './Money'

const ColoredTableModal = (itemInfo) => {
    console.log('itemInfo', itemInfo)
    return (
        <div>
            <div className="react-bootstrap-table table-responsive border-0 p-5 ">
                <table className="table table-head-custom table-vertical-center  item-add-table borderTableims mb-5 p-5">
                    {itemInfo.itemInfo?.length > 0 &&
                        <thead >
                            <tr>
                                <th>SL</th>
                                <th>Product ID</th>
                                <th>Product Name</th>
                                <th>Standerd Value </th>
                                <th>Actual CM</th>
                                
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {itemInfo.itemInfo?.length > 0 &&
                            itemInfo.itemInfo?.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index+++1}</td>
                                        <td>{item.productID}</td>
                                        <td>{item.productName}</td>
                                        <td>{parseFloat(item.itemWiseMixing).toFixed(2)}%</td>
                                        <td>{item.actualCM}%</td>
                                       

                                    </tr>
                                );
                            })}



                    </tbody>



                </table>
            </div>
        </div>
    )
}

export default ColoredTableModal