import React, { useState } from "react";
import { MdEdit } from "react-icons/md";
import EditRangeModal from "./Modals/EditRangeModal";
import { useSelector } from 'react-redux';
import toast, { Toaster } from "react-hot-toast";

const ColorRange = () => {
  const rangeValues = useSelector(state => state.map.rangeValues);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rangeName, setRangeName] = useState(null);

  const handleEditRange = (rangeName) => {
    switch (rangeName) {
      case "best":
        setRangeName("best");
        break;
      case "good":
        setRangeName("good");
        break;
      case "bad":
        setRangeName("bad");
        break;
      case "worst":
        setRangeName("worst");
        break;
      default:
        break;
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <EditRangeModal onClose={closeModal} rangeName={rangeName} toast={toast} />
      )}

      <Toaster position="top-right" />

      <table className="table table-bordered range-table">
        <thead>
          <tr>
            <th scope="col">Color</th>
            <th scope="col">Range</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ backgroundColor: "#20bf6b" }}>Best</td>
            <td>
              {rangeValues?.best?.min}%{" ≤ "}{rangeValues?.best?.max}%
            </td>
            <td className="text-center" onClick={() => handleEditRange("best")}>
              <MdEdit className="edit-icon" />
            </td>
          </tr>
          <tr>
            <td style={{ backgroundColor: "#f7b731" }}>Good</td>
            <td>
              {rangeValues?.good?.min}%{" ≤ "}{rangeValues?.good?.max}%
            </td>
            <td className="text-center" onClick={() => handleEditRange("good")}>
              <MdEdit className="edit-icon" />
            </td>
          </tr>
          <tr>
            <td style={{ backgroundColor: "#99abbf" }}>satisfactory</td>
            <td>
              {rangeValues?.bad?.min}% {" ≤ "}{rangeValues?.bad?.max}%
            </td>
            <td className="text-center" onClick={() => handleEditRange("bad")}>
              <MdEdit className="edit-icon" />
            </td>
          </tr>
          <tr>
            <td style={{ backgroundColor: "#ff5e57" }}>poor</td>
            <td> {rangeValues?.worst?.min}%{" ≤ "}{rangeValues?.worst?.max}% </td>
            <td className="text-center" onClick={() => handleEditRange("worst")}>
              <MdEdit className="edit-icon" />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ColorRange;
