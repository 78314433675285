import React, { useEffect } from 'react';
import { useState } from 'react';
import { Form, Button, Spinner, Col, Modal } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { numberFormat } from '../../../Global/numberFormat';
import LoadingSpinner from '../../../modules/Billing/Components/LoadingSpinner';


const DasilyImsReportRolling = ({ dailyImsData, headerData, workingDays }) => {

    const dispatch = useDispatch();
    const { register, setValue } = useForm();
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const handleAccordionToggle = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const regionDataFilter = [{}, {}];
    const currentDate = new Date();
    const initialMonth = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}`;
    const [selectedMonth, setSelectedMonth] = useState(initialMonth);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };
    console.log('selectedMonth', selectedMonth)

    const divisionDataViews = useSelector(state => state?.mapData?.topTwentTSM?.allData);
    const divisionDataViewIsLoading = useSelector(state => state?.mapData?.topTwentTSMIsLoading);
    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];
    const monthNumber = dailyImsData && dailyImsData.length > 0 ? dailyImsData[0]?.month : null;
    const monthName = monthNames[monthNumber - 1]; // Adjusting index since array is zero-based
    return (
        <div className='container ims-division-wise-report'>
            <div>
                <div className="dailysales      container-fluid top_card3-map rounded mb-5  p-2">
                    <h1 style={{ width: '48%', margin: 'auto' }}> Daily  Ims Report Summery {monthName && "("} {monthName} {monthName && ")"}</h1>
                </div>
            </div>
            <div className="table-container">
                <table id="table-to-xls" className="territory-table">
                    <thead className='header-working'>
                        <th>  </th>
                        <th> Working days:  {workingDays?.totWorkingDay}</th>
                        <th></th>
                        <th>  </th>
                        <th></th>
                        <th></th>
                        <th>  </th>
                        <th></th>
                        <th> Working days Pass: {workingDays?.passWorkingDay}  </th>
                        <th>  </th>
                        <th></th>
                        <th></th>
                        <th>  </th>
                        <th></th>
                        <th>  Need IMS: {workingDays?.imsTarget.toFixed(2)}% </th>
                        <th></th>
                        <th></th>
                        <th>  </th>
                        <th></th>
                        <th></th>
                        <th>  </th>
                        <th></th>

                    </thead>

                    <thead>
                        <tr>
                            <th> Name of the
                                Division</th>
                            <th>So QTY</th>
                            <th> Online Target (Lac)</th>
                            <th>IMS Target (Lac)</th>
                            <th> Order <br />
                                {headerData?.oraderDateTime}
                            </th>
                            <th> Ims(C.M) <br />
                                {headerData?.imsCmDate}
                            </th>
                            <th> Ims(L.M) <br />
                                {headerData?.imsLmDate}

                            </th>
                            <th> Non Ex Value <br />
                                {headerData?.nonExecutionValueDate}

                            </th>
                            <th> Per So Ims <br />
                                {headerData?.perSoImsDate}

                            </th>

                            <th> No Excution
                                %
                            </th>
                            <th>Today's Fund Plan</th>

                            <th> Cum. Ims    {headerData?.cumImsDate}
                                <br />

                            </th>
                            <th> Ach. Ims %  <br />

                            </th>

                            <th>  Landing % <br />

                            </th>

                            <th> Cum. Ims   {headerData?.cumImsLastMonthDate}
                                <br />

                            </th>

                            <th> Gw over Prev. Month <br />

                            </th>

                            <th>  Short End of   {headerData?.shortEndOffCMonthDate}
                                <br />

                            </th>
                            <th>   Last day lifting {headerData?.lastDayLiftingDate}
                                <br />

                            </th>
                            <th>  Cum. lifting  {headerData?.cumLiftingDate}
                                <br />

                            </th>
                            <th>  Last day Collection <br />
                                {headerData?.lastDayCollectionDate}

                            </th>
                            <th>  Cum, Fund Collection 2024<br />
                                {headerData?.cumFundCollectionDate}

                            </th>

                            <th>
                                Ach. Online %
                            </th>


                        </tr>
                    </thead>
                    <tbody className="territory-tr">
                        {dailyImsData?.map((item, index) => (
                            <tr key={index} className={`${item?.divisionName === "North" || item?.divisionName === "South" ? 'font-weights' : ''}`}
                                style={{ backgroundColor: item?.divisionName === "National" ? 'yellow' : '' }}>
                                <td>{item?.divisionName} </td>
                                <td>{item?.totSo} </td>
                                <td>{item?.onlineTarget} </td>
                                <td> {item?.imsTarget}</td>
                                <td> {item?.oraderDate}</td>
                                <td  > {item?.imsCm}</td>
                                <td>{item?.imsLm} </td>
                                <td>{item?.nonExecutionValue} </td>
                                <td>{item?.perSoIms} </td>
                                <td>{item?.nonExecutionPer}% </td>
                                <td> {item?.todayFundPlan}</td>
                                <td> {item?.cumImsLastMonth}</td>
                                <td  > {item?.achImsPer}%</td>
                                <td  > {item?.landing}%</td>
                                <td  > {item?.cumIms}</td>
                                <td>{item?.gwOverPrevMonth}% </td>
                                <td>{item?.shortEndOffCMonth}</td>
                                <td>{item?.lastDayLifting}</td>
                                <td> {item?.cumLifting}</td>
                                <td> {item?.lastDayCollection}</td>
                                <td  > {item?.cumFundCollection}</td>
                                <td>{item?.achOnline} %</td>
                            </tr>
                        ))}
                    </tbody>




                </table>
            </div>



        </div >
    );
};

export default DasilyImsReportRolling;