import React, { useRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import { useSelector } from "react-redux";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect } from "react";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin
);

export default function SalesMixingMonitoring2() {
  const salesMixingData = useSelector(
    (state) => state.productionLine.salesMixingMonitoringData
  );
  const salesMixingMonitoringDataLabels = useSelector(
    (state) => state.productionLine.salesMixingMonitoringDataLabels
  );
  const salesMixingMonitoringDataLabels2 = useSelector(
    (state) => state.productionLine.salesMixingMonitoringDataLabels2
  );
  const isLoading = useSelector(
    (state) => state.productionLine.toSalesMonitoringLoading
  );
  let nf = new Intl.NumberFormat("en-BD");
  const chartRef = useRef(null);
  const zoomOptions = {
    pan: {
      enabled: true,
      mode: "x",
    },
    zoom: {
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      mode: "x",
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "bottom",
        display: true,
        labels: {
          usePointStyle: true,
        },
        datalabels: {
          display: true,
          color: "white",
        },
      },
      tooltip: {
        callbacks: {
          title: (context) => {
            const filterToolTip =
              salesMixingMonitoringDataLabels[context[0].dataIndex];
            return filterToolTip;
          },
        },
      },
    },
  };

  const labels = salesMixingMonitoringDataLabels2;

  const data = {
    labels,
    datasets: [
      {
        type: "line",
        label: "Act Prd",
        color: "white",
        backgroundColor: "#203864",
        borderColor: "#203864",
        borderWidth: 2,
        fill: false,
        data: salesMixingData,
        datalabels: {
          display: true,
          color: "white",
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: function(value, context) {
            return nf.format(value);
          },
          backgroundColor: "#203864",
          backgroundColor: function(dataColor) {
            return dataColor.dataset.data[dataColor.dataIndex] < 0
              ? "#203864"
              : "rgba(106, 90, 205, 0.7)";
          },
        },
      },
      {
        type: "bar",
        label: "Act Stack",
        backgroundColor: "#ffc000",
        data: salesMixingData,
        datalabels: {
          display: true,
          color: "white",
          font: {
            weight: "bold",
            size: 12,
          },
          formatter: function(value, context) {
            return nf.format(value);
          },
        },
        options: {
          maintainAspectRatio: true ,
          plugins: {
            tooltip: {
              callbacks: {
                title: (context) => {
                  console.log(context);
                },
              },
            },
          },
        },
      },
    ],
  };

  return (
    <div className="card">
      <div className="text-center bg-danger pt-2 text-white rounded-top">
        <h5>Sales Mixing MonitoringGGG</h5>
      </div>
      {isLoading && (
        <span className="spineercolor mt-5 mb-5">
          <LoadingSpinner />
        </span>
      )}
        <div className="scrollChart">
          <div className="hrozontalscroll">
            <Chart options={options} data={data} plugins={[ChartDataLabels]} />
          </div>
        </div>
    </div>
  );
}
