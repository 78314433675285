import React from "react";
import territory from "../../Data/territory";
import regionData from "../../Data/region";
import divisionData from "../../Data/division";
import saleData from "../../Data/saleData";
import { useSelector } from "react-redux";
import { numberFormat } from "../../../Global/numberFormat";
import DownloadDropdown from "../../../modules/master/PrintDwopdown/ExcelDawnload";

const RegionModal = ({ onClose, id, selectColor }) => {
  console.log('id', id)
  const regionInfoModal = useSelector(state => state.mapData?.regionDataForModal?.data?.allData)
  const state = useSelector(state => state.mapData)

  const regionDataFilter = regionInfoModal?.filter(
    (item) => item?.id === parseInt(id)
  );

  const totalPerformanceSum = regionDataFilter?.reduce((sum, zone) => sum + zone.performance, 0) || 0;
  const totalTargetSum = regionDataFilter?.reduce((sum, zone) => sum + parseInt(zone.target), 0) || 0;


  return (
    <div className="modal ">
      <div className="modal-content">

        <div style={{display:'flex'}}>
          <div className="text-right col-lg-11" >
            <DownloadDropdown excelname='Region Report' />
          </div>
          <div className="text-end">
            <span className="close closs-icon" onClick={onClose}>
              &times;
            </span>
          </div>
        </div>
        <h4 className="mb-3">
          {regionDataFilter[0]?.area_name} Region
        </h4>
        <div className="table-container">
          <table id="table-to-xls"

            className="territory-table">
            <thead>
              <tr>
                <th>Area Name</th>
                <th>Line Name</th>
                <th> Product Name</th>
                <th>Target</th>
                <th>Sales</th>
                <th>Achievement</th>

              </tr>
            </thead>
            <tbody className="territory-tr">
              {regionDataFilter?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.area_name}</td>
                  <td>{item?.line_name}</td>
                  <td>{item?.product_name}</td>
                  <td>{numberFormat(parseInt(item?.target))}</td>
                  <td>{numberFormat(item?.performance)}</td>
                  <td  >{item?.achievement}%</td>


                </tr>
              ))}
            </tbody>
            <thead>
              <tr>
                <th> </th>
                <th></th>
                <th>  Total  </th>
                <th>{numberFormat(totalTargetSum) || 0}</th>
                <th>{numberFormat(totalPerformanceSum) || 0}</th>

                <th>{parseInt(totalPerformanceSum * 100 / totalTargetSum)} %</th>

              </tr>
            </thead>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RegionModal;
