import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import TopFiveDSM from './TopFiveDSM';
import SimpleModal from '../../modules/master/components/Modal/SimpleModal';
import ButtomThreeDsm from './ButtomThreeDsm';
import TopTenRM from './TopTenRM';
import TopTwentyTsm from './TopTwentyTsm';
import BottomTwentyTsm from './BottomTwentyTsm';
import DivisionWiseImsSummery from './DivisionWiseImsSummery';
import RegionWiseImsSummery from './RegionWiseImsSummery';
import HeighestTwentySalesOfficers from './HeighestTwentySalesOfficers';
import BottomTwentySalesOfficers from './BottomTwentySalesOfficers';
import TopTwentSalesValueAchOfficer from './TopTwentSalesValueAchOfficer';
import FocusSkuTargetVsAch from './FocusSkuTargetVsAch';
import FocusProductTargetVsAchievement from './FocusProductTargetVsAchievement';
import TopSellingSku from './TopSellingSku';
import CategoryWiseDivisionAch from './CategoryWiseDivisionAch';
import DailyImsReportSummery from './DailyImsReportSummery';
const ReportMenuWithModal = () => {

    const navigate = useHistory();
    const handleReportPage = (url) => {

        navigate.push(url);

    }


    const [topFiveDsm, setTopFiveDsm] = useState(false);
    const [bottomThreeDsmModal, setBottomThreeDsmModal] = useState(false);
    const [topTenRmModal, setTopTenRmModal] = useState(false);
    const [bottomTenRmModal, setBottomTenRmModal] = useState(false);
    const [topTwentyTsmModal, setTopTwentyTsmModal] = useState(false);
    const [bottomTwentyTsmModal, setBottomTwentyTsmModal] = useState(false);
    const [divisionWiseImsModal, setDivisionWiseImsModal] = useState(false);
    const [regionWiseImsModal, setRegionWiseImsModal] = useState(false);
    const [heighestAchiversModal, setHeighestAchiversModal] = useState(false);
    const [bottomTwentyModal, setButtomTwentyModal] = useState(false);
    const [topTwentySoModal, setTopTwentySoModal] = useState(false);
    const [bottomTwentySoModal, setButtomTwentySoModal] = useState(false);
    const [focusSkuModal, setFocusSkuModal] = useState(false);
    const [focusProductModal, setFocusProductModal] = useState(false);
    const [topSellingSkuModal, setTopSellingSkuModal] = useState(false);
    const [categoryWiseDivisionModal, setCategoryWiseDivisionModal] = useState(false);
    const [dailyImsReportModal, setDAilyImsReportModal] = useState(false);

    const [itemifo, setItemifo] = useState(null);






    return (
        <div>


            <div>

                <div className='container'>
                    <div className="row p-4">
                        <div
                            className="col-lg-3 btn-grad"
                            onClick={() => setTopFiveDsm(true)}
                        >
                            <i class="fas fa-file-alt"></i>  Top Five DSM
                        </div>
                        <div onClick={() => setBottomThreeDsmModal(true)}
                            className="col-lg-3 btn-grad2"
                        >
                            <i class="fas fa-file-alt"></i>  Bottom 3 DSM
                        </div>

                        <div onClick={() => setTopTenRmModal(true)}
                            className="col-lg-3 btn-grad3"
                        >
                            <i class="fas fa-file-alt"></i> <span className="text-white">Top Ten RM</span>

                        </div>
                        <div onClick={() => setBottomTenRmModal(true)}
                            className="col-lg-3 btn-grad"
                        >
                            <i class="fas fa-file-alt"></i>  Bottom Ten RM
                        </div>
                        <div onClick={() => setTopTwentyTsmModal(true)}
                            className="col-lg-3 btn-grad2"
                        >
                            <i class="fas fa-file-alt"></i> Top Twenty TSM
                        </div>

                        <div onClick={() => setBottomTwentyTsmModal(true)}
                            className="col-lg-3 btn-grad3"
                        >
                            <i class="fas fa-file-alt"></i> <span className="text-white">Bottom Twenty TSM</span>

                        </div>

                        <div onClick={() => setDivisionWiseImsModal(true)}
                            className="col-lg-3 btn-grad"
                        >
                            <i class="fas fa-file-alt"></i>  Division Wise Ims Report
                        </div>
                        <div onClick={() => setRegionWiseImsModal(true)}
                            className="col-lg-3 btn-grad2"
                        >
                            <i class="fas fa-file-alt"></i>  Region Wise Ims report
                        </div>

                        <div onClick={() => setHeighestAchiversModal(true)}
                            className="col-lg-3 btn-grad3"
                        >
                            <i class="fas fa-file-alt"></i> <span className="text-white">H.ACHIEVERS 20 SO</span>

                        </div>
                        <div onClick={() => setButtomTwentyModal(true)}
                            className="col-lg-3 btn-grad"
                        >
                            <i class="fas fa-file-alt"></i> BOTTOM 20 SO
                        </div>
                        <div onClick={() => setTopTwentySoModal(true)}
                            className="col-lg-3 btn-grad2"
                        >
                            <i class="fas fa-file-alt"></i> TOP 20 SO
                        </div>

                        <div onClick={() => setButtomTwentySoModal(true)}
                            className="col-lg-3 btn-grad3"
                        >
                            <i class="fas fa-file-alt"></i> <span className="text-white">BOTTOM 20 SO</span>

                        </div>
                        <div onClick={() => setFocusSkuModal(true)}
                            className="col-lg-3 btn-grad"
                        >
                            <i class="fas fa-file-alt"></i> Focus Sku Tar vs Ach
                        </div>
                        <div onClick={() => setFocusProductModal(true)}
                            className="col-lg-3 btn-grad2"
                        >
                            <i class="fas fa-file-alt"></i>  Focus Prod Tar Vs Ach
                        </div>

                        <div onClick={() => setTopSellingSkuModal(true)}
                            className="col-lg-3 btn-grad3"
                        >
                            <i class="fas fa-file-alt"></i> <span className="text-white">Top Selling Sku</span>

                        </div>
                        <div onClick={() => setCategoryWiseDivisionModal(true)}
                            className="col-lg-3 btn-grad"
                        >
                            <i class="fas fa-file-alt"></i>  Category wise division ach
                        </div>
                        <div onClick={() => setDAilyImsReportModal(true)}
                            className="col-lg-3 btn-grad2"
                        >
                            <i class="fas fa-file-alt"></i> DAILY REPORT SUMMARY
                        </div>

                    </div>
                </div>

            </div>



            <SimpleModal
                size="xl"
                show={topFiveDsm}
                handleClose={() => setTopFiveDsm(false)}
                handleShow={() => setTopFiveDsm(true)}

            >
                <TopFiveDSM
                    handleClose={() => setTopFiveDsm(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>

            <SimpleModal
                size="xl"
                show={bottomThreeDsmModal}
                handleClose={() => setBottomThreeDsmModal(false)}
                handleShow={() => setBottomThreeDsmModal(true)}

            >
                <ButtomThreeDsm
                    handleClose={() => setBottomThreeDsmModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>

            <SimpleModal
                size="xl"
                show={topTenRmModal}
                handleClose={() => setTopTenRmModal(false)}
                handleShow={() => setTopTenRmModal(true)}

            >
                <TopTenRM
                    handleClose={() => setTopTenRmModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={bottomTenRmModal}
                handleClose={() => setBottomTenRmModal(false)}
                handleShow={() => setBottomTenRmModal(true)}

            >
                <TopTenRM
                    handleClose={() => setBottomTenRmModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={topTwentyTsmModal}
                handleClose={() => setTopTwentyTsmModal(false)}
                handleShow={() => setTopTwentyTsmModal(true)}

            >
                <TopTwentyTsm
                    handleClose={() => setTopTwentyTsmModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={bottomTwentyTsmModal}
                handleClose={() => setBottomTwentyTsmModal(false)}
                handleShow={() => setBottomTwentyTsmModal(true)}

            >
                <BottomTwentyTsm
                    handleClose={() => setBottomTwentyTsmModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={divisionWiseImsModal}
                handleClose={() => setDivisionWiseImsModal(false)}
                handleShow={() => setDivisionWiseImsModal(true)}

            >
                <DivisionWiseImsSummery
                    handleClose={() => setDivisionWiseImsModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={regionWiseImsModal}
                handleClose={() => setRegionWiseImsModal(false)}
                handleShow={() => setRegionWiseImsModal(true)}

            >
                <RegionWiseImsSummery
                    handleClose={() => setRegionWiseImsModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={heighestAchiversModal}
                handleClose={() => setHeighestAchiversModal(false)}
                handleShow={() => setHeighestAchiversModal(true)}

            >
                <HeighestTwentySalesOfficers
                    handleClose={() => setHeighestAchiversModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={bottomTwentyModal}
                handleClose={() => setButtomTwentyModal(false)}
                handleShow={() => setButtomTwentyModal(true)}

            >
                <BottomTwentySalesOfficers
                    handleClose={() => setButtomTwentyModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={topTwentySoModal}
                handleClose={() => setTopTwentySoModal(false)}
                handleShow={() => setTopTwentySoModal(true)}

            >
                <TopTwentSalesValueAchOfficer
                    handleClose={() => setTopTwentySoModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={bottomTwentySoModal}
                handleClose={() => setButtomTwentySoModal(false)}
                handleShow={() => setButtomTwentySoModal(true)}

            >
                <BottomTwentySalesOfficers
                    handleClose={() => setButtomTwentySoModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={focusProductModal}
                handleClose={() => setFocusProductModal(false)}
                handleShow={() => setFocusProductModal(true)}

            >
                <FocusProductTargetVsAchievement  
                    handleClose={() => setFocusProductModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
        
            <SimpleModal
                size="xl"
                show={focusSkuModal}
                handleClose={() => setFocusSkuModal(false)}
                handleShow={() => setFocusSkuModal(true)}

            >
                <FocusSkuTargetVsAch
                    handleClose={() => setFocusSkuModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={topSellingSkuModal}
                handleClose={() => setTopSellingSkuModal(false)}
                handleShow={() => setTopSellingSkuModal(true)}

            >
                <TopSellingSku
                    handleClose={() => setTopSellingSkuModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={categoryWiseDivisionModal}
                handleClose={() => setCategoryWiseDivisionModal(false)}
                handleShow={() => setCategoryWiseDivisionModal(true)}

            >
                <CategoryWiseDivisionAch
                    handleClose={() => setCategoryWiseDivisionModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
            <SimpleModal
                size="xl"
                show={dailyImsReportModal}
                handleClose={() => setDAilyImsReportModal(false)}
                handleShow={() => setDAilyImsReportModal(true)}

            >
                <DailyImsReportSummery
                    handleClose={() => setDAilyImsReportModal(false)}
                    itemDetailnIfo={itemifo}
                />
            </SimpleModal>
        





        </div>
    );
};

export default ReportMenuWithModal;