import React from 'react';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../Billing/Components/LoadingSpinner';
import PaginatedBarChart from './PaginatedBarChart';

export default function PracticeSalesMixingMonitoring() {
  const salesMixingMonitoringData = useSelector(
    (state) => state.productionLine.salesMixingMonitoringData
  );
  const salesMixingMonitoringData2 = useSelector(
    (state) => state.productionLine.salesMixingMonitoringData2
  );
  const isLoading = useSelector(
    (state) => state.productionLine.toSalesMonitoringLoading
  );

  const fullName = useSelector(
    (state) => state.productionLine.fullName
  );

  const dataPointsPerPage = 10; // Change this number based on your preference.

  return (
    <div className="App card">
      <div className="text-center bg-danger pdfBGC2 pt-2 text-white rounded-top">
        <h5 className="pdfFont2C">Sales Mixing Monitoring</h5>
      </div>
      {/* Add any loading indicator if needed */}
      {isLoading ? (
        <span className="spineercolor mt-5 mb-5">
          <LoadingSpinner />
        </span>
      ) : (
        <PaginatedBarChart
          data={salesMixingMonitoringData}
          lineData={salesMixingMonitoringData2}
          dataPointsPerPage={dataPointsPerPage}
          fullLabels = {fullName}
        />
      )}
    </div>
  );
}
