import Axios from "axios";
import * as Types from "./MapType";


export const getOverAllDivisionData = (salesID, SalesType, Fdate, Tdate, selectProductionLine, selectProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_OVER_ALL_DIVISION,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetOverallDivisionSales?`;

    if (salesID !== null) {
        url += `&SalesChannelId=${salesID}`;
    }
    if (selectProductionLine !== null) {
        url += `&ProductionLine=${selectProductionLine}`;
    }
    if (selectProductId !== null) {
        url += `&ProductId=${selectProductId}`;
    }
    if (SalesType !== null) {
        url += `&SalesType=${SalesType}`;
    }

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_OVER_ALL_DIVISION,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const getOverAllZoneData = (salesID, SalesType, Fdate, Tdate, selectProductionLine, selectProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_OVER_ALL_ZONE,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetOverAllZoneSales?`;
    console.log('zone url', url)
    if (salesID !== null) {
        url += `&SalesChannelId=${salesID}`;
    }

    if (selectProductionLine !== null) {
        url += `&ProductionLine=${selectProductionLine}`;
    }
    if (selectProductId !== null) {
        url += `&ProductId=${selectProductId}`;
    }

    if (SalesType !== null) {
        url += `&SalesType=${SalesType}`;
    }

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_OVER_ALL_ZONE,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const getOverAllRegionData = (salesID, SalesType, Fdate, Tdate, selectProductionLine, selectProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_OVER_ALL_REGION,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetOverallAreaSales?`;

    if (salesID !== null) {
        url += `&SalesChannelId=${salesID}`;

        if (selectProductionLine !== null) {
            url += `&ProductionLine=${selectProductionLine}`;
        }
        if (selectProductId !== null) {
            url += `&ProductId=${selectProductId}`;
        }
    }
    if (SalesType !== null) {
        url += `&SalesType=${SalesType}`;
    }

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_OVER_ALL_REGION,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const getOverAllTerritorynData = (salesID, SalesType, Fdate, Tdate, selectProductionLine, selectProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_OVER_ALL_TERRITORY,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetOverallTerritorySales?`;

    if (salesID !== null) {
        url += `&SalesChannelId=${salesID}`;
    }

    if (selectProductionLine !== null) {
        url += `&ProductionLine=${selectProductionLine}`;
    }
    if (selectProductId !== null) {
        url += `&ProductId=${selectProductId}`;
    }
    if (SalesType !== null) {
        url += `&SalesType=${SalesType}`;
    }

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_OVER_ALL_TERRITORY,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const getOverZoneDataForModal = (salesID, SalesType, Fdate, Tdate, selectProductionLine, selectProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_OVER_ALL_ZONE_DATA_FOR_MODAL,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetOverAllZoneSalesDataView?`;

    if (salesID !== null) {
        url += `&SalesChannelId=${salesID}`;
    }
    if (selectProductionLine !== null) {
        url += `&ProductionLine=${selectProductionLine}`;
    }
    if (selectProductId !== null) {
        url += `&ProductId=${selectProductId}`;
    }
    if (SalesType !== null) {
        url += `&SalesType=${SalesType}`;
    }

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_OVER_ALL_ZONE_DATA_FOR_MODAL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const getOverAllDivisionModal = (salesID, SalesType, Fdate, Tdate, selectProductionLine, selectProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_OVER_ALL_DIVISION_DATA_FOR_MODAL,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetOverallDivisionSalesDataView?`;

    if (salesID !== null) {
        url += `&SalesChannelId=${salesID}`;
    }

    if (selectProductionLine !== null) {
        url += `&ProductionLine=${selectProductionLine}`;
    }
    if (selectProductId !== null) {
        url += `&ProductId=${selectProductId}`;
    }
    if (SalesType !== null) {
        url += `&SalesType=${SalesType}`;
    }

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_OVER_ALL_DIVISION_DATA_FOR_MODAL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const getOverAllRegionForModal = (salesID, SalesType, Fdate, Tdate, selectProductionLine, selectProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_OVER_ALL_REGION_FOR_MODAL,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetOverallAreaSalesDataView?`;

    if (salesID !== null) {
        url += `&SalesChannelId=${salesID}`;
    }

    if (selectProductionLine !== null) {
        url += `&ProductionLine=${selectProductionLine}`;
    }
    if (selectProductId !== null) {
        url += `&ProductId=${selectProductId}`;
    }

    if (SalesType !== null) {
        url += `&SalesType=${SalesType}`;
    }

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_OVER_ALL_REGION_FOR_MODAL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const getOverAlltERRITORYForModal = (salesID, SalesType, Fdate, Tdate, selectProductionLine, selectProductId) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    dispatch({
        type: Types.GET_OVER_ALL_TERRITORY_FOR_MODAL,
        payload: responseList,
    });
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetOverallTerritorySalesDataView?`;

    if (salesID !== null) {
        url += `&SalesChannelId=${salesID}`;
    }

    if (selectProductionLine !== null) {
        url += `&ProductionLine=${selectProductionLine}`;
    }
    if (selectProductId !== null) {
        url += `&ProductId=${selectProductId}`;
    }
    if (SalesType !== null) {
        url += `&SalesType=${SalesType}`;
    }

    if (Fdate !== null) {
        url += `&Fdate=${Fdate}`;
    }
    if (Tdate !== null) {
        url += `&Tdate=${Tdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;
        dispatch({
            type: Types.GET_OVER_ALL_TERRITORY_FOR_MODAL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};



export const getOverAllPerformanceForBiscutChanel = (id, subId, top, xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
        fDate: 0,
        endDate: 0
    };
    dispatch({
        type: Types.GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TopPerformerForBiscuitChannelNew?`;

    if (id !== null) {
        url += `&id=${id}`;
    }
    if (subId !== null) {
        url += `&subId=${subId}`;
    }
    if (top !== null) {
        url += `&top=${top}`;
    }

    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;




        console.log('response', response)
        dispatch({
            type: Types.GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};






export const getOverAllPerformanceForBiscutChanelButtomTop = (id, subId, top, xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
        fDateFast: 0,
        endDateFast: 0
    };
    dispatch({
        type: Types.GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL_BUTTOM,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TopPerformerForFastChannelNew?`;

    if (id !== null) {
        url += `id=${id}`;
    }
    if (subId !== null) {
        url += `&subId=${subId}`;
    }
    if (top !== null) {
        url += `&top=${top}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;




        console.log('response', response)
        dispatch({
            type: Types.GET_OVER_ALL_PERFORMANCE_FOR_BISCUT_CHANEL_BUTTOM,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};



export const getOverallPerformancForTopFastButtomChanel = (id, subId, top, xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
        fDate: 0,
        endDate: 0
    };
    dispatch({
        type: Types.GET_OVER_ALL_PERFORMANCE_BUTTOM_FOR_TOP,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BottomPerformerForBiscuitChannelNew?`;

    if (id !== null) {
        url += `id=${id}`;
    }
    if (subId !== null) {
        url += `&subId=${subId}`;
    }

    if (top !== null) {
        url += `&bottom=${top}`;
    }

    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }

    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;




        console.log('response', response)
        dispatch({
            type: Types.GET_OVER_ALL_PERFORMANCE_BUTTOM_FOR_TOP,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const getButtomperformanceForPoorperformance = (id, subId, top, xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
        fDate: 0,
        endDate: 0
    };
    dispatch({
        type: Types.GET_OVER_ALL_PERFORMANCE_BUTTOM_For_POOR_FAST,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BottomPerformerForFastChannelNew?`;

    if (id !== null) {
        url += `id=${id}`;
    }
    if (subId !== null) {
        url += `&subId=${subId}`;
    }
    if (top !== null) {
        url += `&bottom=${top}`;
    }

    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;



        console.log('response', response)
        dispatch({
            type: Types.GET_OVER_ALL_PERFORMANCE_BUTTOM_For_POOR_FAST,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};





export const getOverallZoneDivisionList = () => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}ZoneDivisionList?`;
    await Axios.get(url).then((res) => {
        console.log('division', res)
        responseList.data = res.data.data;
        responseList.status = false;
    });
    dispatch({ type: Types.GET_ZONE_DIVISION_LIST, payload: responseList });
};


export const getOverallAreaTerritory = (id) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetAreaTerritoryList?`;
    if (id !== null) {
        url += `id=${id}`;
    }
    await Axios.get(url).then((res) => {
        console.log('division', res)
        responseList.data = res.data.data;
        responseList.status = false;
    });
    dispatch({ type: Types.GET_AREA_TERRITORY_LIST, payload: responseList });
};




export const locationWiseTopPerformerForBiscutChanel = (zoneId, divisionId, areaId, territory, xdate, top, salesType) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_LOCATION_WISE_TOP_PERFORMERbISCKUT_CHANEL,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}LocationWisetopperformerbiscuit?`;

    if (zoneId !== null) {
        url += `ZoneID=${zoneId}`;
    }
    if (divisionId !== null) {
        url += `&DivisionID=${divisionId}`;
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`;
    }

    if (territory !== null) {
        url += `&Territory=${territory}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }
    if (top !== null) {
        url += `&top=${top}`;
    }

    if (salesType !== null) {
        url += `&PS_ID=${salesType}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_LOCATION_WISE_TOP_PERFORMERbISCKUT_CHANEL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const locationWiseBottomPerformerForBiscutChanel = (zoneId, divisionId, areaId, territory, xdate, top, salesType) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_LOCATION_WISE_BOTTOM_PERFORMERbISCKUT_CHANEL,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}LocationWisebottomperformerbiscuit?`;

    if (zoneId !== null) {
        url += `ZoneID=${zoneId}`;
    }
    if (divisionId !== null) {
        url += `&DivisionID=${divisionId}`;
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`;
    }

    if (territory !== null) {
        url += `&Territory=${territory}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }
    if (top !== null) {
        url += `&bottom=${top}`;
    }

    if (salesType !== null) {
        url += `&PS_ID=${salesType}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_LOCATION_WISE_BOTTOM_PERFORMERbISCKUT_CHANEL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const locationWiseTopPerformerForFastChanel = (zoneId, divisionId, areaId, territory, xdate, top, salesType) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_LOCATION_WISE_TOP_PERFORMER_FAST_CHANEL,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}LocationWisetopperformerFast?`;

    if (zoneId !== null) {
        url += `ZoneID=${zoneId}`;
    }
    if (divisionId !== null) {
        url += `&DivisionID=${divisionId}`;
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`;
    }

    if (territory !== null) {
        url += `&Territory=${territory}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }
    if (top !== null) {
        url += `&top=${top}`;
    }

    if (salesType !== null) {
        url += `&PS_ID=${salesType}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_LOCATION_WISE_TOP_PERFORMER_FAST_CHANEL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const locationWiseBottomPerformerForFastChanel = (zoneId, divisionId, areaId, territory, xdate, top, salesType) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_LOCATION_WISE_BOTTOM_PERFORMER_FAST_CHANEL,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}LocationWisebottomperformerFast?`;

    if (zoneId !== null) {
        url += `ZoneID=${zoneId}`;
    }
    if (divisionId !== null) {
        url += `&DivisionID=${divisionId}`;
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`;
    }

    if (territory !== null) {
        url += `&Territory=${territory}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }
    if (top !== null) {
        url += `&bottom=${top}`;
    }

    if (salesType !== null) {
        url += `&PS_ID=${salesType}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_LOCATION_WISE_BOTTOM_PERFORMER_FAST_CHANEL,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};



export const getZoneListEntryNew = async () => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };

    try {
        let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}GetZoneList`;
        await Axios
            .get(geturl)
            .then(function (response) {
                responseList.data = response.data.data;

            })

            .catch(function (error) {

            });
        // responseList.data.unshift(objectVar)
    } catch (error) {

    }
    return responseList;
};


export const getDivisionListByZoneEntryNew = async (zoneId, type = 0) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    try {
        let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}GetDivisionList?zoneId=${zoneId}&type=${type}`;

        await Axios
            .get(geturl)
            .then(function (response) {
                console.log('divisionewewe', response);
                responseList.data = response.data.data;
            })
            .catch(function (error) {

            });
    } catch (error) {

    }
    return responseList;
};

export const getDivisionListByZoneEntryPoorNew = async (zoneId, type = 0) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    try {
        let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}GetDivisionList?zoneId=${zoneId}&type=${type}`;

        await Axios
            .get(geturl)
            .then(function (response) {
                console.log('divisionewewe', response);
                responseList.data = response.data.data;
            })
            .catch(function (error) {

            });
    } catch (error) {

    }
    return responseList;
};



export const getAreaListByDivisionEntryNew = async (divisinId, type = 0) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    try {
        let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}GetAreaList?divisionId=${divisinId}&type=${type}`;


        await Axios
            .get(geturl)
            .then(function (response) {
                responseList.data = response.data.data;
            })
            .catch(function (error) {

            });
    } catch (error) {

    }
    return responseList;
};
export const getTerritoryEntryNew = async (areaId, type) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    try {
        let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}TerritoryList?AreaId=${areaId}&type=${type}`;



        await Axios
            .get(geturl)
            .then(function (response) {

                responseList.data = response.data.data;
            })
            .catch(function (error) {

            });
    } catch (error) {

    }
    return responseList;
};
export const getTerritoryEntryNewZone = async (areaId) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: false,
    };
    try {
        let geturl = `${process.env.REACT_APP_API_PUBLIC_URL}TerritoryList?zoneId=${areaId} `;



        await Axios
            .get(geturl)
            .then(function (response) {

                responseList.data = response.data.data;
            })
            .catch(function (error) {

            });
    } catch (error) {

    }
    return responseList;
};



export const locationWiseTopperformerBiskutChanelDataView = (zoneId, divisionId, areaId, territory, xdate, salesType) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_LOCATION_WISE_BISKUT_CHANEL_DATA_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}LocationWisetopperformerbiscuitDataView?`;

    if (zoneId !== null) {
        url += `ZoneID=${zoneId}`;
    }
    if (divisionId !== null) {
        url += `&DivisionID=${divisionId}`;
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`;
    }

    if (territory !== null) {
        url += `&Territory=${territory}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }


    if (salesType !== null) {
        url += `&PS_ID=${salesType}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_LOCATION_WISE_BISKUT_CHANEL_DATA_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const locationWiseBottomperformerBiskutChanelDataView = (zoneId, divisionId, areaId, territory, xdate, salesType) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_LOCATION_WISE_BOTTOM_BISKUT_CHANEL_DATA_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}LocationWisebottomperformerbiscuitDataView?`;

    if (zoneId !== null) {
        url += `ZoneID=${zoneId}`;
    }
    if (divisionId !== null) {
        url += `&DivisionID=${divisionId}`;
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`;
    }

    if (territory !== null) {
        url += `&Territory=${territory}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }


    if (salesType !== null) {
        url += `&PS_ID=${salesType}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_LOCATION_WISE_BOTTOM_BISKUT_CHANEL_DATA_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};



export const locationWisTopperformerFastChanelDataView = (zoneId, divisionId, areaId, territory, xdate, salesType) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_LOCATION_WISE_TOP_FAST_CHANEL_DATA_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}LocationWisetopperformerFastDataView?`;

    if (zoneId !== null) {
        url += `ZoneID=${zoneId}`;
    }
    if (divisionId !== null) {
        url += `&DivisionID=${divisionId}`;
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`;
    }

    if (territory !== null) {
        url += `&Territory=${territory}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }


    if (salesType !== null) {
        url += `&PS_ID=${salesType}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_LOCATION_WISE_TOP_FAST_CHANEL_DATA_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};



export const locationWisBottomperformerFastChanelDataView = (zoneId, divisionId, areaId, territory, xdate, salesType) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_LOCATION_WISE_BOTTOM_FAST_CHANEL_DATA_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}LocationWisebottomperformerFastDataView?`;

    if (zoneId !== null) {
        url += `ZoneID=${zoneId}`;
    }
    if (divisionId !== null) {
        url += `&DivisionID=${divisionId}`;
    }
    if (areaId !== null) {
        url += `&AreaID=${areaId}`;
    }

    if (territory !== null) {
        url += `&Territory=${territory}`;
    }
    if (xdate !== null) {
        url += `&xdate=${xdate}`;
    }


    if (salesType !== null) {
        url += `&PS_ID=${salesType}`;
    }


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_LOCATION_WISE_BOTTOM_FAST_CHANEL_DATA_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const DivisionWiseImsSummeryVW = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GEt_DIVISION_WISE_IMS_SUMMERY_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}DIVISION_WISE_IMS_SUMMARY_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GEt_DIVISION_WISE_IMS_SUMMERY_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const RegionWiseImsSummeryVW = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GEt_REGION_WISE_IMS_SUMMERY_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}REGION_WISE_IMS_SUMMARY_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GEt_REGION_WISE_IMS_SUMMERY_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const BottomThreeDSMView = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.BOTTOM_THREE_DSM_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BOTTOM_3_DSM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.BOTTOM_THREE_DSM_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const TopTenRMView = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.TOP_TEN_RM_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TOP_10_RM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.TOP_TEN_RM_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const BottomTenRM = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.BOTTOM_TEN_RM_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BOTTOM_10_RM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.BOTTOM_TEN_RM_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const TopTwentyTSM = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.TOP_TWENTY_TSM_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TOP_20_TSM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.TOP_TWENTY_TSM_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const BottomTwentyTSM = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.BOTTOM_TWENTY_TSM_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BOTTOM_20_TSM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.BOTTOM_TWENTY_TSM_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const TopFiveDSMView = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.TOP_FIVE_DSM_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TOP_5_DSM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.TOP_FIVE_DSM_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const heighestAchieversTwentySalesOfficers = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.HEIGEST_ACHIEVERS_TWENTY_SALES_OFFICERS,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}HIGHEST_ACHIEVERS_20_SALES_OFFICER?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.HEIGEST_ACHIEVERS_TWENTY_SALES_OFFICERS,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const bottomTwentySalesOfficersvw = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.BOTTOM_TWENTY_SALES_OFFICERS,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BOTTOM_20_SALES_OFFICER?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.BOTTOM_TWENTY_SALES_OFFICERS,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const ImsSummeryAllView = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.IMS_SUMMERY_ALL_VIEW,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}CombineAPI?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.IMS_SUMMERY_ALL_VIEW,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const topTwentSalesOfficerVw = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.TOP_TWENTY_SALES_OFFICERS,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}Top_20_SO_Value_Achiever?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.TOP_TWENTY_SALES_OFFICERS,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const BottomTwentSalesOfficerAcVw = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.BOTTOM_TWENTY_SALES_ACH_OFFICERS,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}BOTTOM_20_SO_VALUE_ACHIVER?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.BOTTOM_TWENTY_SALES_ACH_OFFICERS,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const CategoryWiseDivisonAchDataVw = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.CATEGORY_WISE_DIVISON_ACHIVEMENT,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}CATEGORY_WISE_DIVISION_ACH?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.CATEGORY_WISE_DIVISON_ACHIVEMENT,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const focusPeroductTargetVsAchievmentView = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.FOCUS_PRODUCT_TARGET_VS_ACHIEMENT,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}FOCUS_PRODUCT_TARGET_ACH?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.FOCUS_PRODUCT_TARGET_VS_ACHIEMENT,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const focusSkuTargetVsAchievmentView = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.FOCUS_SKU_gTARGET_VS_ACHIEMENT,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}FOCUS_SKU_TARGET_ACH?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.FOCUS_SKU_gTARGET_VS_ACHIEMENT,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const topSellingSkuView = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.TOP_SELLING_SKU,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}TOP_SELLING_SKU?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.TOP_SELLING_SKU,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


//previous month report 

export const previousMonthDivisionWiseImsSummery = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_DIVISION_WISE_IMS_SUMMERY,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_DIVISION_WISE_IMS_SUMMARY_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_DIVISION_WISE_IMS_SUMMERY,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const RegionWiseImsDataForPreviousMonth = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_REGION_WISE_IMS_SUMMERY,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_REGION_WISE_IMS_SUMMERY_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_REGION_WISE_IMS_SUMMERY,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const previousMonthTopFiveDsm = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_TOP_FIVE_DSM,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_TOP_5_DSM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_TOP_FIVE_DSM,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const bOttomThreeDsmPre = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_BOTTOM_THREE_DSM,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_BOTTOM_3_DSM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_BOTTOM_THREE_DSM,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};
export const TopTenRmPreviousMonth = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_TOP_TEN_RM_DATA,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_TOP_10_RM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_TOP_TEN_RM_DATA,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const BottomTenRmDataPreMonth = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_BOTTOM_TEN_RM_DATA,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_BOTTOM_10_RM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_BOTTOM_TEN_RM_DATA,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};
export const BottomTwentyTsmPre = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_BOTTOM_TWENTY_TSM_DATA,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_TOP_20_TSM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_BOTTOM_TWENTY_TSM_DATA,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};
export const topTwentyTsmBottomPre = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_BOTTOM_TWENTY_TSM_DATA,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_TOP_20_TSM_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_TOP_TWENTY_TSM_DATA,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};
export const TopTwentySoPre = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_TOP_TWENTY_SO_DATA,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_TOP_20_SO_VW?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_TOP_TWENTY_SO_DATA,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};
export const preMonthTopTwentySoValueAch = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_SO_VALUE_ACH_DATA,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_TOP_20_SO_VALUE_ACHIVER?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_SO_VALUE_ACH_DATA,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};
export const preMonthTwentyBottomValueAch = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.GET_PREVIOUS_MONTH_BOTTOM_SO_VALUE_ACH_DATA,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}P_MONTH_BOTTOM_20_SO_VALUE_ACHIVER?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.GET_PREVIOUS_MONTH_BOTTOM_SO_VALUE_ACH_DATA,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const currentMonthWorkingDays = () => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.CURRENT_MONTH_WORKING_DAYS,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}Working_days?`;




    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.CURRENT_MONTH_WORKING_DAYS,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};

export const previousMonthWorkingDays = () => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.PREVIOUS_MONTH_WORKING_DAYS,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}Prev_Working_days?`;




    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.PREVIOUS_MONTH_WORKING_DAYS,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};


export const dailyImsSummeryreport = (xdate) => async (dispatch) => {
    let responseList = {
        isLoading: true,
        data: {},
        status: true,

    };
    dispatch({
        type: Types.DAILY_IMS_LIFTING_SUMMERY_DETAILS,
        payload: responseList,
    });

    let url = `${process.env.REACT_APP_API_PUBLIC_URL}DAILY_LIFTING_IMS_SUMMARY?`;

    if (xdate !== null) {
        url += `xdate=${xdate}`;
    }
    console.log('urll', url, xdate)


    try {
        const response = await Axios.get(url);
        responseList.data = response.data.data == null ? [] : response.data.data;
        responseList.isLoading = false;

        console.log('response', response)
        dispatch({
            type: Types.DAILY_IMS_LIFTING_SUMMERY_DETAILS,
            payload: responseList,
        });
    } catch (error) {
        // Handle any error that occurred during the API request
        console.log('Error:', error);
    }
};



