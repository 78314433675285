export const addHoverEffects = (regions) => {
    const handleMouseEnter = (e) => {
        regions.forEach(region => {
            if (region !== e.target) {
                region.style.opacity = 0.2;
            }
        });
    };

    const handleMouseLeave = () => {
        regions.forEach(region => {
            region.style.opacity = 1;
        });
    };

    regions.forEach(region => {
        region.addEventListener('mouseenter', handleMouseEnter);
        region.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
        regions.forEach(region => {
            region.removeEventListener('mouseenter', handleMouseEnter);
            region.removeEventListener('mouseleave', handleMouseLeave);
        });
    };
};
