import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const PaginatedBarChart = ({ data, lineData, dataPointsPerPage,fullLabels }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end indexes of the data for the current page.
  const startIndex = (currentPage - 1) * dataPointsPerPage;
  const endIndex = startIndex + dataPointsPerPage;
  const dataForCurrentPage = data.slice(startIndex, endIndex);
  const dataline = lineData.slice(startIndex, endIndex);
  const labelsAll = fullLabels.slice(startIndex, endIndex);

  // Extract labels, values, and line values for the Chart.js datasets.
  const labels = dataForCurrentPage.map((item) => item.label);
  const barValues = dataline.map((item) => item.value); 
  const lineValues = dataForCurrentPage.map((item) => item.value);
  const tooltipslabel = labelsAll.map((item) => item.label);

  const chartData = {
    labels,
    datasets: [
      {
        type: 'bar',
        label: 'SALES MIX %',
        backgroundColor: '#EFCA4E',
        borderColor: '#EFCA4E',
        borderWidth: 1,
        order: 1,
        data: barValues,
        datalabels: {
          display: true,
          color: 'black',
          font: {
            weight: 'bold',
            size: 12,
          },
          formatter: function (value, context) {
            return value + '%';
          },
          backgroundColor: "#FFD081",
        },
      },
      {
        type: 'line',
        label: 'ACT SALES %',
        borderColor: '#25147A',
        borderWidth: 2,
        data: lineValues,
        order: 2,
        datalabels: {
          display: true,
          color: '#ffff',
          backgroundColor: "#25147A",
        },
      },
    ],
  };

  const totalPages = Math.ceil(data.length / dataPointsPerPage);

  const handleClick = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const pagination = [];

  // Display first page
  pagination.push(
    <a
      href="#"
      key={1}
      onClick={(e) => handleClick(e, 1)}
      className={currentPage === 1 ? "active" : ""}
    >
      1
    </a>
  );

  // Display ellipsis (...) if there are intermediate pages
  if (totalPages > 3) {
    pagination.push(<span key="ellipsis">...</span>);
  }

  // Display pages 2 to totalPages-1
  for (let i = 2; i < totalPages; i++) {
    // Display only the pages around the current page
    if (Math.abs(i - currentPage) <= 2) {
      pagination.push(
        <a
          href="#"
          key={i}
          onClick={(e) => handleClick(e, i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </a>
      );
    }
  }

  // Display ellipsis (...) if there are intermediate pages
  if (totalPages > 3 && currentPage < totalPages - 1) {
    pagination.push(<span key="ellipsis">...</span>);
  }

  // Display last page
  if (totalPages > 1) {
    pagination.push(
      <a
        href="#"
        key={totalPages}
        onClick={(e) => handleClick(e, totalPages)}
        className={currentPage === totalPages ? "active" : ""}
      >
        {totalPages}
      </a>
    );
  }


  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <BsChevronLeft />
        </button>
        <div style={{ flex: 1 }}>
          {data.length === 0 ? (
            <div className='text-center'>No data available.</div>
          ) : (
            <div style={{ width: '100%', height: '400px' }}>
              <Bar
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: true,
                  scales: {
                    y: {
                      beginAtZero: true,
                    },
                  },
                  plugins: {
                    tooltip: {
                      callbacks: {
                        title: (context) => {
                          const filterToolTip = tooltipslabel[context[0].dataIndex];
                          const line = lineValues[context[0].dataIndex];
                          return filterToolTip +("\n") +` ACT SALES : ${line}%`;
                        },
                      },
                    },
                    
                    legend: {
                      display: true,
                      position: "bottom",
                    },
                    datalabels: {
                      display: true,
                      color: '#424E63',
                      font: {
                        weight: 'bold',
                        size: 12,
                      },
                      formatter: function (value, context) {
                        return value + '%';
                      },
                    },
                  },
                }}
                plugins={[ChartDataLabels]} // Add the ChartDataLabels plugin here
              />
            </div>
          )}
        </div>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <BsChevronRight />
        </button>
      </div>
      {/* <div className="pagination mb-2">
        {pagination}
      </div> */}
    </div>
  );
};

export default PaginatedBarChart;
