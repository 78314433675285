import Axios from "axios";
import clsx from "clsx";
import { showToast } from "../../Components/ToastHelper";
import * as Types from '../Types';



export const GetUnit = () => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GETUnit`;
   
    await Axios.get(url).then((res) => {
      responseList.data = res.data.data.result;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_UNIT_NAME, payload: responseList });   
  };

  export const GetCategory = (unitId) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetProductCategory?Unit=${unitId}`;
    console.log('url', url);

    await Axios.get(url).then((res) => {
      console.log('res', res);
      responseList.data = res.data.data.result;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_CATEGORY_NAME, payload: responseList });   
  };
  export const GetCustomerGroup = (unitId) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };

    var newObject = [
      {
        salesOfficeId:1001,
        salesOfficeName:"Consumer",
        intUnitId:53,
      },
    
    ]
    
    let url = `${process.env.REACT_APP_API_PUBLIC_URL}GetAfmlCustomerGroupType`;
    await Axios.get(url).then((res) => {
      console.log('res cutomer group', res);
     
      
        responseList.data = res.data.data;
        responseList.status = false;
    
     
      // responseList.data.push(newObject);
    });

    console.log('responseList customer group', responseList);
    
    dispatch({ type: Types.GET_CUSTOMER_GROUP, payload: responseList });   
  };
  export const GetDashboardTargetDate = (fromDate,toDate,unitId,ValueType,monthId,YearID,categoryId,customerGroupID) => async (dispatch) => {

    console.log('customerGroupID', customerGroupID);
    
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };

    let url = "";
        url = `${process.env.REACT_APP_API_PUBLIC_URL}GetAblDeliveryInfo?`;
    if(fromDate !==null){
      url +=`&fromDate=${fromDate}`
    }
    if(toDate !==null){
      url +=`&toDate=${toDate}`
    }
    if(unitId !==null){
      url +=`&unitId=${unitId}`
    }
    if(ValueType !==null){
      url +=`&ValueType=${ValueType}`
    }
    if(monthId !==null){
      url +=`&monthId=${monthId}`
    }
    if(YearID !==null){
      url +=`&Year=${YearID}`
    }
    if(categoryId !==null){
      url +=`&categoryId=${categoryId}`
    }
    if(customerGroupID !==null && customerGroupID !==undefined){
      url +=`&CustomerGroup=${customerGroupID}`
    }
    console.log('url', url);
    await Axios.get(url).then((res) => {
        console.log('res', res);
      responseList.data = res.data.data.result;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_DASHBOARD_DATA, payload: responseList });
    //dispatch({ type: Types.GET_AFML_DASHBOARD_DATA, payload: responseList });
     
  };
  export const GetAfmlDashboardTargetDate = (fromDate,toDate,unitId,ValueType,monthId,YearID,categoryId,customerGroupID) => async (dispatch) => {

    console.log('customerGroupID', customerGroupID);
    
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };

    let url = "";
        url = `${process.env.REACT_APP_API_PUBLIC_URL}GetAblDeliveryInfo?`;
    if(fromDate !==null){
      url +=`&fromDate=${fromDate}`
    }
    if(toDate !==null){
      url +=`&toDate=${toDate}`
    }
    if(unitId !==null){
      url +=`&unitId=${unitId}`
    }
    if(ValueType !==null){
      url +=`&ValueType=${ValueType}`
    }
    if(monthId !==null){
      url +=`&monthId=${monthId}`
    }
    if(YearID !==null){
      url +=`&Year=${YearID}`
    }
    if(categoryId !==null){
      url +=`&categoryId=${categoryId}`
    }
    if(customerGroupID !==null){
      url +=`&CustomerGroup=${customerGroupID}`
    }
    console.log('url', url);
    await Axios.get(url).then((res) => {
        console.log('res', res);
      responseList.data = res.data.data.result;
      responseList.status = false;
    });
    dispatch({ type: Types.GET_AFML_DASHBOARD_DATA, payload: responseList });
     
  };



  export const TargetSubmit = (itemDataInput,startDate) => async (dispatch) => {
    let responseList = {
      isLoading: true,
      data: {},
      status: true,
    };

    console.log('itemDataInput.customerGroup', itemDataInput.customerGroup);
   let targetDetails=
   [
      {
        unitId: 0,
        targetQty: 0,
        targetValue: 0,
        unit: 0,
        targetMonth: "2022-09-19T07:53:03.137Z"
      }
    ]

    const daynamicData =
      [
        {
          unitId: parseInt(itemDataInput.unit),
          targetQty: parseInt(itemDataInput.targetQty),
          targetValue: parseInt(itemDataInput.targetValue),
          categoryId:parseInt(itemDataInput.category),
          measurementId: parseInt(itemDataInput.unit),
          //customerGroupId: parseInt(itemDataInput.customerGroup),
          customerGroupId: itemDataInput.customerGroup!=null?parseInt(itemDataInput.customerGroup):0,
          targetMonth: `${startDate}T07:53:03.137Z`
        }
      ]


    let url = `${process.env.REACT_APP_API_PUBLIC_URL}InsertAccountsTarget?targetDate=${startDate}&unit=${itemDataInput.unit}`;
    console.log('url', url);
   
    await Axios.post(url,daynamicData).then((res) => {
        console.log('res', res);
      responseList.data = res.data.data.result;
      responseList.status = false;
      showToast("success", `Submit Successfully`);
    });
    dispatch({ type: Types.INSERT_ACCOUNT_TARGET, payload: responseList });   
  };

  export const InputHandle = (name, value) => (dispatch) => {
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.CHANGE_PRODUCT_INPUT, payload: formData });
  };
  export const InputReportHandle = (name, value) => (dispatch) => {
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.CHANGE_PRODUCT_INPUT_REPORT, payload: formData });
  };
  export const handleDateManage = (name, value) => (dispatch) => {
    const formData = {
      name: name,
      value: value,
    };
    dispatch({ type: Types.CHANGE_HANDLE_DATE_MANAGE, payload: formData });
  };