import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import faker from "faker";
import LoadingSpinner from "../Billing/Components/LoadingSpinner";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export function AnualSalesSinceInception() {
  const dispatch = useDispatch();
  const reportData = useSelector((state) => state.dashboardcart.reportData);
  const anualabldata = useSelector((state) => state.dashboardcart.anualabldata);
  const anualablprice = useSelector(
    (state) => state.dashboardcart.anualablprice
  );
  const anualabldate = useSelector((state) => state.dashboardcart.anualabldate);
  const anualgrowth = useSelector((state) => state.dashboardcart.anualgrowth);

  const isLoading = useSelector(
    (state) => state.dashboardcart.monthtomonthLoading
  );

  const verticleData = anualablprice;
  const horizontalData = anualabldate;

  let nf = new Intl.NumberFormat("en-BD");

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
        callbacks: {
            label: (context) => {
                const datasetLabel = context.dataset.label || "";
                const value = context.parsed.y;

                return [
                    `${datasetLabel}: ${value}`
                ].join("\n");
            },
        },
    },
      legend: {
        position: "bottom",
        display: true,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: "Millions",
          font: {
            size: 12,
          },
        },
      },
    },

    legend: {
      labels: {
        fontColor: "black",
      },
      fillStyle: "blue", // set the desired legend key color
    },
  };

  const labels = horizontalData;

  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        label: [reportData.lineName] + " Anual Sales",
        backgroundColor: "#0b8793",
        data: verticleData,
        borderColor: "white",
        borderWidth: 2,
        datalabels: {
          display: true,
          color: "white",
          align: "bottom",
          anchor: "end",
          rotation: 270,
          formatter: function(value, context) {
            return "BDT " + nf.format(value);
          },
        },
      },
      {
        type: "bar",
        label: [reportData.lineName] + " Growth",
        backgroundColor: function(dataColor) {
          return dataColor.dataset.data[dataColor.dataIndex] < 0
            ? "rgba(238, 42, 10,0.7)"
            : "rgba(14, 119, 199, 0.7)";
        },
        data: anualgrowth,
        datalabels: {
          display: true,
          color: "black",
          align: "bottom",
          anchor: "end",
          rotation: 270,
          formatter: function(value, context) {
            return value + "%";
          },
        },
      },
    ],
  };

  return (
    <div className="card mb-0 ">
      <span className="spineercolor mt-5 mb-5">
        {isLoading && <LoadingSpinner />}
      </span>
      <>
        {!isLoading && (
          <>
            <div className="text-center">
              <h5>Annual Sales Since Inception(TK)- At core</h5>
            </div>
            <Chart
              options={options}
              data={data}
              height={137}
              plugins={[
                {
                  beforeDraw(c) {
                    var legends = c.legend.legendItems;
                    legends[0].fillStyle = "#0b8793";
                    legends[1].fillStyle = "rgba(14, 119, 199, 0.7)";
                  },
                },
                ChartDataLabels,
              ]}
            />
          </>
        )}
      </>
    </div>
  );
}
