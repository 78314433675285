import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'; // Import useSelector from your Redux library
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import LoadingSpinner from '../Billing/Components/LoadingSpinner';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const MonthToMonthLineColumnAreaChart = () => {
    const actualSalesQtyData = useSelector(
        (state) => state.cm.actualSalesQtyData
    );
    const actualSalesValData = useSelector(
        (state) => state.cm.actualSalesValData
    );
    const cmData = useSelector((state) => state.cm.cmData);

    const isLoading = useSelector(
        (state) => state.cm.cmAnalysisLoading
    );
   

    const labels = actualSalesQtyData.map((item) => item.label);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Actual Sales',
                data: actualSalesQtyData.map((item) => item.value),
                type: 'bar',
                backgroundColor: 'rgb(93, 98, 181)',
                borderColor: 'rgb(93, 98, 181)',
                borderWidth: 3,
                yAxisID: 'y1',
                order: 3,
                datalabels: {
                    display: true,
                    color: 'rgb(93, 98, 181)',
                    align: 'end',
                    anchor: 'end',
                    font: {
                        weight: 'bold',
                        size: 12,
                    },
                },
            },

            {
                type: 'line',
                label: 'Actual CM',
                borderColor: '#43ccfe',
                borderWidth: 2,
                data: actualSalesValData.map((item) => item.value),
                order: 1,
                // yAxisID: 'y2',
                yAxisID: 'y1',
                datalabels: {
                  display: true,
                  color: '#ffff',
                  //backgroundColor: "#43ccfe",
                },
              },
            
            {
                label: 'CM%',
                data: cmData.map((item) => item.value),
                type: 'line',
                fill: true,
                borderWidth: 2,
                order: 2,
                //backgroundColor: 'rgba(242, 114, 111, 0.9)',
                borderColor: 'rgb(242, 114, 111)',
                datalabels: {
                    display: true,
                    formatter: (value) => value + '%',
                    color: 'black',
                    backgroundColor: "rgba(242, 114, 111, 0.9)",
                    font: {
                        weight: 'bold',
                        size: 12,
                    },
                },
                cubicInterpolationMode: 'monotone',
            }
            
            

        ],
    };
    const options = {
        layout: {
            padding: {
                top: 30
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Core',
                    font: {
                        size: 12,
                    },
                },
            },
            y1: {
                display: false,
            },
            y2: {
                display: false,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: "bottom",
            },
            tooltip: {
                mode: "index",
                intersect: false,
                callbacks: {
                    label: (context) => {
                        const datasetLabel = context.dataset.label || "";
                        const value = context.parsed.y;

                        return [
                            `${datasetLabel}: ${value}`
                        ].join("\n");
                    },
                },
            },
        },
    };

    return (
        <>
            <div className='container card '>
                {isLoading ? (
                    <span className="spineercolor mt-5 mb-5 text-center">
                        <LoadingSpinner className="text-center" />
                    </span>
                ) : (
                    <div className='row'>
                        <div className='col-lg-2 paddingremove'>
                            <div className='threecardtop p-5'>
                                <div>
                                    <div className="column-container">
                                        <div className="column-item">
                                            <h5 className="button-123">Actual Sales</h5>
                                        </div>
                                        <div className="column-item">
                                            <h5 className="button-124">Actual CM</h5>
                                        </div>
                                        <div className="column-item">
                                            <h5 className="button-125">CM%</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-lg-10 paddingremove'>
                            <div id="chart" className=''>
                                <div className="">
                                    <h5 className="button-122 float-right">Current Month</h5>
                                </div>

                                <>
                                    <div className='' style={{ width: '100%' }}>
                                        <Line data={chartData} options={options} height={132} plugins={[ChartDataLabels]} />
                                    </div>
                                </>

                            </div>
                        </div>
                    </div>
                )}
            </div>


        </>
    );
};

export default MonthToMonthLineColumnAreaChart;
